import React from 'react'

import LoginToAccountModal from '../modals/loginToAccountModal'
import CampaignModal from '../modals/campaignModal'
import RawLogExportModal from '../modals/rawLogExportModal'
import DeleteAccountModal from '../modals/deleteAccountModal'
import AccountBrandingModal from '../modals/accountBrandingModal'
import EditAccountModal from '../modals/editAccountModal'
import DeleteCampaignsModal from '../modals/deleteCampaignsModal'
import CloneCampaignsModal from '../modals/cloneCampaignsModal'
import MoveCampaignsModal from '../modals/moveCampaignsModal'
import CreateConsentModal from '../modals/createConsentModal'

const Modals = () => <React.Fragment>
  <LoginToAccountModal/>
  <CampaignModal/>
  <RawLogExportModal/>
  <DeleteAccountModal/>
  <AccountBrandingModal/>
  <EditAccountModal/>
  <DeleteCampaignsModal/>
  <CloneCampaignsModal/>
  <MoveCampaignsModal/>
  <CreateConsentModal/>
</React.Fragment>

export default Modals
