import { getDataAssessmentAudiences, getDataAssessmentReports, requestDataAssessmentReport } from '../util/api'

const initialState = {
  reports: [],
  audiences: [],
}

const DataAssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REPORTS':
      return {...state, reports: action.reports}
    case 'SET_AUDIENCES':
      return {...state, audiences: action.audiences}
    default:
      return state
  }
}

export const requestReport = ({pid, audience}) => {
  return (dispatch) => {
    return requestDataAssessmentReport({pid, audience})
      .then(audiences => dispatch({
        type: 'SET_AUDIENCES',
        audiences
      }))
  }
}

export const fetchAudiences = () => {
  return (dispatch) => {
    return getDataAssessmentAudiences()
      .then(response => dispatch({
        type: 'SET_AUDIENCES',
        audiences: response.data
      }))
  }
}

export const fetchReports = () => {
  return (dispatch) => {
    return getDataAssessmentReports()
      .then(({data}) => {
        dispatch({
        type: 'SET_REPORTS',
        reports: data
      })
    })
  }
}

export default DataAssessmentReducer
