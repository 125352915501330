import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { modalStyles } from './../styles/styles'
import { createAccount } from '../reducers/accountsReducer'
import { toggleCreateModal } from '../reducers/modalReducer'
import AccountForm from '../components/AccountForm'

const CreateAccountModal = ({modalIsOpen, toggleCreateModal, createAccount, account}) =>
  <Modal
    closeTimeoutMS={200}
    isOpen={modalIsOpen}
    style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px', 'height': '75%', 'overflow': 'auto'}}}
    onRequestClose={toggleCreateModal}
  >
    <div>
      <div className="modal-header">
        <h3 className="modal-title">Create account</h3>
      </div>
      <AccountForm account={account} action={createAccount} onClose={toggleCreateModal}/>
    </div>
  </Modal>

const mapStateToProps = ({modals}) => ({
  modalIsOpen: modals.createModal,
  account: {
    name: '',
    cid: '',
    branding: {},
    organization: {},
    permissions: [],
    features: [],
    users: []
  }
})

export default connect(mapStateToProps, {toggleCreateModal, createAccount})(CreateAccountModal)

