import {
  getAccountCampaigns,
  getOrganization,
  getAccountByProduct,
  getAccountsByIds,
  getAccountToken,
  deleteAccountCampaigns,
  cloneAccountCampaigns,
  moveCampaignsToAccount,
  searchAccountCampaigns
} from './../util/api'
import { toast } from 'react-toastify'
import { toggleCloneCampaignsModal, toggleDeleteCampaignsModal, toggleMoveCampaignsModal } from './modalReducer'
import { setAccountCampaigns, setTotalCampaigns } from './campaignReducer'
import { uniqBy } from 'lodash'
import { createOauthClient, getAccountApiKeys } from '../util/api'

const initialState = {
  pagination: {
    resultPerPage: 25,
    resultsPerRequest: 50,
    cursor: 0,
    sortColumn: 'created',
  },
  isLoading: false,
  adminAccount: {},
  account: {
    name: '',
    cid: '',
    branding: {},
    organization: {},
    permissions: [],
    features: [],
    users: [],
  },
  oauthClient: null,
  organization: {},
  search: {
    campaigns: [],
    isSearching: false,
    searchTerm: null
  },
  webapplogin: {
    token: null,
    webappurl: null
  }
}

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {...state, isLoading: action.isLoading}
    case 'SET_ACCOUNT':
      return {...state, account: action.account}
    case 'SET_ADMIN_ACCOUNT':
      return {...state, adminAccount: action.account}
    case 'SET_WEBAPP_LOGIN':
      return {...state, webapplogin: action.data}
    case 'SET_CURSOR':
      return {...state, pagination: {...state.pagination, cursor: action.cursor}}
    case 'SET_ACCOUNT_SEARCH_RESULTS':
      return {...state, search: {...state.search, campaigns: action.campaigns}}
    case 'SET_ACCOUNT_SEARCH_TERM':
      return {...state, search: {...state.search, searchTerm: action.term}}
    case 'SET_ACCOUNT_IS_SEARCHING':
      return {...state, search: {...state.search, isSearching: action.isSearching}}
    case 'SET_ACCOUNT_OAUTH_CLIENT':
      return {...state, oauthClient: action.client}
    case 'SET_ORGANIZATION':
      let organization = {...action.organization}
      let account = {...state.account, organization}
      return {...state, organization, account}
    default:
      return state
  }
}

const setAccount = (account) => ({
  type: 'SET_ACCOUNT',
  account
})

const setAdminAccount = (account) => ({
  type: 'SET_ADMIN_ACCOUNT',
  account
})

const setOrganization = (organization) => ({
  type: 'SET_ORGANIZATION',
  organization
})

export const setWebAppLogin = (data) => ({
  type: 'SET_WEBAPP_LOGIN',
  data
})

export const setIsLoading = (isLoading) => ({
  type: 'SET_LOADING',
  isLoading
})

export const setCursor = (cursor) => ({
  type: 'SET_CURSOR',
  cursor
})

export const setAccountOauthClient = (client) => ({
  type: 'SET_ACCOUNT_OAUTH_CLIENT',
  client
})

export const clearSearch = () => setSearch([], false, null)

const setSearch = (campaigns, isSearching, term) => {
  return dispatch => {
    dispatch({type: 'SET_ACCOUNT_SEARCH_RESULTS', campaigns})
    dispatch({type: 'SET_ACCOUNT_IS_SEARCHING', isSearching})
    dispatch({type: 'SET_ACCOUNT_SEARCH_TERM', term})
  }
}

export const searchCampaigns = (term) => {
  return (dispatch, getState) => {
    let {account: {id}} = getState().account

    let initialCampaigns = getState().campaign.accountCampaigns
      .filter(({name, id}) => {
        return !term
          || name.toLowerCase().includes(term.toLowerCase())
          || id === term
      })

    // 1: set initial campaigns
    dispatch(setSearch(initialCampaigns, true, term))

    // 2: enrich with searched campaigns
    searchAccountCampaigns(term, id).then(response => {
      let pids = response.data.campaigns.map(campaign => campaign.pid).slice(0, 50)

      if (pids.length > 0) {
        getAccountCampaigns(id, {'campaignIds': pids})
          .then(response => {
            let combined = initialCampaigns.concat(
              response.data.map(campaign => ({...campaign, selected: false}))
            )

            dispatch(setSearch(uniqBy(combined, 'id'), false, term))
          })
      } else {
        dispatch(setSearch([], false, term))
      }
    })
  }
}

export const deleteCampaigns = (campaigns) => {
  return (dispatch, getState) => {
    let {account: {id}} = getState().account

    return deleteAccountCampaigns(campaigns, id)
      .then(() => {
        toast.success(`Selected campaigns has been deleted`);

        dispatch(toggleDeleteCampaignsModal())

        fetchCampaigns(id).then(({campaigns}) => dispatch(setAccountCampaigns(campaigns)))
      })
  }
}

export const cloneCampaigns = (account, campaigns) => {
  return (dispatch) => {
    let payload = {
      campaigns: campaigns.map(({id}) => {
        return {
          source: {pid: id},
          destination: {cid: account.id}
        }
      })
    }

    cloneAccountCampaigns(payload)
      .then(data => {
        if (data.error) {
          toast.error(data.error.message, {
            autoClose: 10000,
            closeOnClick: true,
          });
        } else {
          toast.success(`Campaigns have been successfully cloned to ${account.id}`);
        }

        dispatch(toggleCloneCampaignsModal());
      })
  }
}

export const moveCampaigns = (account, campaigns) => {
  return (dispatch) => {
    let payload = {
      campaigns: campaigns.map(campaign => ({pid: campaign.id}))
    }

    dispatch(toggleMoveCampaignsModal())

    return moveCampaignsToAccount(payload, account.id).then(() => {
      toast.success(`Campaigns have been successfully moved`);

      if (account.id !== undefined) {
        fetchCampaigns(account.id).then(({campaigns}) => dispatch(setAccountCampaigns(campaigns)))
      }
    })
  }
}

export const generateWebAppLink = id => {
  return dispatch => {
    getAccountToken(id).then(response => dispatch(setWebAppLogin(response)))
  }
}

export const createApiClient = data => {
  return (dispatch, getState) => {
    let {account: {id}} = getState().account
    createOauthClient(id, data.name).then(client => dispatch(setAccountOauthClient(client)))
  }
}

export const setMasterAccount = (masterAccountId = 2) => {
  return (dispatch) => {
    dispatch(fetchAccount(masterAccountId)).then(account => dispatch(setAdminAccount(account)))
  }
}

export const loadAccount = id => {
  return (dispatch) => {
    dispatch(fetchAccount(id)).then(account => {
      dispatch(setAccount(account))
      getOrganization(account.organizationId).then(organization => dispatch(setOrganization(organization)))
      getAccountToken(id).then(response => dispatch(setWebAppLogin(response)))
      getAccountApiKeys(id).then(client => dispatch(setAccountOauthClient(client)))
    })

    dispatch(fetchInitialAccountCampaigns(id))
  }
}

export const fetchInitialAccountCampaigns = (id) => {
  return (dispatch) => {
    fetchCampaigns(id, 0, 50, true)
      .then(({campaigns, totalCampaigns}) => {
        dispatch(setAccountCampaigns(campaigns))
        dispatch(setTotalCampaigns(totalCampaigns))
        dispatch(setIsLoading(false))
      })
  }
}

export const fetchAccount = (id) => {
  return (dispatch) => {
    dispatch(setIsLoading(true))
    return getAccountsByIds([id])
      .then(accounts => accounts.find(account => account.id === parseInt(id)))
      .then(ARAccount => getAccountByProduct(id)
        .then(account => {
          return {
            ...account,
            permissions: ARAccount.permissions,
            cid: ARAccount.cid,
            features: ARAccount.features,
            id: ARAccount.id,
            APAccountId: account.id,
            branding: ARAccount.branding
          }
        })
      )
  }
}

export const nextPage = () => {
  return (dispatch, getState) => {
    let {pagination: {cursor, resultPerPage}} = getState().account
    dispatch(changePage(cursor + resultPerPage))
  }
}

export const previousPage = () => {
  return (dispatch, getState) => {
    let {pagination: {cursor, resultPerPage}} = getState().account
    dispatch(changePage(cursor - resultPerPage))
  }
}

const changePage = (newCursor) => {
  return (dispatch, getState) => {
    let {campaign: {accountCampaigns}, account: {account, pagination: {resultsPerRequest}}} = getState()

    if (newCursor >= accountCampaigns.length) {
      dispatch(setIsLoading(true))
      fetchCampaigns(account.id, newCursor, resultsPerRequest)
        .then(data => {
          let newCampaigns = accountCampaigns.concat(data.campaigns)
          dispatch(setAccountCampaigns(newCampaigns))
        })
        .then(() => dispatch(setIsLoading(false)))
    }

    dispatch(setCursor(newCursor))
  }
}

export const fetchCampaigns = (accountId, start = 0, maxResults = 200, pagination = false) => {
  return getAccountCampaigns(accountId, {start, maxResults, pagination}).then(({data, meta}) => {
      return {
        campaigns: data.map(campaign => ({...campaign, selected: false})),
        totalCampaigns: meta.totalCount,
      }
    }
  )
}

export default accountsReducer