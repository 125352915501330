import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { modalStyles } from './../styles/styles'
import { deleteCampaigns } from '../reducers/accountReducer'
import { toggleDeleteCampaignsModal } from '../reducers/modalReducer'
import ModalHeader from '../components/ModalHeader'
import { deselectCampaign } from '../reducers/campaignReducer'

const DeleteCampaignsModal = ({modalIsOpen, campaigns, toggleModal, deleteCampaigns}) =>
  <Modal
    closeTimeoutMS={200}
    isOpen={modalIsOpen}
    style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px', 'width': '30%'}}}
    onRequestClose={toggleModal}
  >
    <ModalHeader title={'Delete campaigns - Are you sure?'} onCancel={toggleModal}/>
    <div className="modal-body">
      The following campaigns will be deleted:
      <div>
        {campaigns.map(campaign =>
          <small key={campaign.id}>{campaign.id} : {campaign.name}</small>
        )}
      </div>
    </div>
    <div className="modal-footer">
      <button onClick={toggleModal} type="button" className="btn btn-default btn-wide">
        Cancel
      </button>
      <button onClick={() => deleteCampaigns(campaigns)} type="button" className="btn btn-danger btn-wide">
        Delete
      </button>
    </div>
  </Modal>

const mapStateToProps = ({campaign, modals}) => {
  let campaigns = campaign.selectedCampaign.id
    ? [campaign.selectedCampaign]
    : campaign.selectedCampaigns

  return {
    campaigns,
    modalIsOpen: modals.deleteCampaignsModal
  }
}

const mapDispatchToProps = dispatch => ({
  deleteCampaigns: (campaigns) => dispatch(deleteCampaigns(campaigns)),
  toggleModal: () => {
    dispatch(deselectCampaign())
    dispatch(toggleDeleteCampaignsModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCampaignsModal)

