import React from 'react'
import { connect } from 'react-redux'
import { previousPage, nextPage } from '../reducers/accountReducer'
import AccountCampaign from './AccountCampaign'
import LoadingBar from './LoadingBar'

const AccountCampaigns = ({campaigns, isLoading, isSearching, nextPage, previousPage, cursor, canPaginate}) => {
  if (isLoading) {
    return <LoadingBar loadingText='Fetching campaigns...'/>
  }

  if (campaigns.length === 0 && !isSearching) {
    return <LoadingBar loadingText='No campaigns found'/>
  }

  return <div>
    <table className="table table-vcenter table-projects">
      <thead>
      <tr>
        <th className="cb-cell">
          <label onClick={() => console.log('Add/remove all selected campaigns')} className="pretty-checkbox">
            <i/>
          </label>
        </th>
        <th>Name</th>
        <th>Campaign ID</th>
        <th>Type</th>
        <th className="sort-toggle">Created</th>
        <th>Status</th>
        <th><span className="pull-right">Events</span></th>
        <th><span className="pull-right">Action</span></th>
      </tr>
      </thead>
      <tbody>
      {campaigns.map(campaign => <AccountCampaign key={campaign.id} campaign={campaign}/>)}
      </tbody>
    </table>

    {isSearching && <LoadingBar loadingText='Searching for older...'/>}

    <div className="row row-centered">
      <button className='btn btn-primary'
              style={{'marginRight': '10px'}}
              disabled={cursor === 0}
              onClick={previousPage}>
        Previous
      </button>
      <button className='btn btn-primary' disabled={!canPaginate} onClick={nextPage}>Next</button>
    </div>
  </div>
}

const mapStateToProps = ({account: {isLoading, pagination, search}, campaign}) => {
  let {cursor, resultPerPage} = pagination
  let {campaigns, isSearching, searchTerm} = search

  return {
    isLoading,
    isSearching,
    cursor,
    searchTerm,
    canPaginate: cursor + resultPerPage <= campaign.accountTotalCampaigns && searchTerm === null,
    campaigns: searchTerm !== null
      ? campaigns
      : campaign.accountCampaigns.slice(cursor, cursor + resultPerPage)
  }
}

export default connect(mapStateToProps, {previousPage, nextPage})(AccountCampaigns)
