export const providers = [
  'facebook',
  'dv360',
  'adwords'
]

export const states = [
  'AwaitingRequest',
  'AwaitingRequestApproval',
  'Connected',
  'Revoked'
]

export const statusMap = [
  {
    status: 'AwaitingRequest',
    description: 'Awaiting AudienceProject to send Linkage Request',
    transitionText: 'Transition to AwaitingRequestApproval'
  },
  {
    status: 'AwaitingRequestApproval',
    description: 'Awaiting Customer to give permission to provider',
    transitionText: 'Transition to Connected'
  },
  {
    status: 'Connected',
    description: 'Account can be used for Integrated Reporting',
    transitionText: 'Connection established'
  },
  {
    status: 'Revoked',
    description: 'Account has been revoked in the respective provider',
    transitionText: 'Revert back to connected'
  }
]