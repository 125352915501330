import { fetchCampaignDetails, getAccountToken } from './../util/api'
import _ from 'lodash'

const initialState = {
  selectedCampaign: {},
  selectedCampaigns: [],
  accountCampaigns: [],
  webappToken: null,
  accountTotalCampaigns: null,
}

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_CAMPAIGN':
      return {...state, selectedCampaign: action.campaign}
    case 'DESELECT_CAMPAIGN':
      return {...state, selectedCampaign: {}}
    case 'SET_WEBAPP_CAMPAIGN_TOKEN':
      return {...state, webappToken: action.token}
    case 'SET_CAMPAIGNS':
      return {...state, selectedCampaigns: action.campaigns}
    case 'SET_ACCOUNT_CAMPAIGNS':
      return {...state, accountCampaigns: action.campaigns}
    case 'SET_TOTAL_ACCOUNT_CAMPAIGNS':
      return {...state, accountTotalCampaigns: action.totalCampaigns}
    case 'TOGGLE_CAMPAIGN': {
      let {selectedCampaigns, accountCampaigns} = state
      let index = _.findIndex(selectedCampaigns, {id: action.id})

      let newSelection = index === -1
        ? selectedCampaigns.concat(_.find(accountCampaigns, {id: action.id}))
        : selectedCampaigns.filter(campaign => campaign.id !== action.id)

      return {...state, selectedCampaigns: newSelection}
    }
    case 'TOGGLE_SELECTED_CAMPAIGN':
      let selectedCampaign = _.find(state.accountCampaigns, {id: action.id})

      return {
        ...state,
        accountCampaigns: state.accountCampaigns.map(campaign => {
          if (campaign.id === selectedCampaign.id) {
            return {...campaign, selected: !campaign.selected}
          }

          return campaign
        }),
      }
    default:
      return state
  }
}

export const setCampaigns = campaigns => ({
  type: 'SET_CAMPAIGN',
  campaigns,
})

export const setAccountCampaigns = (campaigns) => ({
  type: 'SET_ACCOUNT_CAMPAIGNS',
  campaigns
})

export const setTotalCampaigns = (totalCampaigns) => ({
  type: 'SET_TOTAL_ACCOUNT_CAMPAIGNS',
  totalCampaigns
})

export const toggleCampaign = (id) => ({
  type: 'TOGGLE_CAMPAIGN',
  id
})

export const toggleSelectedCampaign = (id) => ({
  type: 'TOGGLE_SELECTED_CAMPAIGN',
  id
})

export const selectCampaign = (campaign) => ({
  type: 'SELECT_CAMPAIGN',
  campaign
})

export const setWebappToken = (token) => ({
  type: 'SET_WEBAPP_CAMPAIGN_TOKEN',
  token: token
})

export const deselectCampaign = () => ({
  type: 'DESELECT_CAMPAIGN',
})

export const fetchAndSelectCampaign = pid => {
  return (dispatch) => {
    return fetchCampaignDetails(pid).then(campaign => {
      getAccountToken(campaign.customerId).then(response => dispatch(setWebappToken(response.token)))
      dispatch(selectCampaign(campaign))
    })
  }
}

export default campaignReducer
