import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import Spinner from './common/Spinner'
import { Col, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { createApiClient } from '../reducers/accountReducer'
import { errorStyles } from '../styles/styles'
import useForm from 'react-hook-form'

const styles = {
  'marginTop': '1rem'
}

const AccountApiKeysTab = ({oauthClient, createApiClient}) => {
  const {register, handleSubmit, errors, setValue} = useForm()

  useEffect(() => {
    register({name: 'name'}, {required: true})
  }, [register])

  if (oauthClient === null) {
    return <Spinner/>
  }

  return <div style={styles}>
    {oauthClient !== false && <Fragment>
      <Row>
        <Col md={12}>
          <label>
            <b>Client ID: </b>{oauthClient.key}
          </label>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <label>
            <b>Client secret: </b>{oauthClient.secret}
          </label>
        </Col>
      </Row>
    </Fragment>}

    {oauthClient === false && <Fragment>
      <Row>
        <Col md={4}>No Api client generated yet</Col>
      </Row>
      <form onSubmit={handleSubmit(createApiClient)}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <FormLabel column={false}>API Client Name</FormLabel>
              <FormControl as='input'
                           name='name'
                           placeholder="Client name"
                           onChange={(e) => setValue('name', e.target.value)}
                           style={errors.name ? errorStyles : {}}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <button style={styles} type="submit" className="btn btn-primary btn-wide">Generate client</button>
          </Col>
        </Row>
      </form>
    </Fragment>}
  </div>
}

const mapStateToProps = ({account: {oauthClient}}) => ({
  oauthClient
})

export default connect(mapStateToProps, {createApiClient})(AccountApiKeysTab)
