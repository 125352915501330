import Highcharts from 'highcharts'

export const impressionsChartOptions = {
  title: {
    text: ''
  },
  chart: {
    type: 'column',
    events: {
      load () {
        this.showLoading()
        setTimeout(this.hideLoading.bind(this), 4000)
      }
    }
  },
  plotOptions: {
    series: {
      groupPadding: 0,
      pointWidth: 10
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      month: '%e. %b',
      year: '%b',
    },

    labels: {
      formatter: function () {
        return Highcharts.dateFormat('%e. %b', this.value)
      }
    }
  },
  yAxis: {
    type: 'number',
    min: 0,
    title: {
      text: null
    }
  },
  tooltip: {
    valueDecimals: 0,
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  loading: {
    style: {
      backgroundColor: 'white',
      opacity: 0.8,
    },
  },
  series: {
    data: []
  }
}

export const campaignsCreatedOptions = {
  title: {
    text: ''
  },
  chart: {
    type: 'column',
    events: {
      load () {
        this.showLoading()
        setTimeout(this.hideLoading.bind(this), 2000)
      }
    }
  },
  plotOptions: {
    series: {
      groupPadding: 0,
      pointWidth: 10
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      month: '%e. %b',
      year: '%b',
    },
    labels: {
      formatter: function () {
        return Highcharts.dateFormat('%e. %b', this.value)
      }
    }
  },
  yAxis: {
    type: 'number',
    min: 0,
    title: {
      text: null
    }
  },
  tooltip: {
    valueDecimals: 0,
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  loading: {
    style: {
      backgroundColor: 'white',
      opacity: 0.8,
    },
  },
  series: {
    data: []
  }
}