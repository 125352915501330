import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { getLabelClass } from "../styles/styles";

const DataAssessmentTable = ({ reports }) => {
  const downloadReport = (id) => {
    axios
      .create({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ssoToken")}`,
        },
        responseType: "stream",
      })
      .get(
        `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/data-assessment/reports/${id}`,
        {
          headers: { "Customer-id": 2 },
          responseType: "blob",
        }
      )
      .then((response) => {
        //Build a URL from the file
        const fileURL = URL.createObjectURL(response.data); //Open the URL on new Window
        window.open(fileURL);
      });
  };

  return (
    <div>
      <div>
        <h4>Reports</h4>
        <table className="table table-vcenter table-exports">
          <tbody>
            <tr>
              <th>Project ID</th>
              <th>Audience</th>
              <th>Status</th>
              <th />
            </tr>
            {reports.map((report) => (
              <tr
                key={report.id}
                className={report.state !== "SUCCEEDED" ? "disabled" : ""}
                style={{ cursor: "default" }}
              >
                <td>{report.campaignId}</td>
                <td>{report.humanReadableAudience}</td>
                <td>
                  <span className={`label ${getLabelClass(report.state)}`}>
                    {report.state}
                  </span>
                </td>
                <td onClick={() => downloadReport(report.id)}>Download</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ dataAssessment }) => ({
  reports: dataAssessment.reports,
});

export default connect(mapStateToProps)(DataAssessmentTable);
