import React from 'react'
import { connect } from 'react-redux'
import { toggleLoginModal, toggleEditModal, toggleDeleteModal, toggleBrandingModal } from '../reducers/modalReducer'
import DropDownMenu from './DropdownMenu'

const AccountModalsDropdown = ({
                                 toggleEditModal,
                                 toggleLoginModal,
                                 toggleDeleteModal,
                                 toggleBrandingModal,
                                 organization
                               }) => {
  const redirectToUsers = () => {
    window.open(`${process.env.REACT_APP_CP_AUDIENCEPROJECT_URL}/accounts/${organization.id}/users`)
  }

  const actionItems = [
      {
        label: 'Edit account info',
        action: toggleEditModal,
        icon: 'building-1'
      },
      {
        label: 'Manage users',
        action: redirectToUsers,
        icon: 'user-1'
      },
      {
        label: 'Customize branding',
        action: toggleBrandingModal,
        icon: 'bucket-1'
      },
      {
        label: 'Delete account',
        action: toggleDeleteModal,
        icon: 'trash-1',
        status: 'danger'
      }
    ]

  const mainAction = {
    label: "Login",
    action: toggleLoginModal
  }

  return <div className="btn-group pull-right pull-up">
    <DropDownMenu mainAction={mainAction} items={actionItems}/>
  </div>
}

const mapStateToProps = ({account: {organization}}) => ({
  organization
})

export default connect(mapStateToProps, {
  toggleLoginModal,
  toggleEditModal,
  toggleDeleteModal,
  toggleBrandingModal
})(AccountModalsDropdown)
