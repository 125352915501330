import React, { useState } from 'react'
import Modal from 'react-modal'
import _ from 'lodash'
import moment from 'moment'
import { modalStyles, modalFooterStyles } from './../styles/styles'
import { setModalAccount, createMapping, deleteMapping } from '../reducers/techedgeReducer'
import { closeTechedgeMappingModal } from '../reducers/modalReducer'
import { connect } from 'react-redux'

const MapTechedgeToAudienceReportModal = ({
                                            techedgeAccount,
                                            accounts,
                                            accountOptions,
                                            modalIsOpen,
                                            createMapping,
                                            mappings,
                                            deleteMapping,
                                            closeTechedgeMappingModal
                                          }) => {
  const [account, setAccount] = useState('')
  const handleAccountChange = (id) => setAccount(accounts.find(item => item.id === Number(id)))

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      onRequestClose={closeTechedgeMappingModal}
      style={modalStyles}
    >
      <div className='col-md-12'>
        <p>Map techedge account</p>
      </div>
      <form>
        <div className="row">
          <div className="form-group col-xs-12">
            <div className='col-xs-12'>
              <label>Techedge Account</label>
            </div>
            <div className='col-xs-12'>
              <select className="form-control input-sm">
                <option defaultValue={techedgeAccount.name} hidden>
                  {techedgeAccount.name}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-xs-12">
            <div className='col-xs-10'>
              <label>AudienceReport account</label>
            </div>
            <div className='col-xs-10'>
              <select
                value={account.id}
                onChange={(event) => handleAccountChange(event.target.value)}
                className="form-control input-sm">
                <option defaultValue={account.id}>Choose account...</option>
                {accountOptions.map(
                  ({id, name, cid}) => <option key={id} value={id}>{name} : {cid}</option>)
                }
              </select>
            </div>
            <div className='col-xs-2'>
              <div onClick={() => createMapping(techedgeAccount, account)} className="btn-sm pull-right btn-info">
                <i className='flaticon stroke plus-1'/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 form-group">
            <div className='col-xs-10'>
              <label>Current mappings</label>
            </div>
            <div className='col-xs-12'>
              <ul>
                {mappings.map(account => <li style={{'paddingTop': '4px'}} className="small" key={account.id}>
                    {moment(account.createdOn).format('Y-MM-DD')} : {account.name},
                    <div onClick={() => deleteMapping(techedgeAccount, account)}
                         className="btn btn-xs pull-right btn-danger">
                      <i className='flaticon stroke trash-1'/>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </form>
      <div style={modalFooterStyles}>
        <button onClick={closeTechedgeMappingModal} type="button" className="btn btn-primary btn-wide">Close</button>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({accounts, techedge, modals}) => {
  let accountOptions = _(accounts.audienceReportAccounts)
    .filter(account => {
      return _.find(
        techedge.modalAccount.audienceReportAccounts,
        {'id': account.id}
      ) === undefined
    })
    .sortBy('name')
    .value()

  return {
    accountOptions: accountOptions,
    accounts: accounts.audienceReportAccounts,
    techedgeAccount: techedge.modalAccount,
    mappings: techedge.modalAccountMappings,
    modalIsOpen: modals.techedgeMappingModal
  }
}

export default connect(mapStateToProps, {
  setModalAccount,
  createMapping,
  closeTechedgeMappingModal,
  deleteMapping
})(MapTechedgeToAudienceReportModal)

