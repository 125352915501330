import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { modalStyles } from './../styles/styles'
import { toggleDeleteModal } from '../reducers/modalReducer'
import { deleteAccount } from '../reducers/accountsReducer'

const DeleteAccountModal = ({modalIsOpen, toggleDeleteModal, deleteAccount, account}) => {
  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px'}}}
      onRequestClose={toggleDeleteModal}
    >
      <div>
        <div className="modal-header">
          <h3 className="modal-title">Delete account</h3>
        </div>
        <div className="modal-body">
          <p>You are about to delete this company from AudienceReport. All associated projects will be deleted</p>
        </div>
        <div className="modal-footer">
          <button onClick={toggleDeleteModal} type="button" className="btn btn-default btn-wide">
            Cancel
          </button>
          <button onClick={() => deleteAccount(account)} type="button" className="btn btn-danger btn-wide">
            Delete
          </button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({account, modals}) => {
  return {
    account: account.account,
    modalIsOpen: modals.deleteModal,
  }
}

export default connect(mapStateToProps, {toggleDeleteModal, deleteAccount})(DeleteAccountModal)

