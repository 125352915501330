import { findIndex, last } from 'lodash'
import { statusMap } from '../config/fusion'
import { find } from 'lodash'

export const getTransitionText = (status) => find(statusMap, {status}).transitionText

export const getNextTransitionState = (status) => {
  // transitions it backs to 'Connected'
  if (status === 'Revoked') {
    return statusMap[2].status
  }

  let index = findIndex(statusMap, ['status', status]) + 1
  if (index > statusMap.length - 1) {
    return last(statusMap).status
  }
  return statusMap[index].status
}

export default {getTransitionText}