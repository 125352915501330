import React from 'react'
import { connect } from 'react-redux'
import TechedgeCampaignsList from './TechedgeCampaignsList'
import TechedgeCampaignsFilters from './TechedgeCampaignsFilters'
import { Row, Col } from 'react-bootstrap'

const TechedgeCampaignsTab = () => {
  return <div>
    <h2 className="page-title">Techedge Campaigns</h2>
    <TechedgeCampaignsFilters/>
    <Row style={{'marginTop': '24px'}} className="row">
      <Col lg={12}>
        <TechedgeCampaignsList/>
      </Col>
    </Row>
  </div>
}

export default connect()(TechedgeCampaignsTab)
