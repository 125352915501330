import React, { useEffect } from 'react'
import Navbar from './components/Navbar'
import { Switch, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Routes from './components/Routes'
import Modals from './components/Modals'
import { loadApp } from './reducers/userReducer'

const App = ({history, isAuthenticated, isLoading, loadApp}) => {
  if (window.location.protocol !== 'https:' && process.env.NODE_ENV !== 'development') {
    window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
  }

  useEffect(() => {
    loadApp()
  }, [loadApp])

  if (!isLoading && !isAuthenticated && history.location.pathname !== '/login') {
    return <Redirect to="/login"/>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <div className={isAuthenticated ? 'body-wrap' : ''}>
    <div className={isAuthenticated ? 'container-fluid has-max-width' : ''}>
      <Switch>
        <React.Fragment>
          {isAuthenticated && !isLoading && <header>
            <Navbar/>
          </header>}
          <Routes/>
          <Modals/>
        </React.Fragment>
      </Switch>
    </div>
  </div>
}

const mapStateToProps = ({user: {user, isAuthenticated, isLoading}}) => ({
  isAuthenticated,
  isLoading,
  user
})

export default withRouter(connect(mapStateToProps, {loadApp})(App))
