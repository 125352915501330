import React from 'react'
import { connect } from 'react-redux'
import { keys } from 'lodash'

const TrackpointLookupDateTable = ({columns, rows, isSearching, hasSearched}) =>
  <div>
    {isSearching && <div className='row'>
      <div className="spinner">Loading...</div>
    </div>}
    {hasSearched && <h3>No results found for given search input</h3>}
    {!isSearching && rows.length > 0 && <div>
      <div className='row'>
        <div className="col-md-12">
          <h3>Trackpoints with registered events for the given date</h3>
        </div>
      </div>

      <div className='row'>
        <div className="col-md-12">
          <table className="table table-vcenter table-projects">
            <thead>
            <tr>
              <th>Date</th>
              {columns.map(eventType =>
                <th key={eventType}>{eventType}</th>
              )}
            </tr>
            </thead>
            <tbody>
            {rows.map(item =>
              <tr key={item.id}>
                <td>{item.id}</td>
                {columns.map(eventType =>
                  <td key={`${eventType}${item.date}`}>{item.counts[eventType]}</td>
                )}
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>}
  </div>

const mapStateToProps = ({trackpoints: {isSearching, dateResults: {totals, trackpoints}}}) => {
  return {
    isSearching,
    columns: keys(totals).filter(eventType => totals[eventType] > 0),
    hasSearched: keys(totals).length > 0,
    rows: trackpoints,
  }
}

export default connect(mapStateToProps)(TrackpointLookupDateTable)

