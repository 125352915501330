import React from 'react'
import Radium from 'radium'
import { Dropdown } from 'react-bootstrap'

const caretStyles = {'marginLeft': '2px', 'backgroundColor': '#16d6d8'}

const itemStyles = {
  'display': 'block',
  'padding': '12px 20px',
  ':hover': {
    cursor: 'pointer',
    background: '#f1f5fa'
  },
}

const itemLabelStyle = {
  color: '#4c5d6d',
  fontSize: '14px',
  ':hover': {
    color: '#4c5d6d',
  },
}

const dangerStyle = {
  color: '#f14c4c',
  fontSize: '14px',
  ':hover': {
    color: '#4c5d6d',
  },
}

const DropDownMenu = ({items, mainAction}) =>
  <Dropdown>
    <button onClick={mainAction.action} className={`btn btn-primary ${mainAction.className}`}>
      {mainAction.label}
    </button>
    <Dropdown.Toggle style={caretStyles}
                     variant="success"
                     id="dropdown-basic"
                     className={`top-right btn btn-primary dropdown-toggle ${mainAction.className}`}>
      <span className="caret"/>
    </Dropdown.Toggle>
    <Dropdown.Menu drop={'left'}>
      {items.map(item =>
        <div key={item.label} className={item.className} onClick={item.action} style={itemStyles}>
          <Dropdown.Item style={item.status === 'danger' ? dangerStyle : itemLabelStyle}>
            <i className={`fui-  flaticon stroke ${item.icon}`}/>{item.label}
          </Dropdown.Item>
        </div>
      )}
    </Dropdown.Menu>
  </Dropdown>

export default Radium(DropDownMenu)