import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import FusionAccountsTab from './../components/fusionIntegration'
import TechedgeAccountTab from '../components/techedgeAccountTab'
import TechedgeCampaignsTab from '../components/techedgeCampaignsTab'
import CampaignManagerIntegrationTab from '../components/CampaignManagerIntegrationTab'
import AdformIntegrationTab from '../components/AdformIntegrationTab'
import { fetchFusionRequests } from './../reducers/fusionReducer'
import { fetchTechedgeCampaigns } from './../reducers/techedgeReducer'
import { fetchTechedgeAccounts } from '../reducers/accountsReducer'
import { NavLink, Route, Switch } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import TechedgeCampaign from '../components/TechedgeCampaign'
import { Row, Col } from 'react-bootstrap'
import DMSOperationsTab from '../components/DMSOperationsTab'

const IntegrationsView = ({fetchFusionRequests, fetchTechedgeCampaigns, fetchTechedgeAccounts, location}) => {
  const isTabActive = (path) => `/integrations/${path}` === location.pathname

  useEffect(() => {
    fetchFusionRequests()
    fetchTechedgeAccounts()
    fetchTechedgeCampaigns()
  }, [fetchFusionRequests, fetchTechedgeAccounts, fetchTechedgeCampaigns])

  const renderTabs = () => {
    const tabs = [{
      key: 'fusion',
      label: 'Fusion Accounts',
      path: 'fusion/accounts',
    }, {
      key: 'techedgeAccounts',
      label: 'Techedge Accounts',
      path: 'techedge/accounts',
    }, {
      key: 'techedgeCampaigns',
      label: 'Techedge Campaigns',
      path: 'techedge/campaigns',
    }, {
      key: 'campaignManager',
      label: 'Campaign manager',
      path: 'campaign-manager',
    }, {
      key: 'adform',
      label: 'Adform',
      path: 'adform/campaigns',
    },{
      key: 'dms',
      label: 'Danish Marked Standard',
      path: 'dms/operations',
    },
    ]

    return (
      <ul className="nav nav-tabs" role="tablist">
        {tabs.map(tab => (
          <li key={tab.key} className={isTabActive(tab.path) ? 'active' : ''}>
            <NavLink activeClassName="active" to={`/integrations/${tab.path}`}>{tab.label}</NavLink>
          </li>
        ))}
      </ul>
    )
  }

  const renderRoutes = () => {
    const routes = [
      {
        path: '/integrations/fusion/accounts',
        Component: FusionAccountsTab
      },
      {
        path: '/integrations/techedge/accounts',
        Component: TechedgeAccountTab
      },
      {
        path: '/integrations/techedge/campaigns',
        Component: TechedgeCampaignsTab
      },
      {
        path: '/integrations/campaign-manager',
        Component: CampaignManagerIntegrationTab
      },
      {
        path: '/integrations/adform/campaigns',
        Component: AdformIntegrationTab
      },
      {
        path: '/integrations/techedge/campaigns/:campaignId',
        Component: TechedgeCampaign
      },
      {
        path: '/integrations/dms/operations',
        Component: DMSOperationsTab
      }
    ]

    return <Switch>
      <Fragment>
        <Route exact path={`/integrations`} component={FusionAccountsTab}/>
        {routes.map(({path, Component}) => {
          return <Route exact key={path} path={path}>
            {({match}) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="page"
                unmountOnExit
              >
                <div className="page">
                  <Component/>
                </div>
              </CSSTransition>
            )}
          </Route>
        })}
      </Fragment>
    </Switch>
  }

  return (
    <div>
      <Row>
        <Col md='12'>
          {renderTabs()}
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          {renderRoutes()}
        </Col>
      </Row>
    </div>
  )
}

export default connect(null, {
  fetchFusionRequests,
  fetchTechedgeCampaigns,
  fetchTechedgeAccounts
})(IntegrationsView)
