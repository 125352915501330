import { campaignApi } from '../axios'

const initialState = {
  searchResults: [],
  isSearching: false
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_RESULTS':
      return {...state, searchResults: action.results}
    default:
      return state
  }
}

export const searchCampaigns = (query) => {
  return (dispatch) => {
    return campaignApi.get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/search/${query}/campaigns`)
      .then(response => {
        const options = response.data.data.map(({pid, name}) => {
          return {
            query, // hack based on AsyncTypeahead labelKey
            name: name,
            id: parseInt(pid),
          }
        })

        dispatch({
          type: 'SET_SEARCH_RESULTS',
          results: options
        })
      })
  }
}

export default searchReducer
