import React, {
  useEffect,
} from 'react'
import { connect } from 'react-redux'
import { fetchOperations } from '../reducers/DMSReducer'
import Spinner from './common/Spinner'
import LabelDate from './common/LabelDate'
import { orderBy } from 'lodash'
import { fetchReport } from '../reducers/reportReducer'
import { fetchAndSelectCampaign } from '../reducers/campaignReducer'
import { toggleCampaignModal } from '../reducers/modalReducer'

const DMSOperationsTab = ({operations, fetchOperations, toggleCampaignModal}) => {
  useEffect(() => {
    fetchOperations()
  }, [fetchOperations])

  const renderTableHeader = () => {
    return (
      <thead>
      <tr>
        <th>Name</th>
        <th>Created</th>
        <th>Period</th>
        <th>TV Stations</th>
      </tr>
      </thead>
    )
  }

  const renderChannels = operation => {
    return <td>
      {operation.channels.map(channel => (
        <a
          href='#'
          key={channel.dmsID}
          onClick={() => toggleCampaignModal(channel.dmsID)}
        >
          {channel.provider.toUpperCase()}
        </a>))}
    </td>
  }

  const renderTableBody = () => (
    <tbody>
    {operations.map((operation) => (
      <tr key={operation.total.id}>
        <td onClick={() => toggleCampaignModal(operation.total.id)}>{operation.name}</td>
        <td style={{'width': '15%'}}>
          <LabelDate date={operation.created}/>
        </td>
        <td style={{'width': '15%'}}>{operation.startDate} - {operation.endDate}</td>
        {renderChannels(operation)}
      </tr>
    ))}
    </tbody>
  )

  return <div>
    <h2 className='page-title'>
      Danish Marked Standard Operations
      <div>
        <small>Input Sheets:
          <a href={process.env.REACT_APP_DMS_TV2_SHEET} className="inner"> TV2</a>,
          <a href={process.env.REACT_APP_DMS_NENT_SHEET} className="inner"> NENT</a>,
          <a href={process.env.REACT_APP_DMS_DISCOVERY_SHEET} className="inner"> Discovery</a>
        </small>
      </div>
    </h2>

    {operations ?
      <table className="table table-vcenter table-projects">
        {renderTableHeader()}
        {renderTableBody()}
      </table> :
      <Spinner/>}
  </div>
}

const mapStateToProps = ({dms}) => ({
  operations: orderBy(dms.operations, ['created'], ['desc']),
})

const mapDispatchToProps = dispatch => ({
  fetchOperations: () => dispatch(fetchOperations()),
  toggleCampaignModal: (id) => {
    dispatch(fetchReport(id))
    dispatch(fetchAndSelectCampaign(id))
    dispatch(toggleCampaignModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DMSOperationsTab)
