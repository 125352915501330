import React, { useState } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { modalStyles } from './../styles/styles'
import { toggleCloneCampaignsModal } from '../reducers/modalReducer'
import { cloneCampaigns } from '../reducers/accountReducer'
import ModalFooter from '../components/ModalFooter'
import ModalHeader from '../components/ModalHeader'
import { deselectCampaign } from '../reducers/campaignReducer'
import { Typeahead } from 'react-bootstrap-typeahead'

const CloneCampaignsModal = ({options, campaigns, modalIsOpen, toggleModal, cloneCampaigns}) => {
  const [account, setAccount] = useState()

  const onSubmit = (event) => {
    event.preventDefault()
    cloneCampaigns(account[0], campaigns)
  }

  return <Modal
    closeTimeoutMS={200}
    isOpen={modalIsOpen}
    style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px', 'width': '30%'}}}
    onRequestClose={toggleModal}
  >
    <form onSubmit={onSubmit}>
      <div className="modal-content">
        <ModalHeader title={'Clone campaigns to new account'} onCancel={toggleModal}/>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <label>Campaigns to be cloned:</label>
              <div className="form-group">
                {campaigns.map(campaign =>
                  <small key={campaign.id}>{campaign.id} : {campaign.name}</small>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-xs-12">
              <div>
                <label>Destination account</label>
              </div>
              <div>
                <Typeahead
                  id='move-account-selector'
                  bsSize={'small'}
                  placeholder={'Select Account'}
                  options={options}
                  onChange={(selected) => setAccount(selected)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <small className='col-lg-12'>
              Note: for consistency reasons, only CIDs that the current account has access to are displayed.
              You can add more from each account's settings.
            </small>
          </div>
        </div>

        <ModalFooter onCancel={toggleModal} submitLabel={'Clone'}/>
      </div>
    </form>
  </Modal>
}

const mapStateToProps = ({modals, account, accounts, campaign}) => {
  let campaigns = campaign.selectedCampaign.id
    ? [campaign.selectedCampaign]
    : campaign.selectedCampaigns

  return {
    campaigns,
    modalIsOpen: modals.cloneCampaignsModal,
    options: accounts.audienceReportAccounts.filter(ARAccount =>
      ARAccount.id !== account.account.id
    ).map(({id, name, cid}) => {
      return {id: cid, label: `${name} : ${cid}`}
    }),
  }
}

const mapDispatchToProps = dispatch => ({
  cloneCampaigns: (account, campaigns) => dispatch(cloneCampaigns(account, campaigns)),
  toggleModal: () => {
    dispatch(deselectCampaign())
    dispatch(toggleCloneCampaignsModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CloneCampaignsModal)

