import React from 'react'

const ModalHeader = ({onCancel, title, children}) => <div className="modal-header">
  <button type="button"
          className="close"
          onClick={onCancel}>
    <span style={{'fontSize': '24px'}} aria-hidden="true">&times;</span>
    <span className="sr-only">Close</span>
  </button>
  {children}
  <h3 className="modal-title">{title}</h3>
</div>

export default ModalHeader

