import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Route, Switch } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Col, Row } from 'react-bootstrap'
import { loadAccount } from '../reducers/accountReducer'
import Spinner from '../components/common/Spinner'
import AccountModalsDropdown from '../components/AccountModalsDropdown'
import AccountCampaignsTab from '../components/AccountCampaignsTab'
import AccountAPIKeysTab from '../components/AccountApiKeysTab'

const AccountView = ({
  match,
  loadAccount,
  account,
  organization,
}) => {
  useEffect(() => {
    loadAccount(match.params.accountId)
  }, [loadAccount, match.params.accountId])

  if (!account || !organization) {
    return <Spinner/>
  }

  const renderTabs = () => {
    const tabs = [
      {
        key: 'campaigns',
        label: 'Campaigns',
        path: `${match.url}/campaigns`,
      },
      {
        key: 'APIKeys',
        label: 'API Client',
        path: `${match.url}/api`,
      }
    ]

    return <ul className="nav nav-tabs" role="tablist">
      {tabs.map(tab => <li key={tab.key}>
          <NavLink to={tab.path}>
            {tab.label}
          </NavLink>
        </li>
      )}
    </ul>
  }

  const renderRoutes = () => {
    const routes = [
      {
        path: '/accounts/:accountId',
        Component: AccountCampaignsTab
      },
      {
        path: '/accounts/:accountId/campaigns',
        Component: AccountCampaignsTab
      },
      {
        path: '/accounts/:accountId/api',
        Component: AccountAPIKeysTab
      }
    ]

    return <Switch>
      <Fragment>
        {routes.map(({path, Component}) => {
          return <Route exact key={path} path={path}>
            {({match}) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="accountTabs"
                unmountOnExit
              >
                <div className="accountTab">
                  <Component/>
                </div>
              </CSSTransition>
            )}
          </Route>
        })}
      </Fragment>
    </Switch>
  }

  return (
    <div>
      <div>
        <AccountModalsDropdown/>
        <h2 className="page-title page-breadcrumb">
          <NavLink to='/accounts'>
            Accounts
          </NavLink>

          {account.name} &nbsp;
          <small>belongs to &nbsp;
            <a href={`${process.env.REACT_APP_CP_AUDIENCEPROJECT_URL}/organizations/${organization.id}`}
              className="inner">{organization.name}
            </a>
            &nbsp; organization. User management happens in &nbsp;
            <a href={`${process.env.REACT_APP_CP_AUDIENCEPROJECT_URL}/accounts/${account.id}/users`}
              className="inner">
              AudienceProject Control Panel
            </a>
          </small>
        </h2>
        {renderTabs()}
      </div>
      <Row>
        <Col md='12'>
          {renderRoutes()}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ account: {account, organization }}) => {
  return {
    account,
    organization
  }
}

export default connect(mapStateToProps, {loadAccount})(AccountView)