import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { connect } from 'react-redux'
import useForm from 'react-hook-form'
import DatePicker from 'react-datepicker'
import AccountFormOrganization from './AccountFormOrganization'
import AccountFormFeatures from './AccountFormFeatures'
import AccountFormPermissions from './AccountFormPermissions'
import { errorStyles } from '../styles/styles'

const AccountForm = ({
  cids,
  action,
  account,
  organizations,
  initialPermissions,
  onClose,
}) => {
  const [permissions, setPermissions] = useState({selectedOption: initialPermissions});
  const [migrationDate, setMigrationDate] = useState();
  const [migrationDateExist, setMigrationDateExist] = useState();
  const [migrationDateLoaded, setMigrationDateLoaded] = useState(false);

  const migrationDateDisabled = migrationDate && new Date(migrationDate) < new Date();

  const {
    errors,
    register,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      'name': account.name,
      'cid': account.cid,
      'organization': account.organization.id,
      'permissions': initialPermissions,
      'features': []
    }
  });

  const updateAccountName = data => {
    const endpoint = `${process.env.REACT_APP_API_AUDIENCEPROJECT_URL}/org/v1/accounts/${account.APAccountId}`;
    const headers = {
      'content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('ssoToken')}`,
    };

    return fetch(endpoint, {
      headers,
      method: 'PATCH',
      body: JSON.stringify({
        name: data.name,
      })
    });
  };

  const requestMigrationDate = useCallback((query, variables) => {
    const endpoint = `${process.env.REACT_APP_API_AUDIENCEPROJECT_URL}/ar/migration/v1/graphql`;
    const headers = {
      'content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('ssoToken')}`,
    };

    return fetch(endpoint, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        query: query.replace(/\s/g, ''),
        variables,
      })
    }).then(response => response.json());
  }, []);

  const loadMigrationDate = useCallback(() => {
    const query = `query(
        $accountId: Int!
      ) {
        accountIntegrationMigration(
          accountId: $accountId
        ) {
          migrationDate
        }
      }`;

    requestMigrationDate(query, {
      accountId: account.id,
    })
      .then(json => {
        setMigrationDateLoaded(true);

        if (json.errors) {
          const [error] = json.errors;
          console.error(error.message);
        } else {
          setMigrationDate(new Date(json.data.accountIntegrationMigration.migrationDate));
          setMigrationDateExist(true);
        }
      });
  }, [account.id, requestMigrationDate]);

  const changeMigrationDate = () => {
    const action = migrationDateExist ?
      'updateAccountIntegrationMigration' :
      'createAccountIntegrationMigration';
    const mirgation = migrationDateExist ?
      'UpdateAccountIntegrationMigrationInput!' :
      'CreateAccountIntegrationMigrationInput!';

    const query = `mutation(
        $accountId: Int!,
        $migration: ${mirgation}
      ) {
        ${action}(
          accountId: $accountId,
          migration: $migration
        ) {
          migrationDate
        }
      }`;

    requestMigrationDate(query, {
      accountId: account.id,
      migration: {
        migrationDate,
      },
    });
  };

  const deleteMigrationDate = () => {
    const query = `mutation(
      $accountId: Int!,
    ) {
      deleteAccountIntegrationMigration(
        accountId: $accountId,
      )
    }`;

  setMigrationDateLoaded(false);

  requestMigrationDate(query, {
    accountId: account.id,
  })
    .then(() => {
      setMigrationDate();
      setMigrationDateLoaded(true);
    });
  };

  useEffect(() => {
    if (!migrationDateLoaded) {
      loadMigrationDate();
    }
  }, [migrationDateLoaded, loadMigrationDate]);

  useEffect(() => {
    register({name: 'permissions'})
    register({name: 'organization'})
  }, [register]);

  const handlePermissionChange = value => {
    let selectedOption = value === null
      ? []
      : value

    setValue('permissions', selectedOption)
    setPermissions({selectedOption})
  }

  const handleOrganizationChange = organization => {
    setValue('organization', organization)
  }

  const handleMigrationDateChange = value => {
    setMigrationDate(value);

    if (!value && !migrationDateDisabled && migrationDateExist) {
      deleteMigrationDate();
    }
  }

  const onSubmit = data => {
    // needed for custom input
    if (data.organization === undefined) {
      setError('organization', 'required', 'Organization is required')
    } else {
      let newFeatures = Object
        .keys(data.features)
        .filter(feature => data.features[feature])

      updateAccountName(data);
      action({
        ...data,
        id: account.id,
        features: newFeatures,
      });
    }

    if (migrationDate) {
      changeMigrationDate();
    }
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <div className="modal-body">
      <div className="row">
        <div className="col-xs-6">
          <div className="form-group">
            <label>Acccount name </label>
            <input
              name="name"
              ref={register({required: true})}
              className="form-control"
              style={errors.name ? errorStyles : {}}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <div className="form-group">
            <label>CID (3-character company prefix)</label>
            <input
              name="cid"
              disabled={account.id !== undefined}
              style={errors.cid ? errorStyles : {}}
              ref={register({
                required: true,
                minLength: 3,
                maxLength: 3
              })}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <AccountFormOrganization
        organizations={organizations}
        initialValue={account.organization.name}
        handleChange={handleOrganizationChange}
        errors={errors}
      />
      <div className='form-group'>
        <label>Migration to Next Date</label>
        <DatePicker
          minDate={new Date()}
          selected={migrationDate}
          disabled={migrationDateDisabled}
          className='form-control omnisearch full-width'
          dateFormat='yyyy-MM-dd'
          placeholderText={migrationDateLoaded ? 'Select a date…' : 'Loading…'}
          onChange={handleMigrationDateChange}
          isClearable={!migrationDateDisabled}
        />
      </div>
      <AccountFormPermissions
        cids={cids}
        initialValue={permissions.selectedOption}
        onChange={handlePermissionChange}
      />
      <AccountFormFeatures
        accountFeatures={account.features}
        register={register}
      />
    </div>
    <div className="modal-footer">
      <button onClick={onClose} type="button" className="btn btn-default btn-wide">Cancel</button>
      <button type="submit" className="btn btn-primary btn-wide">Save</button>
    </div>
  </form>
}

const mapStateToProps = ({accounts}, {action, onClose, account}) => {
  return {
    account,
    action: action,
    onClose: onClose,
    initialPermissions: account.permissions.map(permission => {
      return {label: permission.cid, value: permission.id}
    }),
    organizations: accounts.organizations.map(organization => {
      return {label: organization.name, value: organization.id}
    }),
    cids: accounts.audienceReportAccounts.map(account => {
      return {label: account.cid, value: account.id}
    }),
  }
}

export default connect(mapStateToProps)(AccountForm)
