import { checkTokenHasCPAccess, fetchUserDetails, obtainToken } from '../util/auth'
import { fetchAudienceReportAccounts, fetchOrganizations } from './accountsReducer'
import moment from 'moment'
import { toast } from 'react-toastify'
import { setMasterAccount } from './accountReducer'
import { fetchFeatures } from './featureReducer'

const initialState = {
  user: {},
  isAuthenticated: false,
  isLoading: true,
  isAdmin: false,
  lastRefresh: moment()
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {...state, user: action.user}
    case 'TOGGLE_LOADING':
      return {...state, isLoading: !state.isLoading}
    case 'SET_IS_AUTHENTICATED':
      return {...state, isAuthenticated: action.value}
    case 'SET_IS_ADMIN':
      return {...state, isAdmin: true}
    case 'SET_LAST_TOKEN_REFRESH':
      return {...state, lastRefresh: moment()}
    default:
      return state
  }
}

const setIsAuthenticated = (value) => ({
  type: 'SET_IS_AUTHENTICATED',
  value
})

const setIsAdmin = () => ({
  type: 'SET_IS_ADMIN'
})

const setUser = () => ({
  type: 'SET_USER'
})

const setLastRefresh = () => ({
  type: 'SET_LAST_TOKEN_REFRESH'
})

const toggleLoading = () => ({
  type: 'TOGGLE_LOADING'
})

// 1: loads sso token and verifies it CP access
// 2: loads AR accounts and refreshes token every 5 minute
// 3: sets master account for general CP actions
// 4: loads features and organizations
export const loadApp = () => {
  return (dispatch) => {
    getUserToken().then(response => {
      if (response.idToken !== undefined) {
        checkTokenHasCPAccess(response.idToken).then(() => {
          setInterval(() => dispatch(refreshToken()), 5000);
          dispatch(loadUserDetails(response.idToken))
          dispatch(fetchAudienceReportAccounts())
          dispatch(fetchOrganizations())
          dispatch(fetchFeatures())
          dispatch(setMasterAccount())
          dispatch(setIsAuthenticated(true))
          dispatch(toggleLoading())
        }).catch((error) => {
          toast.success(`You are not authorized to use the control panel - contact support to gain access`);

          dispatch(toggleLoading())
        })
      } else {
        dispatch(toggleLoading())
      }
    }).catch(response => window.alert("You need to be on VPN in order to access the control panel"))
  }
}

export const getUserToken = () => {
  return obtainToken().then(response => {
    window.localStorage.setItem('ssoToken', response.idToken)
    return response
  })
}

export const refreshToken = () => {
  return (dispatch, getState) => {
    let {lastRefresh} = getState().user

    if (moment(lastRefresh).add('5', 'minutes').isAfter(moment())) {
      return
    }

    getUserToken().then(response => {
      if (response.idToken !== undefined) {
        checkTokenHasCPAccess(response.idToken).then(() => {
          dispatch(setLastRefresh())
        }).catch(() => {
          dispatch(setIsAuthenticated(false))
        })
      }
    })
  }
}

const loadUserDetails = (idToken) => {
  return (dispatch) => {
    fetchUserDetails(idToken).then(({data}) => {
      const options = (process.env.NODE_ENV !== 'development') ? 'secure; samesite=strict;' : ''
      document.cookie = `idToken=${idToken}; path=/api/; ${options}`
      document.cookie = 'email=' + data.email
      if (process.env.REACT_APP_IAB_ADMINSTRATOR_EMAILS.indexOf(data.email) !== -1) {
        dispatch(setIsAdmin())
      }

      dispatch(setUser(data))
    })
  }
}

export default userReducer
