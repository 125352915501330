import axios from 'axios'

export const obtainToken = () => {
  return fetch(process.env.REACT_APP_API_AUDIENCEPROJECT_URL + '/sso/v1/cp/token', {
    cors: true,
    method: 'POST',
    credentials: 'include',
    body: '{}',
    headers: {
      'content-type': 'application/json',
    },
  }).then(response => response.json())
}

export const fetchUserDetails = (idToken) => {
  return fetch(process.env.REACT_APP_API_AUDIENCEPROJECT_URL + '/org/v1/users/me', {
    cors: true,
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${idToken}`,
    },
  }).then(response => response.json())
}

export const checkTokenHasCPAccess = (idToken) => {
  return axios
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/token/isValid`, {
      headers: {'Authorization': `Bearer ${idToken}`}
    })
    .then(response => response)
    .catch(error => {
      throw error
    })
}