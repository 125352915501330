import axios from 'axios'

const getAuthConfig = config => {
  let token = localStorage.getItem('ssoToken')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['Customer-Id'] = 2
  }

  return config
}

const getNonImpersonatedAuthConfig = config => {
  let token = localStorage.getItem('ssoToken')

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
}

export const publicCampaignApi = axios.create({
  headers: {'Authorization': `Bearer ${localStorage.getItem('ssoToken')}`}
})

export const integrationsApi = axios.create({
  headers: {'Authorization': `Bearer ${localStorage.getItem('ssoToken')}`}
})

export const campaignApi = axios.create(
  {baseURL: `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel`}
)

campaignApi.interceptors.request.use(
  getAuthConfig,
  error => Promise.reject(error)
)

integrationsApi.interceptors.request.use(
  getAuthConfig,
  error => Promise.reject(error)
)

publicCampaignApi.interceptors.request.use(
  getNonImpersonatedAuthConfig,
  error => Promise.reject(error)
)

export default {
  campaignApi,
  publicCampaignApi,
  integrationsApi,
}