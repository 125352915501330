import { requestRawLogs, fetchRawLogExports } from "../util/api";
import { toggleCampaignExportModal } from "./modalReducer";
import { toast } from "react-toastify";

const initialState = {
  campaignExports: [],
  searchTerm: "",
};

const exportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EXPORTS":
      return { ...state, campaignExports: action.exports };
    case "SET_TERM":
      return { ...state, searchTerm: action.term };
    default:
      return state;
  }
};

export const setSearchTerm = (term) => ({
  type: "SET_TERM",
  term,
});

export const setExports = (exports) => ({
  type: "SET_EXPORTS",
  exports,
});

export const fetchExports = () => {
  return (dispatch) => {
    fetchRawLogExports().then(({ data }) => dispatch(setExports(data)));
  };
};

export const triggerExport = ({ type, iab, campaign: { id, name } }) => {
  return (dispatch) => {
    let payload = { iab, type };

    dispatch(toggleCampaignExportModal());

    return requestRawLogs(payload, id).then(() => {
      toast.success(`Raw logs job for ${name} (${id}) has been submitted`);
    });
  };
};

export default exportReducer;
