import React from 'react'
import { connect } from 'react-redux'
import {
  toggleCloneCampaignsModal,
  toggleDeleteCampaignsModal,
  toggleMoveCampaignsModal,
  toggleCampaignExportModal,
  toggleCampaignModal
} from '../reducers/modalReducer'
import { fetchReport } from '../reducers/reportReducer'
import { fetchAndSelectCampaign, selectCampaign } from '../reducers/campaignReducer'
import DropDownMenu from './DropdownMenu'

const CampaignActionDetails = ({
                                 campaign,
                                 toggleMoveModal,
                                 toggleDeleteModal,
                                 toggleCloneModal,
                                 toggleExportModal,
                                 toggleCampaignModal,
                               }) => {
  const actionItems = [
    {
      label: 'Raw log export',
      action: () => toggleExportModal(campaign),
      icon: 'download-2',
    },
    {
      label: 'Move campaign',
      action: () => toggleMoveModal(campaign),
      icon: 'export-document-1',
    },
    {
      label: 'Clone campaign',
      action: () => toggleCloneModal(campaign),
      icon: 'multiple-documents-1',
    },
    {
      label: 'Delete campaign',
      action: toggleDeleteModal,
      icon: 'trash-1',
      status: 'danger'
    }
  ]

  const mainAction = {
    label: 'Details',
    className: 'btn-sm',
    action: () => toggleCampaignModal(campaign.id)
  }

  return <div className="btn-group btn-group-sm pull-right">
    <DropDownMenu mainAction={mainAction} items={actionItems}/>
  </div>
}

const mapStateToProps = (_, {campaign}) => {
  return {
    campaign
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleCampaignModal: (id) => {
    dispatch(fetchReport(id))
    dispatch(fetchAndSelectCampaign(id))
    dispatch(toggleCampaignModal())
  },
  toggleExportModal: (campaign) => {
    dispatch(selectCampaign(campaign))
    dispatch(toggleCampaignExportModal())
  },
  toggleMoveModal: (campaign) => {
    dispatch(selectCampaign(campaign))
    dispatch(toggleMoveCampaignsModal())
  },
  toggleCloneModal: (campaign) => {
    dispatch(selectCampaign(campaign))
    dispatch(toggleCloneCampaignsModal())
  },
  toggleDeleteModal: (campaign) => {
    dispatch(selectCampaign(campaign))
    dispatch(toggleDeleteCampaignsModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignActionDetails)
