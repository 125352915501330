import React from 'react'
import Modal from 'react-modal'
import { modalStyles } from './../styles/styles'
import { connect } from 'react-redux'
import { toggleEditModal } from '../reducers/modalReducer'
import { editAccount } from '../reducers/accountsReducer'
import AccountForm from '../components/AccountForm'

const EditAccountModal = ({
  account,
  modalIsOpen,
  editAccount,
  toggleEditModal,
}) => (
  <Modal
    closeTimeoutMS={200}
    isOpen={modalIsOpen}
    style={{
      ...modalStyles,
      content: {
        ...modalStyles.content,
        width: 600,
        height: '75%',
        padding: 0,
        overflow: 'auto',
      }}}
    onRequestClose={toggleEditModal}
  >
    <div>
      <div className="modal-header">
        <h3 className="modal-title">Edit account</h3>
      </div>
      <AccountForm account={account} action={editAccount} onClose={toggleEditModal}/>
    </div>
  </Modal>
);

const mapStateToProps = ({account, accounts, features, modals}) => {
  return {
    modalIsOpen: modals.editModal,
    account: {...account.account, organization: account.organization},
    organization: account.organization,
    features: features.features,
    cids: accounts.audienceReportAccounts.map(account => {
      return {label: account.cid, value: account.id}
    })
  }
}

export default connect(mapStateToProps, {toggleEditModal, editAccount})(EditAccountModal)

