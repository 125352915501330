import {
  getIntegrations,
} from '../util/campaignManagerApi';
import { sortBy } from 'lodash';

const REQUEST_DCM_INTEGRATIONS = 'REQUEST_DCM_INTEGRATIONS';
const RECEIVE_DCM_INTEGRATIONS = 'RECEIVE_DCM_INTEGRATIONS';

const initialState = {
  integrations: null,
}

const campaignManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DCM_INTEGRATIONS:
      return {
        ...state,
        integrations: null,
      };

    case RECEIVE_DCM_INTEGRATIONS:
      return {
        ...state,
        integrations: sortBy(
          action.campaignManagerIntegrations
            .filter(item => (item.status !== 'GoogleAuthRequested' && item.status !== 'Pending')),
          ['audienceReportCID']
        )};

    default:
      return state;
  }
}

export const replaceIntegrations = () => dispatch => (
  getIntegrations()
    .then(campaignManagerIntegrations => dispatch({
      type: RECEIVE_DCM_INTEGRATIONS,
      campaignManagerIntegrations,
    }))
);

export const fetchIntegrations = () => dispatch => {
  dispatch({
    type: REQUEST_DCM_INTEGRATIONS,
  });

  return dispatch(replaceIntegrations());
};

export default campaignManagerReducer;
