import {
  fetchIABConsents, updateIABConsent, createIABCosent, deleteIABCosent
} from '../util/api'
import { toast } from 'react-toastify'

const initialState = {
  consents: [],
  isLoading: false,
  isFetching: true,
  consentTypes: [
    {
      key: 'storage_and_access',
      label: 'Storage and access',
    },
    {
      key: 'personalization',
      label: 'Personalization',
    },
    {
      key: 'content_delivery',
      label: 'Content selection, delivery, reporting',
    },
    {
      key: 'measurement',
      label: 'Measurement',
    },
    {
      key: 'ad_selection',
      label: 'Ad selection, delivery, reporting',
    }
  ]
}

const IABConsentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IAB_CONSENTS':
      return {...state, consents: action.consents}
    case 'ADD_IAB_CONSENT':
      return {...state, consents: state.consents.concat(action.consent)}
    case 'REMOVE_IAB_CONSENT':
      let consents = state.consents.filter(consent => consent.value !== action.consent.value)
      return {...state, consents: consents}
    case 'TOGGLE_IAB_LOADING':
      return {...state, isLoading: !state.isLoading}
    case 'TOGGLE_IAB_FETCHING':
      return {...state, isFetching: !state.isFetching}
    default:
      return state
  }
}

export const createConsent = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_IAB_LOADING'
    })

    createIABCosent(data).then(() => {
      toast.success(`Consent has been created`);

      dispatch({
        type: 'ADD_IAB_CONSENT',
        consent: data
      })

      dispatch({
        type: 'TOGGLE_IAB_LOADING'
      })

      dispatch({
        type: 'TOGGLE_CREATE_IAB_CONSENT_MODAL'
      })
    })
  }
}

export const deleteConsent = (consent) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_IAB_LOADING'
    })

    deleteIABCosent(consent).then(() => {
      toast.success(`Consent ${consent.value} has been deleted`);

      dispatch({
        type: 'REMOVE_IAB_CONSENT',
        consent
      })

      dispatch({
        type: 'TOGGLE_IAB_LOADING'
      })
    })
  }
}

export const updateConsent = (consent) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_IAB_LOADING'
    })

    updateIABConsent(consent).then(() => {
      toast.success(`Consent ${consent.value} has been updated`);

      dispatch({
        type: 'TOGGLE_IAB_LOADING'
      })
    })
  }
}

export const fetchConsents = () => {
  return (dispatch) => {
    return fetchIABConsents()
      .then(data => {
        dispatch({
          type: 'SET_IAB_CONSENTS',
          consents: data
        })

        dispatch({
          type: 'TOGGLE_IAB_FETCHING',
        })
      })
  }
}

export default IABConsentReducer
