import React from 'react'
import { connect } from 'react-redux'
import TrackpointSearch from '../components/TrackpointSearch'
import TrackpointDateSearch from '../components/TrackpointDateSearch'
import { NavLink, Route, Switch } from 'react-router-dom'

const TrackpointView = ({match, location}) => {
  const isTabActive = (path) => {
    return path === location.pathname ? 'active' : ''
  }

  return <div>
    <h2 className="page-title">Trackpoint lookup</h2>
    <ul className="nav nav-tabs" role="tablist">
      <li className={isTabActive('/trackpoints')}>
        <NavLink activeClassName="active" to="/trackpoints">Search by trackpoint</NavLink>
      </li>
      <li className={isTabActive('/trackpoints/dateSearch')}>
        <NavLink activeClassName="active" to="/trackpoints/dateSearch">Search trackpoint by date</NavLink>
      </li>
    </ul>

    <Switch>
      <Route exact path={`${match.url}/`} component={TrackpointSearch}/>
      <Route exact path={`/trackpoints/dateSearch`} component={TrackpointDateSearch}/>
    </Switch>
  </div>
}

export default connect()(TrackpointView)
