import { campaignApi } from '../axios'

const initialState = {
  features: [],
}

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FEATURES':
      return {...state, features: action.features}
    default:
      return state
  }
}

export const setFeatures = features => ({
  type: 'SET_FEATURES',
  features,
})

export const fetchFeatures = () => {
  return (dispatch) => {
    return campaignApi
      .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/features`)
      .then(response => dispatch(setFeatures(response.data.data)))
  }
}

export default featureReducer
