import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import {
  getAccountIntegrations,
} from '../util/adformApi';
import Spinner from './common/Spinner';
import LabelExtraName from './common/LabelExtraName';
import AdformIntegrationOverview from './AdformIntegrationOverview';

const AdformIntegrationDetails = ({
  accountId,
  audienceReportCID,
  audienceReportAccountId,
  onHideDetails,
}) => {
  const [integration, setIntegration] = useState();

  useEffect(() => {
    getAccountIntegrations(accountId)
      .then(json => {
        setIntegration(json);
      });
  }, [accountId]);

  const renderAdvertisersHeader = () => {
    const row = [
      'Selected Advertisers',
    ];

    return (
      <thead>
        <tr>
          {row.map((cell, index) => (
            <th key={index}>
              {cell}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  const renderAdvertisers = () => {
    const { selectedAdvertisers: advertisers } = integration;

    return (
      <table className='table table-vcenter table-projects table-scrollable'>
        {renderAdvertisersHeader()}
        <tbody>
          {sortBy(advertisers)
            .map(advertiser => (
              <tr key={advertiser}>
                <td>
                  {advertiser}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const renderContent = () => {
    if (!integration) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <AdformIntegrationOverview integration={integration} />
        {renderAdvertisers()}
      </React.Fragment>
    );
  }

  return (
    <div>
      <h2 className='page-title page-breadcrumb'>
        <button onClick={onHideDetails}>
          Adform Integrations
        </button>
        <LabelExtraName
          name={audienceReportCID}
          extra={audienceReportAccountId}
        />
      </h2>

      {renderContent()}
    </div>
  );
};

AdformIntegrationDetails.propTypes = {
  accountId: PropTypes.number.isRequired,
  audienceReportCID: PropTypes.string.isRequired,
  audienceReportAccountId: PropTypes.number.isRequired,
  onHideDetails: PropTypes.func.isRequired,
};

export default AdformIntegrationDetails;
