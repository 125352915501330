import React from 'react'
import { connect } from 'react-redux'
import { some } from 'lodash'
import { withRouter } from 'react-router-dom'
import { clearJSONData } from '../reducers/techedgeReducer'
import { getSnapshotStyles, getSnapshotIcon } from '../styles/styles'

const TechedgeCampaignsList = ({campaigns, history, clearJSONData}) => {
  const redirectToCampaign = campaign => {
    clearJSONData()
    history.push({
      pathname: `/integrations/techedge/campaigns/${campaign.pid}`,
      state: {campaign}
    })
  }

  return <table className="table table-vcenter">
    <thead>
    <tr>
      <th>Source campaign</th>
      <th>PID</th>
      <th>Type</th>
      <th>Creation date</th>
      <th>Snapshots</th>
      <th/>
    </tr>
    </thead>
    <tbody>
    {campaigns.map(campaign =>
      <tr onClick={() => redirectToCampaign(campaign)} key={campaign.pid}>
        <td>{campaign.name}</td>
        <td>{campaign.pid}</td>
        <td>{campaign.type}</td>
        <td>{campaign.created}</td>
        <td>
          {campaign.snapshots.map(snapshot =>
            <div key={snapshot.id}>
              {snapshot.id}
              <i style={getSnapshotStyles(snapshot.status)} className={getSnapshotIcon(snapshot.status)}/>
            </div>
          )}
        </td>
      </tr>
    )}
    </tbody>
  </table>
}

const mapStateToProps = ({techedge: {filters: {campaignType, snapshotType, accountId, searchId}, campaigns}}) => {
  return {
    campaigns: campaigns
      .filter(campaign => campaignType === 'all' || campaign.type === campaignType)
      .filter(campaign => snapshotType === 'all' || some(campaign.snapshots, {'status': snapshotType}))
      .filter(campaign => accountId === 'all' || campaign.accountId === parseInt(accountId))
      .filter(campaign => {
        return searchId === null
          || campaign.name.toLowerCase().includes(searchId)
          || campaign.pid.toLowerCase().includes(searchId)
          || campaign.snapshots.find(snapshot => snapshot.id.toLowerCase().includes(searchId))
      })
  }
}

export default withRouter(
  connect(mapStateToProps, {clearJSONData})(TechedgeCampaignsList)
)
