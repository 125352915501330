import React from 'react'
import { connect } from 'react-redux'

const TrackpointLookupDates = ({columns, pattern, results, searchParams}) =>
  <div>
    {results.length === 0 && searchParams !== null && <div>
      No Trackpoints found with registered events for the pattern {pattern}.
    </div>}

    {results.map(trackpointResult => <div key={trackpointResult.id}>
      <div className='row'>
        <div className="col-md-12">
          <h3> Event counts for {trackpointResult.id} </h3>
        </div>
      </div>
      <table className="table table-vcenter table-projects">
        <thead>
        <tr>
          <th>Date</th>
          {columns.map(eventType =>
            <th key={eventType}>{eventType}</th>
          )}
        </tr>
        </thead>
        <tbody>
        {trackpointResult.history.map(item =>
          <tr key={item.date}>
            <td>{item.date}</td>
            {columns.map(eventType =>
              <td key={`${eventType}${item.date}`}>{item.counts[eventType]}</td>
            )}
          </tr>
        )}
        </tbody>
      </table>
    </div>)}
  </div>

const mapStateToProps = ({trackpoints: {searchParams, lookupResults, columns}}) => {
  return {
    lookupResults: lookupResults.pattern,
    results: lookupResults.results,
    columns,
    searchParams,
  }
}

export default connect(mapStateToProps)(TrackpointLookupDates)
