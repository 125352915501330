import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import {
  fetchIntegrations,
} from '../reducers/adformReducer';
import { Row } from 'react-bootstrap'
import Spinner from './common/Spinner';
import LabelDate from './common/LabelDate';
import LabelStatus from './common/LabelStatus';
import LabelExtraName from './common/LabelExtraName';
import AdformIntegrationDetails from './AdformIntegrationDetails';

const STATUS_DEFAULT = 'all';

const AdformIntegrationTab = ({
  integrations,
  fetchIntegrations,
}) => {
  const [showDetails, setShowDetails] = useState();
  const [status, setStatus] = useState(STATUS_DEFAULT);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  function onShowDetails(integration) {
    setShowDetails(integration);
  }

  function onHideDetails() {
    setShowDetails(null);
  }

  const renderTableHeader = () => {
    const row = [
      'AudienceReport Account',
      'Agency',
      'Activated',
      'Advertisers',
      'Country',
      'Email',
      'Status',
    ];

    return (
      <thead>
        <tr>
          {row.map((cell, index) => (
            <th key={index}>
              {cell}
            </th>
          ))}
          <th />
        </tr>
      </thead>
    );
  }

  const renderTableRow = (id, row) => (
    <tr key={id}>
      {row.map((cell, index) => (
        <td key={index}>
          {cell}
        </td>
      ))}
    </tr>
  );

  const renderARAccount = ({ audienceReportCID, audienceReportAccountId }) => (
    <a href={`/accounts/${audienceReportAccountId}`}>
      <LabelExtraName
        name={audienceReportCID}
        extra={audienceReportAccountId}
      />
    </a>
  );

  const renderAdvertisers = advertisers => {
    if (!advertisers || advertisers.length === 0) {
      return 0;
    }

    const tail = advertisers.length > 5 ? '…' : '';
    const title = [...advertisers].splice(0, 5).join(', ');

    return (
      <Tooltip
        size='big'
        title={`${title}${tail}`}
      >
        <span className={'label label-info'}>
          {advertisers.length}
        </span>
      </Tooltip>
    );
  }

  const renderOptions = integration => (
    <button
      className="btn btn-default"
      onClick={() => onShowDetails(integration)}
    >
      Details
    </button>
  );

  const renderTableBody = () => (
    <tbody>
      {integrations
        .filter(row => status === STATUS_DEFAULT || row.status === status)
        .map((integration, index) => (
          renderTableRow(index, [
            renderARAccount(integration),
            <LabelExtraName name={integration.agencyNames} extra={integration.agencyIds} />,
            <LabelDate date={integration.activatedAt} />,
            renderAdvertisers(integration.selectedAdvertisers),
            integration.country,
            integration.connectedAccountEmail,
            <LabelStatus status={integration.status} />,
            renderOptions(integration),
          ])
        ))}
    </tbody>
  );

  const renderFilters = () => {
    const statuses = integrations.reduce((memo, item) => {
      if (memo.includes(item.status)) {
        return memo;
      }

      return memo.concat([item.status]).sort();
    }, []);

    return (
      <Row>
        <div className='col-xs-2'>
          <select
            onChange={event => setStatus(event.target.value)}
            className="form-control input-sm">
            <option value={STATUS_DEFAULT}>
              All statuses
            </option>
            {statuses.map(state => <option key={state} value={state}>{state}</option>)}
          </select>
        </div>
      </Row>
    );
  };

  const renderTable = () => (
    <React.Fragment>
      {renderFilters()}
      <Row style={{ marginTop: 24 }}>
        <table className="table table-vcenter table-projects">
          {renderTableHeader()}
          {renderTableBody()}
        </table>
      </Row>
    </React.Fragment>
  );

  const renderIntegrations = () => (
    <div>
      <h2 className='page-title'>
        Adform Integrations
      </h2>

      {integrations ?
        renderTable() :
        <Spinner />}
    </div>
  );

  const renderIntegrationDetails = () => {
    const {
      audienceReportAccountId: accountId,
      audienceReportCID,
      audienceReportAccountId,
    } = showDetails;

    return (
      <AdformIntegrationDetails
        accountId={accountId}
        audienceReportCID={audienceReportCID}
        audienceReportAccountId={audienceReportAccountId}
        onHideDetails={onHideDetails}
      />
    );
  };

  return (
    showDetails ?
      renderIntegrationDetails() :
      renderIntegrations()
  );
};

const mapStateToProps = ({ adform }) => ({
  integrations: adform.integrations,
});

const mapDispatchToProps = dispatch => ({
  fetchIntegrations: () => {
    dispatch(fetchIntegrations());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdformIntegrationTab);
