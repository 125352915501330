import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import TrackpointLookupTable from './TrackpointLookupTables'
import { lookupTrackpoint } from '../reducers/trackpointReducer'
import useForm from 'react-hook-form'
import { errorStyles } from '../styles/styles'
import Select, { createFilter } from 'react-select'

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    fontSize: '14px',
    fontColor: 'white',
    border: '1px solid #dee3e8',
    borderRadius: '0px',
    boxShadow: 'none',
    overflow: 'hidden',
    padding: '2px'
  }),
  option: (styles, {isFocused}) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f1f5fa' : 'white',
      color: 'rgba(38, 68, 99, 0.85)',
      fontSize: '14px',
    }
  },
}

const TrackpointSearch = ({lookupTrackpoint, isSearching, cids}) => {
  const {register, setValue, handleSubmit, errors} = useForm()

  useEffect(() => {
    register({name: 'cid'})
  }, [register])

  const handleCIDChange = value => {
    setValue('cid', value)
  }

  return <div>
    <form onSubmit={handleSubmit(lookupTrackpoint)}>
      <div className="row">
        <div className="col-xs-3">
          <label>CID / Account</label>
          <Select
            filterOption={createFilter({ignoreAccents: false})}
            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
            styles={selectStyles}
            isLoading={cids.length === 0}
            onChange={handleCIDChange}
            options={cids}
            disabled={isSearching}
          />
        </div>

        <div className="col-xs-3">
          <label>Tailcode Pattern</label>
          <div className="form-group full-width">
            <input ref={register({required: true})}
                   disabled={isSearching}
                   name='tailcode'
                   style={errors.id ? errorStyles : {}}
                   type="text"
                   className="form-control omnisearch"/>
          </div>
        </div>

        <div className="col-xs-3">
          <label>&nbsp;</label>
          <div>
            <button disabled={isSearching} type="submit" className="btn btn-icon btn-primary">
              Search
            </button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-2">
          <label>
            <span style={{zIndex: 0}} className="pretty-checkbox">
                <input type="checkbox"
                       defaultChecked={true}
                       ref={register}
                       disabled={true}
                       name={`searchFilters[dateRange]`}/>
              <i style={{backgroundColor: 'rgb(241, 245, 250)'}}/>
              </span>
            Only Search for last 7 days
          </label>
        </div>
        <div className="col-xs-2">
          <label>
            <span style={{zIndex: 0}} className="pretty-checkbox">
                <input type="checkbox"
                       defaultChecked={true}
                       ref={register}
                       name={`searchFilters[includeCampaigns]`}/>
                <i/>
              </span>
            Include campaigns
          </label>
        </div>
      </div>
    </form>

    <TrackpointLookupTable/>
  </div>
}

const mapStateToProps = ({accounts, trackpoints: {isSearching}}) => ({
  isSearching,
  cids: accounts.audienceReportAccounts.map(account => {
    return {label: `${account.cid} : ${account.name}`, value: account.cid}
  })
})

export default connect(mapStateToProps, {lookupTrackpoint})(TrackpointSearch)

