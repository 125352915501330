import React from 'react'
import { connect } from 'react-redux'
import TechedgeList from './techedgeAccountsList'
import TechedgeAccountModal from './../modals/createTechedgeAccountModal'
import { toggleTechedgeAccountModal } from '../reducers/modalReducer'

const TechedgeAccountTab = ({toggleTechedgeAccountModal}) =>
  <div>
    <button className="btn btn-success btn-icon pull-right" onClick={toggleTechedgeAccountModal}>
      <i className="fui- flaticon stroke plus-1"/>
    </button>
    <h2 className="page-title">Techedge accounts</h2>
    <TechedgeAccountModal/>
    <TechedgeList/>
  </div>

export default connect(null, {toggleTechedgeAccountModal})(TechedgeAccountTab)
