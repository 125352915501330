import {
  getIntegrations,
} from '../util/adformApi';
import { sortBy } from 'lodash';

const REQUEST_ADFORM_INTEGRATIONS = 'REQUEST_ADFORM_INTEGRATIONS';
const RECEIVE_ADFORM_INTEGRATIONS = 'RECEIVE_ADFORM_INTEGRATIONS';

const initialState = {
  integrations: null,
}

const adformReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ADFORM_INTEGRATIONS:
      return {
        ...state,
        integrations: null,
      };

    case RECEIVE_ADFORM_INTEGRATIONS:
      return {
        ...state,
        integrations: sortBy(
          action.adformIntegrations.filter(item => item.status !== 'Pending'),
          ['audienceReportCID']
        )};

    default:
      return state;
  }
}

export const replaceIntegrations = () => dispatch => (
  getIntegrations()
    .then(adformIntegrations => dispatch({
      type: RECEIVE_ADFORM_INTEGRATIONS,
      adformIntegrations,
    }))
);

export const fetchIntegrations = () => dispatch => {
  dispatch({
    type: REQUEST_ADFORM_INTEGRATIONS,
  });

  return dispatch(replaceIntegrations());
};

export default adformReducer;
