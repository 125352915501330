import React from 'react'
import Modal from 'react-modal'
import { modalStyles, errorStyles } from './../styles/styles'
import { connect } from 'react-redux'
import { toggleCreateConsentModal } from '../reducers/modalReducer'
import { createConsent } from '../reducers/iabConsentReducer'
import useForm from 'react-hook-form'
import ModalHeader from '../components/ModalHeader'
import ModalFooter from '../components/ModalFooter'

const CreateConsentModal = ({modalIsOpen, toggleCreateConsentModal, createConsent, consentTypes, isLoading}) => {
  const {register, handleSubmit, errors} = useForm()

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      onRequestClose={toggleCreateConsentModal}
      style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px'}}}>
      <form onSubmit={handleSubmit(createConsent)}>
        <ModalHeader title={'Create IAB Consent'} onCancel={toggleCreateConsentModal}/>
        <div style={{'padding': '20px'}}>
          <div className="row">
            <div className="col-xs-4">
              <div className="form-group">
                <label>IAB Type</label>
                <select className="form-control input-sm"
                        name="type"
                        ref={register({required: true})}
                        style={errors.consentType ? errorStyles : {}}>
                  <option value="">Select...</option>
                  <option value="cid">Company</option>
                  <option value="tld">Domain</option>
                </select>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="form-group">
                <label>Value</label>
                <input name='value'
                       type="text"
                       className="form-control input-sm"
                       style={errors.consentValue ? errorStyles : {}}
                       ref={register({required: true})}/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-4">
              <div className="form-group">
                <label>Client</label>
                <input type="text"
                       className="form-control input-sm"
                       name='client'
                       style={errors.client ? errorStyles : {}}
                       ref={register({required: true})}/>
              </div>
            </div>
          </div>

          <div>
            <h4>Consent fields</h4>
          </div>

          {consentTypes.map(consent =>
            <div key={consent.key} className="row">
              <div className="col-md-12">
                <label>
                  <span className="pretty-checkbox">
                    <input type="checkbox"
                           defaultChecked={true}
                           ref={register}
                           name={`consents[${consent.key}]`}/>
                    <i/>
                  </span>
                  {consent.label}
                </label>
              </div>
            </div>
          )}
        </div>
        <ModalFooter disabled={isLoading} onCancel={toggleCreateConsentModal} submitLabel={'Create'}/>
      </form>
    </Modal>
  )
}

const mapStateToProps = ({modals, iabConsents}) => ({
  modalIsOpen: modals.createConsentModal,
  consentTypes: iabConsents.consentTypes,
  isLoading: iabConsents.isLoading
})

export default connect(mapStateToProps, {toggleCreateConsentModal, createConsent})(CreateConsentModal)


