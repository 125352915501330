import React from 'react'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Row, Col } from 'react-bootstrap'
import {
  setAccountFilter,
  setSnapshotStateFilter,
  setCampaignTypeFilter,
  setSnapshotIdFilter
} from './../reducers/techedgeReducer'

const TechedgeCampaignsFilters = ({
                                    accounts,
                                    campaignTypes,
                                    snapshotStates,
                                    setSnapshotStateFilter,
                                    setCampaignTypeFilter,
                                    setSnapshotIdFilter,
                                    setAccountFilter
                                  }) => {
  const options = accounts.map(({id, name, cid}) => {
    return {id, label: `${name} : ${cid}`}
  })

  return <Row>
    <Col md='2'>
      <input
        onChange={(event) => setSnapshotIdFilter(event.target.value)}
        type="text"
        placeholder="Name, Campaign ID  or techedge ID"
        className="form-control input-sm"/>
    </Col>
    <div className='col-xs-2'>
      <Typeahead
        id='techedge-account-filter'
        bsSize={'small'}
        align='left'
        placeholder={'All accounts'}
        onChange={(selected) => {
          if (selected.length !== 0) {
            setAccountFilter(selected[0].id)
          } else {
            setAccountFilter('all')
          }
        }}
        options={options}
      />
    </div>

    <div className='col-xs-2'>
      <select
        onChange={(event) => setSnapshotStateFilter(event.target.value)}
        className="form-control input-sm">
        <option value='all'>All statuses</option>
        {snapshotStates.map(state => <option key={state} value={state}>{state}</option>)}
      </select>
    </div>
    <div className='col-xs-2'>
      <select
        onChange={(event) => setCampaignTypeFilter(event.target.value)}
        className="form-control input-sm">
        <option value='all'>All campaign types</option>
        {campaignTypes.map(state => <option key={state} value={state}>{state}</option>)}
      </select>
    </div>
  </Row>
}

const mapStateToProps = ({accounts, techedge: {snapshotStates, campaignTypes}}) => {
  return {
    snapshotStates,
    campaignTypes,
    accounts: accounts.audienceReportAccounts,
  }
}

export default connect(mapStateToProps, {
  setSnapshotStateFilter,
  setCampaignTypeFilter,
  setAccountFilter,
  setSnapshotIdFilter
})(TechedgeCampaignsFilters)

