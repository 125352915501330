import React from 'react';
import PropTypes from 'prop-types';

const LabelStatus = ({ status }) => {
  const getStatusClass = () => {
    switch (status.toLowerCase()) {
      case 'active': return 'label-success';
      case 'failed': return 'label-danger';
      case 'pending': return 'label-warning';
      case 'disabled': return 'label-default';
      default: return 'label-info';
    }
  };

  return (
    <span className={`label ${getStatusClass()}`}>
      {status}
    </span>
  );
};

LabelStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default LabelStatus;
