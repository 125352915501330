import React from 'react'
import moment from 'moment'
import { Col, Row, ListGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchSnapshotJson } from '../reducers/techedgeReducer'
import { getSnapshotStyles, getSnapshotIcon } from '../styles/styles'

const styles = {
  listItem: {'cursor': 'pointer'},
  headline: {'marginBottom': '4px'},
  divider: {'borderLeft': '1px solid #dee3e8', 'minHeight': '24em'},
  snapshots: {'marginTop': '32px', 'lineHeight': '1.0'},
  listGroup: {'marginTop': '32px'},
}

const TechedgeSnapshotDetails = ({campaign, hasData, jsonData, fetchSnapshotJson}) => {
  const renderSnapshotList = () => {
    return <ListGroup style={styles.listGroup}>
      {campaign.snapshots.map(snapshot =>
        <ListGroup.Item action
                        key={snapshot.id}
                        style={styles.listItem}
                        onClick={() => fetchSnapshotJson(snapshot, campaign.panelCountry)}>
          {snapshot.id}
          <i style={getSnapshotStyles(snapshot.status)} className={getSnapshotIcon(snapshot.status)}/>
        </ListGroup.Item>
      )}
    </ListGroup>
  }

  return (
    <Row>
      <Col lg={4}>
        <Row>
          <Col lg={12}>
            <h2 style={styles.headline}>Snapshots</h2>
            <small>Click snapshot to retrieve raw json data</small>
          </Col>
          <Col lg={12}>
            {renderSnapshotList()}
          </Col>
        </Row>
      </Col>

      <Col lg={8} style={styles.divider}>
        <Col lg={12}>
          <h2 style={styles.headline}>JSON Data</h2>
          {!hasData && <small>No data fetched yet</small>}
        </Col>
        <Col lg={12}>
          {hasData && jsonData.map(({date, json}) =>
            <Row key={date} style={styles.snapshots}>
              <Col lg={9}>
                {moment(date).format('Y-MM-DD')}
                {json.length === 0 && <div><small>No export found</small></div>}
                {json.length !== 0 &&
                <div>
                  <pre>{JSON.stringify(json, null, 2)}</pre>
                </div>}
              </Col>
            </Row>
          )}
        </Col>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({techedge: {snapshotJsonData}}, {campaign}) => ({
  campaign,
  jsonData: snapshotJsonData,
  hasData: snapshotJsonData.length !== 0
})

export default connect(mapStateToProps, {fetchSnapshotJson})(TechedgeSnapshotDetails)

