import React, { useEffect } from 'react'
import SearchBar from '../components/SearchBar'
import { connect } from 'react-redux'
import { fetchConsents } from '../reducers/iabConsentReducer'
import IABConsentsTable from '../components/IABConsentsTable'
import { toggleCreateConsentModal } from '../reducers/modalReducer'

const IABConsentView = ({fetchConsents, toggleCreateConsentModal, fetchingConsents, isLoading}) => {
  const setTerm = (term) => console.log(term)

  useEffect(() => {
    fetchConsents()
  }, [fetchConsents])

  return <div>
    <div className='row'>
      <h2 className='pull-left page-title'>
        IAB Consent
      </h2>
      <button onClick={toggleCreateConsentModal}
              className="btn btn-success btn-icon pull-right pull-up margin-left">
        <i className="fui- flaticon stroke plus-1"/>
      </button>
      <SearchBar onChange={(event) => setTerm(event.target.value)}/>
    </div>

    <div className='row'>
      {!fetchingConsents && <IABConsentsTable/>}
      {(fetchingConsents || isLoading) && <div className="spinner">Loading...</div>}
    </div>
  </div>
}

const mapStateToProps = ({iabConsents}) => ({
  fetchingConsents: iabConsents.isFetching,
  isLoading: iabConsents.isLoading
})

export default connect(mapStateToProps, {fetchConsents, toggleCreateConsentModal})(IABConsentView)
