import React from 'react'

const ModalFooter = ({onCancel, submitLabel, disabled = false}) =>
  <div className="modal-footer">
    <button onClick={onCancel} type="button" className="btn btn-default btn-wide">
      Cancel
    </button>
    <button disabled={disabled} type="submit" className="btn btn-primary btn-wide">
      {submitLabel}
    </button>
  </div>

export default ModalFooter

