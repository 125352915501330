import React from 'react'

const leftSideStyles = {
  borderRightWidth: "0px",
  borderColor: "#bbc4cd"
}

const rightSideStyles = {
  backgroundColor: "white",
  border: "1px solid #bbc4cd",
  borderLeftWidth: "0px",
  color: "#bbc4cd"
}

const SearchBar = (props) => {
  return (
    <div className="quick-search form-group form-group-max-width pull-right pull-up">
      <div className="input-group">
        <input
          style={leftSideStyles}
          {...props}
          type="search"
          className="form-control"
          placeholder="Quick search..."/>
        <span className="input-group-btn">
          <button style={rightSideStyles} className="btn"><span className="fui-search"/></button>
        </span>
      </div>
    </div>
  )
}

export default SearchBar

