import { campaignApi } from '../axios'

export const getTechedgeCampaigns = () => {
  return campaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/integrations/techedge/campaigns`)
    .then(response => response.data.data)
}

export const getTechedgeAccounts = () => {
  return campaignApi
    .get(`${process.env.REACT_APP_TECHEDGE_API_URL}/accounts`)
    .then(response => response.data.data)
}

export const getCampaignExports = (pid, params) => {
  return campaignApi
    .get(`${process.env.REACT_APP_TECHEDGE_API_URL}/campaigns/${pid}/exports`, {
      params
    })
    .then(response => response.data.data)
}

export const createTechedgeAccount = payload => {
  return campaignApi
    .post(`${process.env.REACT_APP_TECHEDGE_API_URL}/accounts`, payload)
    .then(response => response.data.data)
}

export const createTechedgeMapping = (account, id) => {
  return campaignApi
    .post(`${process.env.REACT_APP_TECHEDGE_API_URL}/accounts/${account.id}`, {
      'audienceReportAccount': id
    })
}

export const deleteTechedgeMapping = (account, id) => {
  return campaignApi
    .delete(`${process.env.REACT_APP_TECHEDGE_API_URL}/accounts/${account.id}/${id}`)
    .then(response => response.data.data)
}

export const deleteTechedgeAccount = account => {
  return campaignApi
    .delete(`${process.env.REACT_APP_TECHEDGE_API_URL}/accounts/${account.id}`)
    .then(response => response.data.data)
}