import React from 'react'
import { Route } from 'react-router-dom'
import LoginView from '../views/LoginView'
import DashboardView from '../views/DashboardView'
import IntegrationsView from '../views/integrationsView'
import TrackpointView from '../views/TrackpointView'
import ExportsView from '../views/ExportsView'
import AccountsView from '../views/AccountsView'
import AccountView from '../views/AccountView'
import DataAssessmentView from '../views/DataAssessmentView'
import IABConsentView from '../views/IABConsentsView'

const Routes = () => <React.Fragment>
  <Route path='/login' component={LoginView}/>
  <Route exact path='/' component={DashboardView}/>
  <Route exact path='/dashboard' component={DashboardView}/>
  <Route exact path='/data-assessment' component={DataAssessmentView}/>
  <Route path='/integrations' component={IntegrationsView}/>
  <Route path='/trackpoints' component={TrackpointView}/>
  <Route exact path='/exports' component={ExportsView}/>
  <Route exact path='/accounts' component={AccountsView}/>
  <Route path='/accounts/:accountId' component={AccountView}/>
  <Route exact path='/iab-blacklist' component={IABConsentView}/>
</React.Fragment>

export default Routes
