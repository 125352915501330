import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Radium from 'radium'

const styles = {
  container: {
    'borderBottom': '1px solid #dee3e8',
    'paddingBottom': '16px'
  },
  header: {
    'marginBottom': '12px'
  },
  label: {
    'lineHeight': '1.0',
    'fontWeight': 'bold',
  },
  arrow: {
    'marginTop': '24px',
  },
}

const TechedgeCampaignDetails = ({campaign, redirectAction}) =>
  <React.Fragment>
    <Row style={styles.container}>
      <Col lg={12}>
        <h2 style={styles.header}>Details</h2>
      </Col>

      <Col lg={4}>
        <label style={styles.label}>Name</label>
        <div>
          <small>
            {campaign.name}
          </small>
        </div>
      </Col>
      <Col lg={2}>
        <label style={styles.label}>Campaign ID</label>
        <div>
          <small>
            {campaign.pid}
          </small>
        </div>
      </Col>
      <Col lg={2}>
        <label style={styles.label}>Created</label>
        <div>
          <small>
            {campaign.created}
          </small>
        </div>
      </Col>
      <Col lg={2}>
        <label style={styles.label}>Type</label>
        <div>
          <small>
            {campaign.type}
          </small>
        </div>
      </Col>

      <Col lg={12}>
        <i onClick={redirectAction} className={`fui-  flaticon stroke arrow-left`}/>
      </Col>
    </Row>
  </React.Fragment>

export default Radium(TechedgeCampaignDetails)

