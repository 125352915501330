import React, {
  useState,
  useEffect,
} from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { modalStyles } from './../styles/styles';
import { toggleBrandingModal } from '../reducers/modalReducer';
import { deleteAccount } from '../reducers/accountsReducer';

const COLOR_PRIMARY = '#16d6d8';
const COLOR_SECONDARY = '#9ee00f';

const AccountBrandingModal = ({
  logo,
  avatar,
  account,
  primaryColor,
  secondaryColor,
  modalIsOpen,
  toggleBrandingModal,
}) => {
  const [invalidFields, setInvalidFields] = useState([]);
  const [branding, setBranding] = useState({});

  useEffect(() => {
    setBranding({
      accountLogo: logo,
      accountAvatar: avatar,
      primaryColor: primaryColor || COLOR_PRIMARY,
      secondaryColor: secondaryColor || COLOR_SECONDARY,
    });
  }, [logo, avatar]);

  const isValidHexColor = hex => /^#([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/.test(hex);
  const hasError = field => invalidFields.includes(field);

  const handleColorChange = event => {
    const { name, value } = event.target;
    setBranding({
      ...branding,
      [name]: value,
    });

    if (!isValidHexColor(value)) {
      if (!invalidFields.includes(name)) {
        setInvalidFields([...invalidFields, name]);
      }
    } else {
      setInvalidFields([...invalidFields.filter(field => field !== name)]);
    }
  };

  const setData = (key, file) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setBranding({
        ...branding,
        [key]: reader.result,
        [`${key}Binary`]: file,
      });
    }, false);

    reader.readAsDataURL(file);
  };

  const handleLogoChange = event => {
    const [file] = event.target.files;
    setData('accountLogo', file);
  };

  const handleAvatarChange = event => {
    const [file] = event.target.files;
    setData('accountAvatar', file);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = new FormData();
    form.append('primaryColor', branding.primaryColor);
    form.append('secondaryColor', branding.secondaryColor);
    if (branding.accountLogoBinary) {
      form.append('accountLogo', branding.accountLogoBinary);
    }
    if (branding.accountAvatarBinary) {
      form.append('accountAvatar', branding.accountAvatarBinary);
    }

    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('ssoToken')}`,
      },
      redirect: 'follow',
      body: form,
    }

    fetch(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/${account.id}/branding/update`, options)
      .then(() => toggleBrandingModal())
        .then(result => console.log(result))
        .catch(error => console.error(error));
  };

  const renderHeader = () => (
    <div className="modal-header">
      <h3 className="modal-title">
        {account.name}
        {' '}
        <small>
          Customize branding
        </small>
      </h3>
    </div>
  );

  const renderFooter = () => (
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-default btn-wide"
        onClick={toggleBrandingModal}
      >
        Cancel
      </button>

      <button
        type="submit"
        className="btn btn-primary btn-wide"
        disabled={invalidFields.length !== 0}
      >
        Save
      </button>
    </div>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <div className='modal-body'>
        <div className="row">
          <div className="col-xs-4">
            <div className={`form-group color-wrap ${hasError('primaryColor') ? 'has-error' : ''}`}>
              <label>
                Colors (hex codes)
              </label>
              <span className="preview-color" style={{ background: branding.primaryColor }}/>
              <input type="text"
                name="primaryColor"
                className="form-control input-color"
                placeholder="Primary Color"
                onChange={handleColorChange}
              />
            </div>
            <div className={`form-group color-wrap ${hasError('secondaryColor') ? 'has-error' : ''}`}>
              <span className="preview-color" style={{ background: branding.secondaryColor }}/>
              <input type="text"
                name="secondaryColor"
                className="form-control input-color"
                placeholder="Secondary Color"
                onChange={handleColorChange}
              />
            </div>
          </div>

          <div className="col-xs-4">
            <div className="form-group">
              <label>Company logo (200-500px wide)</label>
              <div className="fileinput">
                <div className="fileinput-preview thumbnail">
                  {branding.accountLogo && (
                    <img src={branding.accountLogo} alt=''/>)}
                </div>
                <div className={`fileinput-buttons ${!branding.accountLogo && 'fileinput-upload'}`}>
                  <span className="btn btn-link btn-file btn-icon">
                    {!branding.accountLogo && (
                      <span className="fileinput-new">
                        <i className="fui- flaticon stroke inbox-upload-1"/>
                      </span>
                    )}
                    {branding.accountLogo && (
                      <span className="fileinput-exists">
                        <i className="fui- flaticon stroke settings-1"/>
                      </span>
                    )}
                    <input name="logo" type="file" accept="image/png,image/jpg,image/jpeg"
                      onChange={handleLogoChange} />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-4">
            <div className="form-group">
              <label>
                Company avatar
              </label>
              <div className="fileinput fileinput-new">
                <div className="fileinput-preview thumbnail">
                  {branding.accountAvatar && (
                    <img src={branding.accountAvatar} alt=''/>)}
                </div>
                <div className={`fileinput-buttons ${!branding.accountAvatar && 'fileinput-upload'}`}>
                  <span className="btn btn-link btn-file btn-icon">
                    {!branding.accountAvatar && (
                      <span className="fileinput-new">
                        <i className="fui- flaticon stroke inbox-upload-1"/>
                      </span>
                    )}
                    {branding.accountAvatar && (
                      <span className="fileinput-exists">
                        <i className="fui- flaticon stroke settings-1"/>
                      </span>
                    )}
                    <input name="avatar" type="file" accept="image/png,image/jpg,image/jpeg"
                      onChange={handleAvatarChange}/>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderFooter()}
    </form>
  );

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      style={{
        ...modalStyles,
        content: {
          ...modalStyles.content,
          padding: 0,
          width: 900,
        }
      }}
      onRequestClose={toggleBrandingModal}
    >
      <div>
        {renderHeader()}
        {renderForm()}
      </div>
    </Modal>
  );
}

const mapStateToProps = ({account, modals}) => {
  const { branding } = account.account;

  return {
    account: account.account,
    modalIsOpen: modals.brandingModal,
    primaryColor: branding.primaryColor,
    secondaryColor: branding.secondaryColor,
    avatar: branding.accountAvatar,
    logo: branding.accountLogo,
  }
};

export default connect(mapStateToProps, {toggleBrandingModal, deleteAccount})(AccountBrandingModal);

