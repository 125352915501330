import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateConsent, deleteConsent } from '../reducers/iabConsentReducer'
import { keys, find } from 'lodash'
import { consentLabels } from '../util/iab'

const IABConsentsTable = ({consents, updateConsent, deleteConsent, isLoading}) => {
  const [checkedItems, setCheckedItems] = useState([])

  useEffect(() => {
    if (consents.length !== checkedItems.length) {
      setCheckedItems(consents.map(consent => {
        return {type: consent.type, value: consent.value, consents: consent.consents}
      }))
    }
  }, [consents, checkedItems.length])

  const handleChange = (event, updatedConsent) => {
    let newConsents = checkedItems.map(consent => {
      if (consent.value === updatedConsent.value) {
        return {
          ...consent,
          consents: {
            ...consent.consents, [event.target.name]: event.target.checked
          }
        }
      }

      return consent
    })

    setCheckedItems(newConsents)
  }

  const renderCheckboxes = (consent) => {
    return <React.Fragment>
      {keys(consent.consents).map((consentKey) =>
        <td key={consent.value + consentKey}>
          <input className="form-check-input"
                 onClick={(event) => handleChange(event, consent)}
                 name={consentKey}
                 type="checkbox"
                 defaultChecked={consent.consents[consentKey]}
                 style={{'marginRight': '5px'}}/>
          <label className="form-check-label">
            {consentLabels[consentKey]}
          </label>
        </td>
      )}
    </React.Fragment>
  }

  return <table className="table table-vcenter">
    <thead>
    <tr>
      <th>Type</th>
      <th>CID / Domain</th>
      <th>Client</th>
      <th>Consent fields</th>
      <th/>
      <th/>
      <th/>
      <th/>
      <th/>
      <th/>
    </tr>
    </thead>
    <tbody>
    {consents.map(consent =>
      <tr key={consent.value}>
        <td className="col-md-1"><h5>{consent.type}</h5></td>
        <td className="col-md-1"><h5>{consent.value}</h5></td>
        <td className="col-md-1"><h5>{consent.client}</h5></td>
        {renderCheckboxes(consent)}
        <td className="col-md-1">
          <div className="btn-group btn-group-md pull-right disabled">
            <button disabled={isLoading}
                    onClick={() => updateConsent(find(checkedItems, ['value', consent.value]))}
                    className="btn btn-primary">Save
            </button>
          </div>
        </td>
        <td className="col-md-1">
          <div className="btn-group btn-group-md pull-right">
            <button disabled={isLoading}
                    onClick={() => deleteConsent(find(checkedItems, ['value', consent.value]))}
                    className="btn btn-danger">Delete
            </button>
          </div>
        </td>
      </tr>)}
    </tbody>
  </table>
}

const mapStateToProps = ({iabConsents}) => ({
  consents: iabConsents.consents,
  isLoading: iabConsents.isLoading
})

export default connect(mapStateToProps, {updateConsent, deleteConsent})(IABConsentsTable)

