import { campaignApi, publicCampaignApi } from './../axios'

export const fetchCampaignDetails = (pid) => {
  return campaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/campaigns/${pid}`)
    .then((response) => response.data.data)
}

export const getAudienceReportUsers = () => {
  return campaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts`)
    .then((response) => response.data.data)
}

export const getAccountCampaigns = (id, params) => {
  return publicCampaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/v1/campaigns`, {
      params,
      headers: {'Customer-id': id},
    })
    .then((response) => response.data)
}

export const getAccountsByIds = (ids) => {
  return campaignApi
    .post(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts`, {
      ids,
    })
    .then((response) => response.data.data)
}

export const getAccountByProduct = (id) => {
  return campaignApi
    .get(
      `${process.env.REACT_APP_API_AUDIENCEPROJECT_URL}/org/v1/accounts/AudienceReport/${id}`
    )
    .then((response) => response.data.data)
}

export const getAccountApiKeys = (accountId) => {
  return campaignApi
    .get(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/${accountId}/oauthClient`
    )
    .then((response) => response.data.data)
    .catch((error) => {
      // 400 means no client associated with account
      return error.response.status === 400 ? false : null
    })
}

export const createOauthClient = (accountId, name) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/${accountId}/oauthClient`,
      {
        name,
      }
    )
    .then((response) => response.data.data)
}

export const getOrganization = (id) => {
  return campaignApi
    .get(
      `${process.env.REACT_APP_API_AUDIENCEPROJECT_URL}/org/v1/organizations/${id}`
    )
    .then((response) => response.data.data)
}

export const getOrganizationUsers = (id) => {
  return campaignApi
    .get(
      `${process.env.REACT_APP_API_AUDIENCEPROJECT_URL}/org/v1/organizations/${id}/users`
    )
    .then((response) => response.data.data)
}

export const deleteAudienceReportAccount = (account) => {
  return campaignApi
    .delete(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/${account.id}`
    )
    .then((response) => response.data)
}

export const deleteAccountCampaigns = (campaigns, accountId) => {
  let promises = campaigns.map((campaign) => {
    return publicCampaignApi.delete(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/campaigns/${campaign.id}`,
      {
        headers: {'Customer-id': accountId},
      }
    )
  })

  return Promise.all(promises).then((response) => response)
}

export const getFeatures = () => {
  return campaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/features`)
    .then((response) => response.data)
}

export const cloneAccountCampaigns = (payload) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/campaigns/copy`,
      payload
    )
    .then(response => response.data)
    .catch(error => error.response.data);
}

export const moveCampaignsToAccount = (payload, targetAccount) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/campaigns/move/${targetAccount}`,
      payload
    )
    .then((response) => response.data)
}

export const getTrackpointDetails = (payload) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/trackpoints/history/search`,
      payload,
    )
    .then((response) => response.data)
}

export const getTrackpointDateDetails = ({cid, date, tailcode}) => {
  const tail = tailcode ? tailcode : '';
  return campaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/trackpoints/date/${cid}/${date}/${tail}`)
    .then((response) => response.data)
}

export const searchAccountCampaigns = (term, customerId) => {
  return publicCampaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/search/${term}/campaigns`, {
      headers: {'Customer-id': customerId},
    })
    .then((response) => response.data)
}

// Integrated reporting
export const getFusionRequests = () => {
  return campaignApi
    .get(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/integrated`
    )
    .then((response) => response.data)
}

// Danish Marked Standard (DMS)
export const getDMSOperations = () => {
  return campaignApi
    .get(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/integrations/dms/operations`
    )
    .then((response) => response.data)
}

export const getAccountToken = (id) => {
  return campaignApi
    .get(`/company/${id}/webappToken`)
    .then((response) => response.data)
}

export const getOrganizations = () => {
  return campaignApi.get(`/organizations`).then((response) => response.data)
}

export const createNewAudienceReportAccount = (payload) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/account`,
      payload
    )
    .then((response) => response.data)
}

export const editAudienceReportAccount = (payload) => {
  return campaignApi
    .put(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/${payload.id}`,
      payload
    )
    .then((response) => response.data)
}

export const getKpiImpressions = (start, end) => {
  return campaignApi
    .post(`/kpi/impressions`, {
      start,
      end,
    })
    .then((response) => response.data)
}

export const getKpiCampaigns = (start, end) => {
  return campaignApi
    .post(`/kpi/campaigns`, {
      start,
      end,
    })
    .then((response) => response.data)
}

export const getDataAssessmentReports = () => {
  return campaignApi
    .get(`data-assessment/reports`)
    .then((response) => response.data)
}

export const getDataAssessmentAudiences = () => {
  return campaignApi
    .get(`data-assessment/audiences`)
    .then((response) => response.data)
}

export const requestDataAssessmentReport = ({pid, audience}) => {
  return campaignApi
    .post(`data-assessment/reports`, {
      pid,
      audience: audience.label,
    })
    .then((response) => response.data)
}

export const fetchIABConsents = () => {
  return campaignApi
    .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/iab/consents`)
    .then((response) => response.data.data)
}

export const updateIABConsent = ({type, value, consents}) => {
  return campaignApi
    .put(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/iab/consents`,
      {
        type,
        value,
        consents,
      }
    )
    .then((response) => response.data)
}

export const createIABCosent = ({type, value, client, consents}) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/iab/consents`,
      {
        type,
        client,
        value,
        consents,
      }
    )
    .then((response) => response.data)
}

export const deleteIABCosent = ({value}) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/iab/consents`,
      {
        value,
      }
    )
    .then((response) => response.data)
}

export const requestRawLogs = (payload, campaignId) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/exports/${campaignId}/logs`,
      payload
    )
    .then((response) => response.data)
}

export const fetchRawLogExports = () => {
  return campaignApi.get(`/exports/logs`).then((response) => response.data)
}

export const updateAccountBranding = (data, accountId) => {
  return campaignApi
    .post(
      `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/${accountId}/branding/update`,
      data
    )
    .then((response) => response.data)
}
