import React from 'react';
import PropTypes from 'prop-types';
import LabelDate from './common/LabelDate';
import LabelStatus from './common/LabelStatus';

const CampaignManagerIntegrationOverview = ({
  integration,
}) => {
  const {
    status,
    country,
    audienceReportCID,
    audienceReportAccountId,
    connectedUserEmail,
    tagApplication,
    trackingLevel,
    activatedAt,
    lastErrorDate,
    lastErrorMessage,
  } = integration;

  const rows = [{
    label: 'AudienceReport account',
    content: (
      <React.Fragment>
        {audienceReportCID}
        <small>
          [{audienceReportAccountId}]
        </small>
      </React.Fragment>
    ),
  }, {
    label: 'Connected email',
    content: connectedUserEmail,
  }, {
    label: 'Country',
    content: country,
  }, {
    label: 'Status',
    content: (
      <LabelStatus status={status} />
    ),
  }, {
    label: 'Application tag',
    content: (
      <span className={'label label-default'}>
        {tagApplication}
      </span>
    ),
  }, {
    label: 'Tracking level',
    content: (
      <span className={'label label-default'}>
        {trackingLevel}
      </span>
    ),
  }, {
    label: 'Activated',
    content: (
      <LabelDate date={activatedAt} />
    ),
  }];

  if (lastErrorMessage) {
    rows.push({
      label: 'Error message',
      content: (
        <React.Fragment>
          <span className="label label-danger">
            {lastErrorMessage}
          </span>
          <span className="label label-danger">
            {<LabelDate date={lastErrorDate} />}
          </span>
        </React.Fragment>
      ),
    });
  }

  return (
    <table
      className='table table-vcenter table-flat'
      style={{
        maxWidth: 600
      }}
    >
      <tbody>
        {rows.map((item, index) => (
          <tr key={index}>
            <td>
              {`${item.label}:`}
            </td>
            <td>
              {item.content}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CampaignManagerIntegrationOverview.propTypes = {
  integration: PropTypes.object.isRequired,
};

export default CampaignManagerIntegrationOverview;
