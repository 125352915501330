import React from 'react'
import { find } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import TechedgeMapAccountModal from '../modals/mapTechedgeAccountModal'
import { openMappingModal, deleteAccount } from '../reducers/techedgeReducer'

const TechedgeAccountsList = ({audienceReportAccounts, techedgeAccounts, openMappingModal, deleteAccount}) =>
  <div>
    <TechedgeMapAccountModal/>
    <table className="table table-vcenter">
      <thead>
      <tr>
        <th>Name</th>
        <th>Techedge ID</th>
        <th>Country code</th>
        <th>Creation date</th>
        <th>AudienceReport accounts</th>
        <th/>
      </tr>
      </thead>
      <tbody>
      {techedgeAccounts.map(techedgeAccount =>
        <tr key={techedgeAccount.id}>
          <td>{techedgeAccount.name}</td>
          <td>{techedgeAccount.id}</td>
          <td>{techedgeAccount.countryCode}</td>
          <td>{moment(techedgeAccount.createdOn).format('Y-MM-DD')}</td>
          <td>
            <ul>{techedgeAccount.audienceReportAccounts.map((account, index) => {
              if (audienceReportAccounts.length === 0) {
                return <li key={account.id}>No accounts</li>
              }

              var arAccount = find(audienceReportAccounts, {'id': account.id});
              var name = arAccount ? arAccount.name : 'N/A';
              var key = arAccount ? arAccount.id : index;
              return <li key={key}>{name}</li>
            })}
            </ul>
          </td>
          <td>
            <div className='btn-group btn-group-sm pull-right'>
              <button className="btn btn-primary" onClick={() => openMappingModal(techedgeAccount)}>Map</button>
            </div>
            <div className='btn-group pr-1 btn-group-sm pull-right '>
              <button className="btn btn-danger" onClick={() => deleteAccount(techedgeAccount)}>Delete</button>
            </div>
          </td>
        </tr>
      )}
      </tbody>
    </table>
  </div>

const mapStateToProps = ({accounts, modals}) => {
  return {
    techedgeAccounts: accounts.techedgeAccounts,
    audienceReportAccounts: accounts.audienceReportAccounts,
    isModalOpen: modals.techedgeMappingModal
  }
}

export default connect(mapStateToProps, {
  openMappingModal,
  deleteAccount
})(TechedgeAccountsList)
