import React from 'react'
import Modal from 'react-modal'
import { modalStyles, modalFooterStyles, errorStyles } from './../styles/styles'
import { connect } from 'react-redux'
import { createAccount } from '../reducers/techedgeReducer'
import { toggleTechedgeAccountModal } from '../reducers/modalReducer'
import useForm from 'react-hook-form'

const CreateTechedgeAccountModal = ({modalIsOpen, toggleTechedgeAccountModal, createAccount}) => {
  const {register, handleSubmit, errors} = useForm()

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      onRequestClose={toggleTechedgeAccountModal}
      style={modalStyles}>
      <p>Add techedge account</p>
      <form onSubmit={handleSubmit(createAccount)}>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <label>Name</label>
              <input name="name"
                     ref={register({required: true})}
                     className="form-control input-sm"
                     style={errors.name ? errorStyles : {}}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div className="form-group">
              <label>Account id</label>
              <input name="id"
                     ref={register({required: true})}
                     className="form-control input-sm"
                     style={errors.id ? errorStyles : {}}/>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="form-group">
              <label>Country code</label>
              <input name="country"
                     ref={register({required: true})}
                     className="form-control input-sm"
                     style={errors.country ? errorStyles : {}}/>
            </div>
          </div>
        </div>
        <div style={modalFooterStyles}>
          <button type="submit" className="btn btn-primary btn-wide">Save</button>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = ({modals}) => {
  return {
    modalIsOpen: modals.techedgeAccountModal,
  }
}

export default connect(mapStateToProps, {toggleTechedgeAccountModal, createAccount})(CreateTechedgeAccountModal)
