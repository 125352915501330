export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "2px",
    boxShadow: "-15px 15px 1px rgba(0, 0, 0, 0.1)",
    backgroundClip: "border-box",
    width: "33%",
    overflow: "hidden",
    backgroundColor: "#f1f5fa",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(27, 48, 69, 0.95)",
    zIndex: "1040",
  },
};

export const campaignDetailsModalStyles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    padding: "0px",
    width: "65%",
    height: "75%",
    overflow: "hidden",
  },
};

export const modalFooterStyles = {
  float: "right",
  marginTop: "60px",
};

export const modalHeaderStyles = {
  padding: "22px 40px",
  borderBottom: "1px solid #e3e7eb",
};

export const reactSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: "14px",
    fontColor: "white",
    border: "none",
    boxShadow: "none",
    overflow: "hidden",
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f1f5fa" : "white",
      color: "rgba(38, 68, 99, 0.85)",
      fontSize: "14px",
    };
  },
};

export const dataAssessmentSelectStyles = {
  control: (styles, data) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: "14px",
    fontColor: "white",
    boxShadow: "none",
    borderColor: data.isSelected || data.isFocused ? "#16d6d8" : "#dee3e8",
    borderRadius: "2px",
    overflow: "hidden",
    outlineColor: "red",
    padding: "2px 0px",
    "&:hover": {
      borderColor: "#16d6d8",
    },
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f1f5fa" : "white",
      color: "rgba(38, 68, 99, 0.85)",
      fontSize: "14px",
    };
  },
};

export const errorStyles = {
  border: "1px solid red",
};

export const getLabelClass = (status) => {
  switch (status) {
    case "RUNNING":
      return "label-warning";
    case "FINISHED":
      return "label-success";
    case "SUCCEEDED":
      return "label-success";
    case "FAILED":
      return "label-danger";
    case "TIMED_OUT":
      return "label-danger";
    case "CANCELLED":
      return "label-default";
    case "PENDING":
      return "label-default";
    case "ABORTED":
      return "label-default";
    default:
      return "label-default";
  }
};

export const getSnapshotIcon = (status) => {
  return "icon flaticon stroke ".concat(
    status === "synced" ? "checkmark-2" : "clock"
  );
};

export const getSnapshotStyles = (status) => {
  return {
    paddingLeft: "5px",
    color: status === "synced" ? "green" : "#ffc107",
  };
};

export const getCampaignSourceIcon = (status) => {
  let baseClasses = "icon flaticon stroke ";

  switch (status) {
    case "success":
      return baseClasses + "checkmark-2";
    case "pending":
      return baseClasses + "clock";
    case "failed":
      return baseClasses + "x-2";
    default:
      return baseClasses;
  }
};

export const getCampaignSourceStyle = (status) => {
  let statusColor;

  switch (status) {
    case "success":
      statusColor = "green";
      break;
    case "pending":
      statusColor = "ffc107";
      break;
    case "failed":
      statusColor = "red";
      break;
    default:
      statusColor = "black";
  }

  return {
    paddingLeft: "5px",
    color: statusColor,
  };
};
