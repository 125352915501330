import React from 'react'
import { connect } from 'react-redux'
import FusionConnectionsFilters from './fusionConnectionsFilters'
import FusionConnectionsTable from './fusionConnectionsTable'
import { Row, Col } from 'react-bootstrap'

const FusionIntegration = () => {
  return (
    <div>
      <h2>Fusion connection requests</h2>
      <FusionConnectionsFilters/>
      <Row style={{'marginTop': '24px'}}>
        <Col>
          <FusionConnectionsTable/>
        </Col>
      </Row>
    </div>
  )
}

export default connect()(FusionIntegration)

