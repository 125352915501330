import moment from 'moment'
import { getKpiImpressions, getKpiCampaigns } from '../util/api'
import { impressionsChartOptions, campaignsCreatedOptions } from '../util/charts'

const initialState = {
  impressionsChartOptions: impressionsChartOptions,
  campaignsChartOptions: campaignsCreatedOptions,
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IMPRESSIONS_SERIES':
      return {...state, impressionsChartOptions: {...impressionsChartOptions, series: action.series}}
    case 'SET_CAMPAIGNS_IMPRESSIONS':
      return {...state, campaignsChartOptions: {...impressionsChartOptions, series: action.series}}
    default:
      return state
  }
}

export const loadKpis = () => {
  return (dispatch) => {
    let startDate = moment().subtract('1', 'month').format('YYYY-MM-DD')
    let enddate = moment().format('YYYY-MM-DD')

    getKpiImpressions(startDate, enddate).then(({data}) => {
      dispatch({
        type: 'SET_IMPRESSIONS_SERIES',
        series: {
          data: transformToSeries(data, 'impressions'),
          'name': 'impressions',
          color: 'rgb(22, 214, 216)',
        },
      })
    })

    getKpiCampaigns(startDate, enddate).then(({data}) => {
      dispatch({
        type: 'SET_CAMPAIGNS_IMPRESSIONS',
        series: [{
          data: transformToSeries(data, 'created'),
          name: 'campaigns',
          color: 'rgb(22, 214, 216)',
        }]
      })
    })
  }
}

const transformToSeries = (data, key) => {
  return data.map(item => {
      let timestamp = moment(item.date).subtract(1, 'days').unix() * 1000
      return [timestamp, parseInt(item[key])]
  })
}

export default dashboardReducer
