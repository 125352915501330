import { find, reduce } from 'lodash'
import { toast } from 'react-toastify'
import { publicCampaignApi } from '../axios'
import { getFusionRequests } from './../util/api'

import { providers, states } from './../config/fusion'

const initialState = {
  audienceReportAccounts: [],
  requests: {
    dv360: [],
    facebook: [],
    adwords: []
  },
  providerFilters: providers,
  stateFilters: states,
  providers,
  states
}

const fusionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_FUSION_ACCOUNT_SUCCEEDED':
      return state
    case 'CREATE_FUSION_ACCOUNT_FAILED':
      return state
    case 'SET_FUSION_ACCOUNT_REQUEST':
      let {status, requestId} = action.data.request

      let provider = find(state.providers, provider => {
        return find(state.requests[provider], {requestId: requestId})
      })

      let newProviderItems = state.requests[provider].map(request => {
        return request.requestId !== requestId
          ? request
          : {
            ...find(state.requests[provider], {requestId: requestId}),
            status
          }
      })

      let requests = reduce(
        state.requests,
        (carry, requests, providerKey) => {
          if (providerKey === provider) {
            carry[providerKey] = newProviderItems
          } else {
            carry[providerKey] = requests
          }

          return carry
        },
        {}
      )

      return {...state, requests}
    case 'SET_FUSION_ACCOUNT_REQUESTS':
      return {...state, requests: action.requests}
    case 'SET_PROVIDER_FILTER':
      return {...state, providerFilters: action.data}
    case 'SET_STATE_FILTER':
      return {...state, stateFilters: action.data}
    default:
      return state
  }
}

export const setAccountRequest = request => ({
  type: 'SET_FUSION_ACCOUNT_REQUEST',
  data: {request}
})

export const setProviderFilter = provider => ({
  type: 'SET_PROVIDER_FILTER',
  data: provider === 'all' ? providers : [provider]
})

export const setStateFilter = state => ({
  type: 'SET_STATE_FILTER',
  data: state === 'all' ? states : [state]
})

export const fetchFusionRequests = () => {
  return async dispatch => {
    let {data} = await getFusionRequests()

    dispatch({
      type: 'SET_FUSION_ACCOUNT_REQUESTS',
      requests: data
    })
  }
}

export const transitionRequest = request => {
  return dispatch => {
    return publicCampaignApi
      .patch(
        `${process.env.REACT_APP_CAMPAIGN_API_URL}/control-panel/accounts/connectionRequests/${request.requestId}`,
        {status: request.nextTransitionState},
        {headers: {'Customer-id': request.audienceReportAccount.id}}
      )
      .then(response => {
        dispatch(setAccountRequest(response.data.data))
      })
      .catch(exception => {
        toast.error(`${exception.response.data.error.message}`)
      })
  }
}

export default fusionReducer
