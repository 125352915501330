import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import Select from 'react-select'
import { errorStyles, dataAssessmentSelectStyles } from '../styles/styles'
import useForm from 'react-hook-form'
import { fetchAudiences, fetchReports, requestReport } from '../reducers/dataAssessmentReducer'
import DataAssessmentTable from '../components/DataAssessmentTable'

const DataAssessmentView = ({requestReport, fetchAudiences, fetchReports, audiences, isLoading}) => {
  const [audience] = useState()
  const {register, handleSubmit, errors, setValue, setError} = useForm()

  useEffect(() => {
    register({name: 'audience', 'required': true})
  }, [register])

  useEffect(() => {
    fetchAudiences()
    fetchReports()
  }, [fetchAudiences, fetchReports])

  const handleAudienceChange = value => {
    setValue('audience', value)
  }

  const onSubmit = (data) => {
    if (data.audience === undefined) {
      setError('audience', 'required', 'Audience is required')
      return
    }

    requestReport(data)
  }

  return <div>
    <h4>Request new Report</h4>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-xs-2">
          <div className="form-group">
            <label>Campaign ID</label>
            <Tooltip title={'The campaign must have a start date for the last 6 months'} size='big'>
              <input name="pid"
                     placeholder='Input...'
                     ref={register({required: true})}
                     className="form-control"
                     style={errors.pid ? errorStyles : {}}/>
            </Tooltip>
          </div>
        </div>
        <div className="col-xs-4">
          <div className="form-group">
            <label>Audience</label>
            <Select
              styles={dataAssessmentSelectStyles}
              placeholder={audience}
              onChange={handleAudienceChange}
              options={audiences}
            />
            {errors.audience && <small style={{'color': 'red'}}>Audience is required</small>}
          </div>
        </div>
        <div className="col-xs-1">
          <div className="form-group">
            <label>&nbsp;</label>
            <button type="submit" className="form-control btn btn-default btn-wide">
              Request Report
            </button>
          </div>
        </div>
      </div>
    </form>

    {isLoading && <div className="spinner">Loading...</div>}
    {!isLoading && <DataAssessmentTable/>}
  </div>
}

const mapStateToProps = ({dataAssessment}) => {
  return {
    audiences: dataAssessment.audiences,
    isLoading: dataAssessment.reports.length === 0
  }
}

export default connect(mapStateToProps, {fetchAudiences, fetchReports, requestReport})(DataAssessmentView)
