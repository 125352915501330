import React from 'react'
import Modal from 'react-modal'
import { modalStyles, modalHeaderStyles } from './../styles/styles'
import { connect } from 'react-redux'
import { toggleLoginModal } from '../reducers/modalReducer'

const styles = {
  cursor: "pointer"
}

const LoginToAccountModal = ({modalIsOpen, toggleLoginModal, webappUrl, webappToken}) => {
  const redirectToProduction = () => {
    window.open(`${process.env.REACT_APP_WEBAPP_URL}/login/${webappToken}`)
  }

  const redirectToRC = () => {
    window.open(`${process.env.REACT_APP_WEBAPP_RC_URL}/login/${webappToken}`)
  }
  
  return <div>
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px'}}}
      onRequestClose={toggleLoginModal}
    >
      <div>
        <div className='row'>
          <div className='col-md-12' style={modalHeaderStyles}>
            <button type="button" className="close" onClick={toggleLoginModal}>&times;</button>
            <h4 style={{'fontSize': '20px'}}>Login to account</h4>
          </div>
        </div>
        <div className='row'>
          <div style={styles} onClick={redirectToProduction} className='col-md-6 login-as-item'>
            <div className="login-as-item-mark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                   preserveAspectRatio="xMinYMin meet" viewBox="0 0 21 20">
                <path className="fill"
                      d="M13.794,6.994c-0.198,0.201-0.522,0.201-0.72,0l-0.809-0.816c-0.198-0.201-0.522-0.201-0.721,0L9.208,8.541c-0.198,0.201-0.522,0.201-0.72,0L8.19,8.239c-0.198-0.2-0.522-0.2-0.721,0l-3.355,3.396C3.916,11.836,3.983,12,4.263,12h1.019c0.28,0,0.739,0,1.019,0h2.038c0.28,0,0.739,0,1.019,0h2.039c0.279,0,0.738,0,1.018,0h4.076c0.28,0,0.51-0.232,0.51-0.516l0,0c0-0.283,0-0.748,0-1.031V4.266c0-0.283-0.162-0.352-0.36-0.15L13.794,6.994z"/>
                <path className="fill"
                      d="M20,0h-1H2H1C0.448,0,0,0.447,0,1v1v12v1c0,0.553,0.448,1,1,1h1h2.376H7l4.054,2.548l2.1,1.319C13.619,20.16,14,19.949,14,19.4v-0.853V16h3.373H19h1c0.553,0,1-0.447,1-1v-1V2V1C21,0.447,20.553,0,20,0z M18,14h-0.627h-2.016h-0.724H14h-0.166H13.75c-0.273,0-0.54,0-0.75,0c-0.55,0-1,0.45-1,1c0,0.047,0,0.091,0,0.137v0.029v0.367V16v0.78l-3.936-2.474L7.576,14h-3.2H3H2v-1V3V2h1h15h1v1v10v1H18z"/>
              </svg>
            </div>
            <h4 className="login-as-item-headline">AudienceReport</h4>
            The current AudienceReport app <br/>as you know and love it
          </div>
          <div style={styles} onClick={redirectToRC} className="col-xs-6 login-as-item login-as-item-alpha">
            <div className="login-as-item-mark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                   preserveAspectRatio="xMinYMin meet" viewBox="0 0 21 20">
                <path className="fill"
                      d="M13.794,6.994c-0.198,0.201-0.522,0.201-0.72,0l-0.809-0.816c-0.198-0.201-0.522-0.201-0.721,0L9.208,8.541c-0.198,0.201-0.522,0.201-0.72,0L8.19,8.239c-0.198-0.2-0.522-0.2-0.721,0l-3.355,3.396C3.916,11.836,3.983,12,4.263,12h1.019c0.28,0,0.739,0,1.019,0h2.038c0.28,0,0.739,0,1.019,0h2.039c0.279,0,0.738,0,1.018,0h4.076c0.28,0,0.51-0.232,0.51-0.516l0,0c0-0.283,0-0.748,0-1.031V4.266c0-0.283-0.162-0.352-0.36-0.15L13.794,6.994z"/>
                <path className="fill"
                      d="M20,0h-1H2H1C0.448,0,0,0.447,0,1v1v12v1c0,0.553,0.448,1,1,1h1h2.376H7l4.054,2.548l2.1,1.319C13.619,20.16,14,19.949,14,19.4v-0.853V16h3.373H19h1c0.553,0,1-0.447,1-1v-1V2V1C21,0.447,20.553,0,20,0z M18,14h-0.627h-2.016h-0.724H14h-0.166H13.75c-0.273,0-0.54,0-0.75,0c-0.55,0-1,0.45-1,1c0,0.047,0,0.091,0,0.137v0.029v0.367V16v0.78l-3.936-2.474L7.576,14h-3.2H3H2v-1V3V2h1h15h1v1v10v1H18z"/>
              </svg>
            </div>
            <h4 className="login-as-item-headline">AudienceReport Alpha</h4>
            Try out the upcoming version of <br/>AudienceReport before it goes live
          </div>
        </div>
      </div>
    </Modal>
  </div>
}


const mapStateToProps = ({account, modals}) => {
  return {
    modalIsOpen: modals.loginModal,
    webappToken: account.webapplogin.token,
    webappUrl: account.webapplogin.webappurl,
  }
}

export default connect(mapStateToProps, {toggleLoginModal})(LoginToAccountModal)

