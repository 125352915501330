const initialState = {
  deleteCampaignsModal: false,
  moveCampaignsModal: false,
  cloneCampaignsModal: false,
  rawLogExportModal: false,
  loginModal: false,
  editModal: false,
  createModal: false,
  createConsentModal: false,
  deleteModal: false,
  brandingModal: false,
  campaignModal: false,
  techedgeAccountModal: false,
  techedgeMappingModal: false
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_LOGIN_MODAL':
      return {...state, loginModal: !state.loginModal}
    case 'TOGGLE_CAMPAIGN_MODAL':
      return {...state, campaignModal: !state.campaignModal}
    case 'TOGGLE_EDIT_MODAL':
      return {...state, editModal: !state.editModal}
    case 'TOGGLE_CREATE_MODAL':
      return {...state, createModal: !state.createModal}
    case 'TOGGLE_CREATE_IAB_CONSENT_MODAL':
      return {...state, createConsentModal: !state.createConsentModal}
    case 'TOGGLE_DELETE_MODAL':
      return {...state, deleteModal: !state.deleteModal}
    case 'TOGGLE_BRANDING_MODAL':
      return {...state, brandingModal: !state.brandingModal}
    case 'TOGGLE_DELETE_CAMPAIGNS_MODAL':
      return {...state, deleteCampaignsModal: !state.deleteCampaignsModal}
    case 'TOGGLE_MOVE_CAMPAIGNS_MODAL':
      return {...state, moveCampaignsModal: !state.moveCampaignsModal}
    case 'TOGGLE_CLONE_CAMPAIGNS_MODAL':
      return {...state, cloneCampaignsModal: !state.cloneCampaignsModal}
    case 'TOGGLE_RAW_LOG_EXPORT_MODAL':
      return {...state, rawLogExportModal: !state.rawLogExportModal}
    case 'TOGGLE_TECHEDGE_ACCOUNT_MODAL':
      return {...state, techedgeAccountModal: !state.techedgeAccountModal}
    case 'TOGGLE_TECHEDGE_MAPPING_MODAL':
      return {...state, techedgeMappingModal: !state.techedgeMappingModal}
    case 'CLOSE_TECHEDGE_MAPPING_MODAL':
      return {...state, techedgeMappingModal: false}
    default:
      return state
  }
}

export const toggleDeleteCampaignsModal = () => ({
  type: 'TOGGLE_DELETE_CAMPAIGNS_MODAL',
})

export const toggleCloneCampaignsModal = () => ({
  type: 'TOGGLE_CLONE_CAMPAIGNS_MODAL',
})

export const toggleCampaignExportModal = () => ({
  type: 'TOGGLE_RAW_LOG_EXPORT_MODAL',
})

export const toggleMoveCampaignsModal = () => ({
  type: 'TOGGLE_MOVE_CAMPAIGNS_MODAL',
})

export const toggleLoginModal = () => ({
  type: 'TOGGLE_LOGIN_MODAL'
})

export const toggleEditModal = () => ({
  type: 'TOGGLE_EDIT_MODAL'
})

export const toggleDeleteModal = () => ({
  type: 'TOGGLE_DELETE_MODAL'
})

export const toggleCreateModal = () => ({
  type: 'TOGGLE_CREATE_MODAL'
})

export const toggleBrandingModal = () => ({
  type: 'TOGGLE_BRANDING_MODAL'
})

export const toggleCampaignModal = () => ({
  type: 'TOGGLE_CAMPAIGN_MODAL'
})

export const toggleTechedgeAccountModal = () => {
  return {
    type: 'TOGGLE_TECHEDGE_ACCOUNT_MODAL'
  }
}

export const toggleCreateConsentModal = () => {
  return {
    type: 'TOGGLE_CREATE_IAB_CONSENT_MODAL'
  }
}

export const closeTechedgeMappingModal = () => {
  return {
    type: 'CLOSE_TECHEDGE_MAPPING_MODAL'
  }
}

export default modalReducer
