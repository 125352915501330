import React from 'react'
import { connect } from 'react-redux'
import LoginToAccountModal from '../modals/loginToAccountModal'

const AccountList = ({accounts, handleRedirectClick}) =>
  <div id="company-grid">
    <LoginToAccountModal/>
    {accounts.map(account =>
      <div key={account.id} className="fluid-item">
        <div className="fluid-content" onClick={() => handleRedirectClick(account)}>
          <div className="avatar lg">
            <img src={account.branding.accountAvatar || 'images/avatars/default.png'} alt={'avatar'}/>
          </div>
          <h4 className="title">{account.name}</h4>
          <div className="cid">{account.cid}</div>
        </div>
      </div>
    )}
  </div>

const mapStateToProps = ({accounts}, {term}) => ({
  accounts: accounts.audienceReportAccounts.filter(account => {
    return !term
      || account.name.toLowerCase().includes(term.toLowerCase())
      || account.cid.toLowerCase().includes(term.toLowerCase())
  })
})

export default connect(mapStateToProps)(AccountList)
