import { getDMSOperations } from '../util/api'


const initialState = {
  operations: [],
}

const DMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DMS_OPERATIONS':
      return {...state, operations: action.operations}
    default:
      return state
  }
}

export const fetchOperations = () => {
  return (dispatch) => {
    return getDMSOperations()
      .then(response => dispatch({
        type: 'SET_DMS_OPERATIONS',
        operations: response.data
      }))
  }
}

export default DMSReducer
