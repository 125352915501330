import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row } from 'react-bootstrap'
import TechedgeCampaignDetails from './TechedgeCampaignDetails'
import TechedgeSnapshotDetails from './TechedgeSnapshotDetails'

const TechedgeCampaign = ({history}) => {
  if (history.location.state === undefined) {
    return null
  }

  const redirectToList = () => {
    history.push(`/integrations/techedge/campaigns`)
  }

  let campaign = history.location.state.campaign

  return <Row>
    <TechedgeCampaignDetails campaign={campaign} redirectAction={redirectToList}/>
    <TechedgeSnapshotDetails campaign={campaign}/>
  </Row>
}

export default withRouter(connect()(TechedgeCampaign))

