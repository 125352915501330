import React from 'react'
import { connect } from 'react-redux'
import CampaignActionDetails from './CampaignActionDetails'
import { toggleCampaign, toggleSelectedCampaign } from '../reducers/campaignReducer'
import { generateWebAppLink } from '../reducers/accountReducer'

const AccountCampaign = ({campaign, toggle, webappToken, generateWebAppLink, accountId}) => {
  const openInAudienceReport = (id) => {
    window.open(`${process.env.REACT_APP_WEBAPP_URL}/logintoreport/${webappToken}/${id}`)

    // the token is deleted in the webapp and cannot be reused.
    generateWebAppLink(accountId)
  }

  return <tr>
    <td className="cb-cell">
      <label className="pretty-checkbox">
        <input type="checkbox" onChange={() => toggle(campaign.id)} checked={campaign.selected}/>
        <i/>
      </label>
    </td>
    <td className="projectName">
      <div onClick={() => openInAudienceReport(campaign.id)}>
        {campaign.name}
      </div>
    </td>
    <td>{campaign.id}</td>
    <td>{campaign.type}</td>
    <td>{campaign.created}</td>
    <td className={`status status-${campaign.status}`}>
      <i className="icon flaticon stroke"/>{campaign.status}
    </td>
    <td>
      <span className="pull-right">{(campaign.events || 0)}</span>
    </td>
    <td>
      <CampaignActionDetails campaign={campaign}/>
    </td>
  </tr>
}

const mapStateToProps = ({account}, {campaign}) => ({
  webappToken: account.webapplogin.token,
  accountId: account.account.id,
  campaign
})

const mapDispatchToProps = dispatch => ({
  generateWebAppLink: (accountId) => {
    dispatch(generateWebAppLink(accountId));
  },
  toggle: (id) => {
    dispatch(toggleCampaign(id))
    dispatch(toggleSelectedCampaign(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountCampaign)
