import React from 'react'
import { connect } from 'react-redux'
import CampaignActionDetails from './CampaignActionDetails'
import { fetchReport } from '../reducers/reportReducer'
import { toggleCampaignModal } from '../reducers/modalReducer'

const TrackpointLookupCampaigns = ({results, toggleCampaignModal}) =>
  <div>
    {results.map(trackpointResult => {

      if (trackpointResult.campaigns.length === 0) {
        return <div>
          No campaigns found for {trackpointResult.id}.
        </div>
      }

      return <table className="table table-vcenter table-projects">
        <thead>
        <tr>
          <th> Name</th>
          <th>PID</th>
          <th>Created</th>
          <th>Status</th>
          <th>Events</th>
          <th><span className="pull-right">Action</span></th>
        </tr>
        </thead>
        <tbody>
        {trackpointResult.campaigns.map(campaign =>
          <tr key={campaign.id}>
            <td className="projectName">
              {/*TODO: check if a to button is okay*/}
              <button className="title" onClick={() => toggleCampaignModal(campaign.id)}>{campaign.name}</button>
              <small>{campaign.referenceId}</small>
            </td>
            <td>{campaign.id}</td>
            <td>{campaign.created}</td>
            <td className={`status status-${campaign.status}`}><i className="icon flaticon stroke"/>
              {campaign.status}
            </td>
            <td>{campaign.events}</td>
            <td>
              <CampaignActionDetails campaign={campaign}/>
            </td>
          </tr>
        )}
        </tbody>
      </table>
    })}
  </div>

const mapStateToProps = ({trackpoints}) => ({
  results: trackpoints.lookupResults.results,
})

const mapDispatchToProps = dispatch => ({
  toggleCampaignModal: (id) => {
    dispatch(fetchReport(id))
    dispatch(toggleCampaignModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackpointLookupCampaigns)

