import React from 'react'
import Select, { createFilter } from 'react-select'
import { reactSelectStyles } from '../styles/styles'

const AccountFormOrganization = ({organizations, handleChange, errors, initialValue}) =>
  <div className='form-group'>
    <label>Organization</label>
    <Select
      filterOption={createFilter({ignoreAccents: false})}
      styles={reactSelectStyles}
      isLoading={organizations.length === 0 && initialValue === undefined}
      placeholder={initialValue}
      onChange={handleChange}
      options={organizations}
      isDisabled={initialValue !== undefined}
    />
    {errors.organization && 'Organization is required'}
  </div>

export default AccountFormOrganization

