import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import NavbarBrand from './NavbarBrand'
import CampaignSearcher from './CampaignSearcher'

const Navbar = ({isAdmin}) =>
  <nav className="navbar navbar-inverse navbar-fixed-top navbar-default navbar-lg" role="banner" id="navbar-header">
    <div className="container-fluid has-max-width">
      <div className="navbar-header">
        <NavLink activeClassName="active" to="/dashboard">
          <div className="navbar-brand svg-content">
            <NavbarBrand/>
          </div>
        </NavLink>
        <ul className="nav navbar-nav">
          <li>
            <NavLink activeClassName="active" to="/dashboard">Dashboard</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/accounts">Accounts</NavLink>
          </li>
          <li>
            <a href="https://cp.audienceproject.com/users">Users</a>
          </li>
          <li>
            <NavLink activeClassName="active" to="/exports">Exports</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/trackpoints">Trackpoint lookup</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/integrations">Integrations</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/data-assessment">Data Assesment</NavLink>
          </li>
          {isAdmin && <li>
            <NavLink activeClassName="active" to="/iab-blacklist">Blacklist</NavLink>
          </li>}
        </ul>
      </div>
      <CampaignSearcher/>
    </div>
  </nav>

const mapStateToProps = ({user: {isAdmin}}) => ({
  isAdmin
})

export default connect(mapStateToProps)(Navbar)
