import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import { flatMap, includes, orderBy, find } from 'lodash'
import { getTransitionText, getNextTransitionState } from '../util/fusion'
import { transitionRequest } from '../reducers/fusionReducer'

const fusionConnectionsTable = ({requests, transitionRequest, isLoading}) => {
  if (isLoading) {
    return <div>Loading data…</div>
  }

  const getButtonClass = status => status === 'Revoked' ? 'btn btn-warning' : 'btn btn-info';

  return (
    <table className="table table-vcenter">
      <thead>
        <tr>
          <th>Created On</th>
          <th>Provider</th>
          <th>Contact Email</th>
          <th>Contact Name</th>
          <th>Fusion Account ID</th>
          <th>AudienceReport Account</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {requests.map(request => <tr key={request.requestId}>
            <td>{moment(request.created).format('Y-MM-DD HH:mm')}</td>
            <td>{request.provider}</td>
            <td>{request.contactEmail}</td>
            <td>{request.contactName}</td>
            <td>{request.accountId}</td>
            <td>{request.audienceReportAccount ? request.audienceReportAccount.name : 'N/A'}</td>
            <td>
              <Tooltip title={request.toolTipText} size='big'>
                <button
                  className={getButtonClass(request.status)}
                  onClick={() => transitionRequest(request)}
                >
                    {request.status}
                </button>
              </Tooltip>
            </td>
            <td>
              <Tooltip title="Reject connection request" size='big'>
                <button
                  className="btn btn-danger"
                  onClick={() => transitionRequest({...request, nextTransitionState: 'Rejected'})}
                >
                  Reject
                </button>
              </Tooltip>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

const mapStateToProps = ({fusion, accounts}) => {
  let requests = flatMap(fusion.providerFilters, (provider) => {
    return fusion.requests[provider]
      .filter(request => includes(fusion.stateFilters, request.status))
      .map(request => {
        let audienceReportAccount = find(
          accounts.audienceReportAccounts,
          {id: request.audienceReportAccount}
        )

        return {
          ...request,
          provider,
          audienceReportAccount,
          toolTipText: getTransitionText(request.status),
          nextTransitionState: getNextTransitionState(request.status)
        }
      })
  })

  return {
    requests: orderBy(requests, ['created'], ['desc']),
    isLoading: accounts.audienceReportAccounts.length === 0
  }
}

export default connect(mapStateToProps, {transitionRequest})(fusionConnectionsTable)

