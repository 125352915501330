import { getTrackpointDateDetails, getTrackpointDetails } from '../util/api'
import moment from 'moment'

const trackpointEventColumns = [
  'sumClick',
  'sumConversion',
  'sumImpression',
  'sumIvSupported',
  'sumIvInview',
  'sumOther',
  'sumPurchase',
  'sumSignup',
  'sumVideostart',
  'sumVideo25pct',
  'sumVideo50pct',
  'sumVideo75pct',
  'sumVideocomplete',
]

const initialState = {
  searchParams: null,
  columns: trackpointEventColumns,
  lookupResults: {
    results: []
  },
  dateResults: {
    trackpoints: [],
    totals: {},
  },
  isSearching: false,
}

const trackpointReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_IS_SEARCHING':
      return {...state, isSearching: !state.isSearching}
    case 'SET_LOOKUP_RESULTS':
      return {...state, lookupResults: action.lookupResults}
    case 'SET_DATE_RESULTS':
      return {...state, dateResults: action.dateResults}
    case 'SET_SEARCH_PARAMS':
      return {...state, searchParams: action.searchParams}
    default:
      return state
  }
}

const setLookupResults = lookupResults => ({
  type: 'SET_LOOKUP_RESULTS',
  lookupResults,
})

const setSearchParams = searchParams => ({
  type: 'SET_SEARCH_PARAMS',
  searchParams,
})

const setDateLookupResults = dateResults => ({
  type: 'SET_DATE_RESULTS',
  dateResults,
})

const toggleSearching = () => ({
  type: 'TOGGLE_IS_SEARCHING'
})

export const lookupTrackpoint = ({cid, tailcode, searchFilters}) => {
  let payload = {
    cid: cid.value,
    tailcodePattern: tailcode,
    searchParams: {
      includeCampaigns: searchFilters['includeCampaigns'],
      startDate: moment().subtract(365, 'day').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  }

  return (dispatch) => {
    dispatch(toggleSearching())
    return getTrackpointDetails(payload)
      .then((data) => {
        dispatch(setLookupResults(data))
        dispatch(toggleSearching())
        dispatch(setSearchParams(payload))
      })
  }
}

export const lookupByDate = ({date, cid, tailcode}) => {
  return (dispatch) => {
    dispatch(toggleSearching())
    return getTrackpointDateDetails({
      cid,
      tailcode,
      date: moment(date).format('YYYY-MM-DD'),
    }).then(response => {
      dispatch(toggleSearching())
      dispatch(setDateLookupResults(response))
    })
  }
}

export default trackpointReducer
