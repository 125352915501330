import React from 'react'
import { connect } from 'react-redux'
import TrackpointLookupCampaigns from './TrackpointLookupCampaigns'
import TrackpointLookupDates from './TrackpointLookupDates'

const TrackpointLookupTables = ({isSearching}) =>
  isSearching ? <div className='row'>
      <div className="spinner">Loading...</div>
      }
    </div>
    : <div>
      <div className='row'>
        <div className="col-md-12">
          <TrackpointLookupDates/>
        </div>
      </div>
      <div className='row'>
        <div className="col-md-12">
          <TrackpointLookupCampaigns/>
        </div>
      </div>
    </div>

const mapStateToProps = ({trackpoints: {isSearching}}) => ({isSearching})

export default connect(mapStateToProps)(TrackpointLookupTables)

