import React, { useState } from 'react'
import Modal from 'react-modal'
import { Typeahead } from 'react-bootstrap-typeahead'
import ModalHeader from '../components/ModalHeader'
import ModalFooter from '../components/ModalFooter'
import { modalStyles } from './../styles/styles'
import { connect } from 'react-redux'
import { toggleMoveCampaignsModal } from '../reducers/modalReducer'
import { moveCampaigns } from '../reducers/accountReducer'
import { deselectCampaign } from '../reducers/campaignReducer'

const styles = {
  ...modalStyles,
  content: {
    ...modalStyles.content,
    'padding': '0px', 'width': '30%'
  }
}

const MoveCampaignsModal = ({
                              options,
                              campaigns,
                              modalIsOpen,
                              toggleModal,
                              moveCampaigns
                            }) => {

  const [account, setAccount] = useState()

  const onSubmit = (event) => {
    event.preventDefault()
    moveCampaigns(account[0], campaigns)
  }

  return <Modal
    closeTimeoutMS={200}
    isOpen={modalIsOpen}
    style={styles}
    onRequestClose={toggleModal}
  >
    <form onSubmit={onSubmit}>
      <div className="modal-content">
        <ModalHeader title={'Move campaigns to account'} onCancel={toggleModal}/>
        <div className="modal-body">
          <div className="row">
            <div className="form-group col-xs-12">
              <div>
                <label>Destination account</label>
              </div>
              <div className='test' style={{'borderRadius': '0px', 'color': 'red'}}>
                <Typeahead
                  id='move-account-selector'
                  bsSize={'small'}
                  placeholder={'Select Account'}
                  options={options}
                  onChange={(selected) => setAccount(selected)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>Campaigns to be moved:</label>
              <div className="form-group">
                {campaigns.map(campaign =>
                  <div key={campaign.id}>
                    <small>{campaign.id} : {campaign.name}</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalFooter onCancel={toggleModal} submitLabel={'Move'}/>
      </div>
    </form>
  </Modal>
}

const mapStateToProps = ({modals, account, campaign, accounts}) => {
  let campaigns = campaign.selectedCampaign.id
    ? [campaign.selectedCampaign]
    : campaign.selectedCampaigns

  return {
    campaigns,
    modalIsOpen: modals.moveCampaignsModal,
    options: accounts.audienceReportAccounts.filter(ARAccount =>
      ARAccount.id !== account.account.id
    ).map(({id, name, cid}) => {
      return {id, label: `${name} : ${cid}`}
    }),
  }
}

const mapDispatchToProps = dispatch => ({
  moveCampaigns: (account, campaigns) => dispatch(moveCampaigns(account, campaigns)),
  toggleModal: () => {
    dispatch(deselectCampaign())
    dispatch(toggleMoveCampaignsModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MoveCampaignsModal)