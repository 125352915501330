import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import {
  getIntegration,
} from '../util/campaignManagerApi';
import Spinner from './common/Spinner';
import LabelExtraName from './common/LabelExtraName';
import CampaignManagerIntegrationOverview from './CampaignManagerIntegrationOverview';

const TAB_SETTINGS = 'settings';
const TAB_TRACKING = 'tracking';
const TAB_AUDIT = 'audit';

const CampaignManagerIntegrationDetails = ({
  accountId,
  integrationId,
  googleAccountId,
  googleAccountName,
  onHideDetails,
}) => {
  const [view, setView] = useState(TAB_SETTINGS)
  const [integration, setIntegration] = useState();

  const onSetView = newView => {
    setView(newView);
  }

  useEffect(() => {
    getIntegration(accountId, integrationId)
      .then(json => {
        setIntegration(json);
      });
  }, [accountId, integrationId]);

  const renderTabs = () => {
    const tabs = [{
      key: TAB_SETTINGS,
      label: 'Settings',
      active: true,
    }, {
      key: TAB_TRACKING,
      label: 'Tracking information',
    }, {
      key: TAB_AUDIT,
      label: 'Audit',
    }];

    return (
      <ul className="nav nav-tabs" role="tablist">
        {tabs.map(tab => (
          <li
            key={tab.key}
            className={tab.active && 'active'}
          >
            <button onClick={() => onSetView(tab.key)}>
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderAdvertisersHeader = () => {
    const row = [
      'Selected Advertisers',
    ];

    return (
      <thead>
        <tr>
          {row.map((cell, index) => (
            <th key={index}>
              {cell}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  const renderAdvertisers = () => {
    const { selectedAdvertisers: advertisers } = integration;

    return (
      <table className='table table-vcenter table-projects table-scrollable'>
        {renderAdvertisersHeader()}
        <tbody>
          {sortBy(advertisers)
            .map(advertiser => (
              <tr key={advertiser}>
                <td>
                  {advertiser}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const renderTabSettingsContent = () => (
    <React.Fragment>
      <CampaignManagerIntegrationOverview integration={integration} />
      {renderAdvertisers()}
    </React.Fragment>
  );

  const renderTabTrackingContent = () => (
    'Tracking information'
  );

  const renderTabAuditContent = () => (
    'Audit'
  );

  const renderActiveTabContent = () => {
    switch (view) {
      case TAB_TRACKING: return renderTabTrackingContent();
      case TAB_AUDIT: return renderTabAuditContent();
      default: return renderTabSettingsContent();
    }
  }

  const renderContent = () => {
    if (!integration) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        {renderTabs()}
        {renderActiveTabContent()}
      </React.Fragment>
    );
  }

  return (
    <div>
      <h2 className='page-title page-breadcrumb'>
        <button onClick={onHideDetails}>
          Campaign Manager Integrations
        </button>
        <LabelExtraName
          name={googleAccountName}
          extra={googleAccountId}
        />
      </h2>

      {renderContent()}
    </div>
  );
};

CampaignManagerIntegrationDetails.propTypes = {
  accountId: PropTypes.number.isRequired,
  integrationId: PropTypes.string.isRequired,
  googleAccountId: PropTypes.number.isRequired,
  googleAccountName: PropTypes.string.isRequired,
  onHideDetails: PropTypes.func.isRequired,
};

export default CampaignManagerIntegrationDetails;
