import React from 'react'
import Modal from 'react-modal'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import ModalFooter from '../components/ModalFooter'
import ModalHeader from '../components/ModalHeader'
import { toggleCampaignExportModal } from '../reducers/modalReducer'
import { triggerExport } from '../reducers/exportReducer'
import { modalStyles } from './../styles/styles'

const RawLogExportModal = ({campaign, modalIsOpen, toggleCampaignExportModal, triggerExport}) => {
  const {register, handleSubmit, errors} = useForm()

  let errorStyles = errors.type ? {'color': 'red'} : {}
  return <Modal
    closeTimeoutMS={200}
    isOpen={modalIsOpen}
    style={{...modalStyles, content: {...modalStyles.content, 'padding': '0px', 'width': '40%'}}}
    onRequestClose={toggleCampaignExportModal}
  >
    <form onSubmit={handleSubmit((data) => triggerExport({...data, campaign}))}>
      <div className="modal-content raw-logs-modal">
        <ModalHeader onCancel={toggleCampaignExportModal} title={'Raw logs export'}/>
        <div className="modal-body">
          <div className="row">
            <div className="col-xs-6">
              <h5>Choose type</h5>
              <div>
                <div className="radio" style={errorStyles}>
                  <label>
                    <input className="danger" name="type" value='raw' type="radio" ref={register({required: true})}/>
                    Raw logs export
                  </label>
                </div>
                <div className="radio" style={errorStyles}>
                  <label>
                    <input name="type" type="radio" value='campaign' ref={register({required: true})}/>
                    Raw logs export for campaign
                  </label>
                </div>
                <div className="radio" style={errorStyles}>
                  <label>
                    <input name="type" type="radio" value='trackpoints' ref={register({required: true})}/>
                    Raw logs export for trackpoint
                  </label>
                </div>
              </div>

              <ul className="details-list">
                <li className="box">
                  <h5>Event count</h5>
                  {campaign.events}
                </li>
                <li className="box">
                  <h5>No. of report items</h5>
                  {campaign.numReportItems}
                </li>
              </ul>
            </div>

            <div className="col-xs-6">
              <h5>IAB consent</h5>
              <div>
                <p>Include log lines ONLY when implicit or explicit consent is given for the following:</p>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" ref={register} name='iab[storage]'/>
                  Storage and access
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" ref={register} name='iab[personalization]'/>
                  Personalization
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" ref={register} name='iab[content]'/> Content delivery
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" ref={register} name='iab[measurement]'/> Measurement
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" ref={register} name='iab[ads]'/> Ad selection
                </label>
              </div>
              <div>
                <p>(none checked = all data from logs)</p>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter onCancel={toggleCampaignExportModal} submitLabel={'Export'}/>
      </div>
    </form>
  </Modal>
}

const mapStateToProps = ({modals, campaign}) => {
  return {
    campaign: campaign.selectedCampaign,
    modalIsOpen: modals.rawLogExportModal,
  }
}

export default connect(mapStateToProps, {toggleCampaignExportModal, triggerExport})(RawLogExportModal)