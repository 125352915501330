import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import RawExportClipboardLine from '../components/RawExportClipboardLine'
import RawExportDownloadLine from '../components/RawExportDownloadLine'
import { fetchExports, setSearchTerm } from './../reducers/exportReducer'
import { getLabelClass } from './../styles/styles'

const ExportsView = ({fetchExports, setSearchTerm, exports}) => {
  useEffect(() => {
    fetchExports()
  }, [fetchExports])

  return <div>
    <div className="row">
      <div className="col-md-12">
        <h2 className='pull-left page-title'>
          Exports
        </h2>
      </div>
    </div>

    <div className="row">
      <div className="col-md-12">
        <div className="form-group form-group-max-width margin-right">
          <div className="input-group">
            <input
              type="search"
              className="form-control"
              placeholder="Quick search..."
              onChange={({target}) => setSearchTerm(target.value)}/>
            <span className="input-group-btn">
              <button type="submit" className="btn"><span className="fui-search"/></button>
            </span>
          </div>
        </div>
      </div>
    </div>

    {exports.length === 0
      ? <div className="spinner">Loading...</div>
      : <table className="table table-vcenter table-hover table-exports">
        <thead>
        <tr>
          <th>Campaign</th>
          <th>Account</th>
          <th>Type</th>
          <th>Date</th>
          <th>Status</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {exports.map((exportItem, index) =>
          exportItem.type === 'raw'
            ? <RawExportClipboardLine key={index} exportItem={exportItem} label={getLabelClass(exportItem.status)}/>
            : <RawExportDownloadLine key={index} exportItem={exportItem} label={getLabelClass(exportItem.status)}/>
        )}
        </tbody>
      </table>
    }
  </div>
}

const mapStateToProps = ({exports}) => {
  let searchTerm = exports.searchTerm.toLowerCase()

  return {
    exports: exports.campaignExports.filter(exportItem => {
      return searchTerm === ''
        || exportItem.projectId.includes(searchTerm)
        || exportItem.date.includes(searchTerm)
        || exportItem.type.includes(searchTerm)
        || exportItem.companyCid.toLowerCase().includes(searchTerm)
        || exportItem.projectName.toLowerCase().includes(searchTerm)
        || exportItem.status.toLowerCase().includes(searchTerm)
    })
  }
}

export default connect(mapStateToProps, {fetchExports, setSearchTerm})(ExportsView)
