import { toast } from 'react-toastify'
import { flatMap, orderBy } from 'lodash'
import _ from 'lodash'
import moment from 'moment'
import { fetchTechedgeAccounts, fetchAudienceReportAccounts } from '../reducers/accountsReducer'
import { closeTechedgeMappingModal, toggleTechedgeAccountModal } from '../reducers/modalReducer'
import {
  getTechedgeCampaigns,
  createTechedgeAccount,
  createTechedgeMapping,
  deleteTechedgeAccount,
  deleteTechedgeMapping,
  getCampaignExports,
} from '../util/techedgeApi'

const initialState = {
  filters: {
    campaignType: 'all',
    snapshotType: 'all',
    accountId: 'all',
    searchId: null,
    search: '',
  },
  snapshotStates: ['synced', 'pending'],
  campaignTypes: ['standard', 'integrated'],
  modalAccount: {},
  modalAccountMappings: [],
  campaigns: [],
  campaign: {},
  campaignView: false,
  snapshotJsonData: []
}

const techedgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TECHEDGE_ACCOUNT':
      return {
        ...state,
        modalAccount: action.account,
        modalAccountMappings: action.account.audienceReportAccounts
      }
    case 'SET_TECHEDGE_CAMPAIGNS':
      return {...state, campaigns: action.campaigns}
    case 'SET_TECHEDGE_SNAPSHOT_DATA':
      return {...state, snapshotJsonData: action.data}
    case 'CLEAR_TECHEDGE_SNAPSHOT_DATA':
      return {...state, snapshotJsonData: []}
    case 'SET_ACCOUNT_FILTER':
      return {...state, filters: {...state.filters, accountId: action.accountId}}
    case 'SET_SNAPSHOT_TYPE_FILTER':
      return {...state, filters: {...state.filters, snapshotType: action.snapshotType}}
    case 'SET_SNAPSHOT_ID_FILTER':
      return {...state, filters: {...state.filters, searchId: action.searchId}}
    case 'SET_CAMPAIGN_TYPE_FILTER':
      return {...state, filters: {...state.filters, campaignType: action.campaignType}}
    case 'SET_TECHEDGE_CAMPAIGN':
      return {...state, campaign: action.campaign}
    case 'TOGGLE_VIEW':
      return {...state, campaignView: !state.campaignView}
    default:
      return state
  }
}

export const setModalAccount = (account) => {
  return {
    type: 'SET_TECHEDGE_ACCOUNT',
    account
  }
}

export const setCampaigns = (campaigns) => {
  return {
    type: 'SET_TECHEDGE_CAMPAIGNS',
    campaigns
  }
}

export const setAccountFilter = (accountId) => ({
  type: 'SET_ACCOUNT_FILTER',
  accountId,
})

export const setSnapshotStateFilter = (snapshotType) => ({
  type: 'SET_SNAPSHOT_TYPE_FILTER',
  snapshotType
})

export const setSnapshotIdFilter = (searchId) => ({
  type: 'SET_SNAPSHOT_ID_FILTER',
  searchId
})

export const setCampaignTypeFilter = (campaignType) => ({
  type: 'SET_CAMPAIGN_TYPE_FILTER',
  campaignType
})

export const setSnapshotData = (data) => ({
  type: 'SET_TECHEDGE_SNAPSHOT_DATA',
  data
})

export const fetchTechedgeCampaigns = () => {
  return (dispatch) => {
    return getTechedgeCampaigns().then(campaigns => dispatch(setCampaigns(campaigns)))
  }
}

export const clearJSONData = () => ({
  type: 'CLEAR_TECHEDGE_SNAPSHOT_DATA',
})

export const fetchSnapshotJson = (snapshot, country) => {
  let now = moment()
  return (dispatch) => {
    return getCampaignExports(snapshot.id, {
      country,
      fromDate: now.clone().subtract('14', 'days').format('YYYY-MM-DD'),
      toDate: now.format('YYYY-MM-DD')
    }).then(data => {
      let sorted = orderBy(data, ['date'], ['desc'])
      dispatch(setSnapshotData(sorted))
    })
  }
}

export const openMappingModal = techedgeAccount => {
  return (dispatch, getState) => {
    let accounts = flatMap(techedgeAccount.audienceReportAccounts, (account) => {
      return _
        .filter(getState().accounts.audienceReportAccounts, {'id': account.id})
        .map(audienceReportAccount => {
          return {...audienceReportAccount, createdOn: account.createdOn}
        })
    })

    dispatch(setModalAccount({...techedgeAccount, 'audienceReportAccounts': accounts}))
    dispatch({
      type: 'TOGGLE_TECHEDGE_MAPPING_MODAL'
    })
  }
}
export const deleteMapping = (techedgeAccount, {id, name}) => {
  return (dispatch) => {
    deleteTechedgeMapping(techedgeAccount, id)
      .then(() => {
        toast.success(`Account: ${name} is no longer mapped to ${name}`);

        dispatch(closeTechedgeMappingModal())
        dispatch(fetchTechedgeAccounts())
        dispatch(fetchAudienceReportAccounts())
      })
  }
}

export const deleteAccount = (account) => {
  return (dispatch) => {
    deleteTechedgeAccount(account)
      .then(() => {
        toast.success(`Account: ${account.name} has been deleted`);
        dispatch(fetchTechedgeAccounts())
        dispatch(fetchAudienceReportAccounts())
      }).catch(() => {
      toast.error(`Error occoured. Please check your input values`);
    })
  }
}

export const createMapping = (account, {id, name}) => {
  return (dispatch) => {
    createTechedgeMapping(account, id)
      .then(() => {
        toast.success(`Account: ${account.name} has been mapped to ${name}`);

        dispatch(closeTechedgeMappingModal())
        dispatch(fetchTechedgeAccounts())
        dispatch(fetchAudienceReportAccounts())
      }).catch(() => {
      toast.error(`Error occoured. Please check your input values`);
    })
  }
}

export const createAccount = (payload) => {
  return (dispatch) => {
    createTechedgeAccount(payload)
      .then(() => {
        toast.success(`Account: ${payload.name} has been created`);
        dispatch(toggleTechedgeAccountModal())
        dispatch(fetchTechedgeAccounts())
        dispatch(fetchAudienceReportAccounts())
      }).catch(() => {
      toast.error(`Error occoured. Please check your input values`);
    })
  }
}

export default techedgeReducer