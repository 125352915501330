import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import accountReducer from './reducers/accountReducer'
import accountsReducer from './reducers/accountsReducer'
import techedgeReducer from './reducers/techedgeReducer'
import adformReducer from './reducers/adformReducer'
import fusionReducer from './reducers/fusionReducer'
import featureReducer from './reducers/featureReducer'
import modalReducer from './reducers/modalReducer'
import exportReducer from './reducers/exportReducer'
import reportReducer from './reducers/reportReducer'
import trackpointReducer from './reducers/trackpointReducer'
import campaignReducer from './reducers/campaignReducer'
import campaignManagerReducer from './reducers/campaignManagerReducer'
import userReducer from './reducers/userReducer'
import dashboardReducer from './reducers/dashboardReducer'
import dataAssessmentReducer from './reducers/dataAssessmentReducer'
import IABConsentReducer from './reducers/iabConsentReducer'
import searchReducer from './reducers/searchReducer'
import DMSReducer from './reducers/DMSReducer'

const reducer = combineReducers({
  account: accountReducer,
  accounts: accountsReducer,
  techedge: techedgeReducer,
  adform: adformReducer,
  fusion: fusionReducer,
  features: featureReducer,
  modals: modalReducer,
  exports: exportReducer,
  trackpoints: trackpointReducer,
  reports: reportReducer,
  campaign: campaignReducer,
  campaignManager: campaignManagerReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  dataAssessment: dataAssessmentReducer,
  iabConsents: IABConsentReducer,
  globalSearcher: searchReducer,
  dms: DMSReducer
})

export default createStore(
  reducer,
  applyMiddleware(thunk)
)
