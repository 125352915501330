import React from 'react'
import { Tooltip } from 'react-tippy'

const RawExportClipboardLine = ({exportItem, label}) =>
    <tr onClick={() => {navigator.clipboard.writeText(exportItem.path)}}
        className={exportItem.status !== 'FINISHED' ? 'disabled' : ''}>
      <td>{exportItem.name} : <small>({exportItem.pid})</small></td>
      <td>{exportItem.accountName} : <small>({exportItem.cid})</small></td>
      <td><small className="capitalize">{exportItem.type}</small></td>
      <td>{exportItem.date}</td>
      <td>
        <span className={`label ${label}`}>{exportItem.status}</span>
      </td>
      <td>
        <Tooltip title={"Copy S3 url to clipboard"} size='big'>
          <i className={'fui- flaticon stroke copy-2'}/>
        </Tooltip>
      </td>
    </tr>

export default RawExportClipboardLine

