import React from 'react';
import PropTypes from 'prop-types';

const LabelExtraName = ({ name, extra }) => {
  const hasExtra = typeof extra !== 'undefined';

  return (
    <span className="label-extra-name">
      {name || '-'}
      {hasExtra && (
        <small>
          [{extra}]
        </small>
      )}
    </span>
  );
};

LabelExtraName.propTypes = {
  name: PropTypes.string,
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

LabelExtraName.defaultProps = {
  name: undefined,
  extra: undefined,
};

export default LabelExtraName;
