import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import {
  fetchIntegrations,
} from '../reducers/campaignManagerReducer';
import Spinner from './common/Spinner';
import LabelDate from './common/LabelDate';
import LabelStatus from './common/LabelStatus';
import LabelExtraName from './common/LabelExtraName';
import CampaignManagerIntegrationDetails from './CampaignManagerIntegrationDetails';

const CampaignManagerIntegrationTab = ({
  integrations,
  fetchIntegrations,
}) => {
  const [search, setSearch] = useState();
  const [showDetails, setShowDetails] = useState();

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  function onSearch(event) {
    const { value } = event.target;
    setSearch(value.trim().toLowerCase());
  };

  function onShowDetails(integration) {
    setShowDetails(integration);
  }

  function onHideDetails() {
    setShowDetails(null);
  }

  const getIntegrationsFilter = () => {
    if (!search) {
      return integrations;
    }

    const matched = value => value && value.toLowerCase().includes(search);

    return integrations
      .filter(({
        audienceReportCID,
        googleAccountName,
      }) => (
        matched(audienceReportCID) ||
        matched(googleAccountName)
      ));
  };

  const renderSearch = () => {
    return (
      <div className='quick-search form-group form-group-max-width pull-right pull-up margin-right'>
        <div className='input-group'>
          <input
            type='search'
            className='form-control'
            placeholder='Search...'
            onChange={onSearch}
          />
          <span className='input-group-btn'>
            <button type='submit' className='btn'>
              <span className='fui-search' />
            </button>
          </span>
        </div>
      </div>
    );
  };

  const renderTableHeader = () => {
    const row = [
      'AudienceReport Account',
      'Google Account',
      'Activated',
      'Advertisers',
      'Country',
      'Status',
    ];

    return (
      <thead>
        <tr>
          {row.map((cell, index) => (
            <th key={index}>
              {cell}
            </th>
          ))}
          <th />
        </tr>
      </thead>
    );
  }

  const renderTableRow = (id, row) => (
    <tr key={id}>
      {row.map((cell, index) => (
        <td key={index}>
          {cell}
        </td>
      ))}
    </tr>
  );

  const renderARAccount = ({ audienceReportCID, audienceReportAccountId }) => (
    <a href={`/accounts/${audienceReportAccountId}`}>
      <LabelExtraName
        name={audienceReportCID}
        extra={audienceReportAccountId}
      />
    </a>
  );

  const renderAdvertisers = advertisers => {
    if (!advertisers || advertisers.length === 0) {
      return 0;
    }

    const tail = advertisers.length > 5 ? '…' : '';
    const title = [...advertisers].splice(0, 5).join(', ');

    return (
      <Tooltip
        size='big'
        title={`${title}${tail}`}
      >
        <span className={'label label-info'}>
          {advertisers.length}
        </span>
      </Tooltip>
    );
  }

  const renderGoogleAccount = ({ googleAccountName, googleAccountId }) => (
    <LabelExtraName
      name={googleAccountName}
      extra={googleAccountId}
    />
  );

  const renderOptions = integration => (
    <button
      className="btn btn-default"
      onClick={() => onShowDetails(integration)}
    >
      Details
    </button>
  );

  const renderTableBody = () => (
    <tbody>
      {getIntegrationsFilter()
        .map(integration => (
          renderTableRow(integration.id, [
            renderARAccount(integration),
            renderGoogleAccount(integration),
            <LabelDate date={integration.activatedAt} />,
            renderAdvertisers(integration.selectedAdvertisers),
            integration.country,
            <LabelStatus status={integration.status} />,
            renderOptions(integration),
          ])
        ))}
    </tbody>
  );

  const renderTable = () => (
    <React.Fragment>
      {renderSearch()}
      <table className="table table-vcenter table-projects">
        {renderTableHeader()}
        {renderTableBody()}
      </table>
    </React.Fragment>
  );

  const renderIntegrations = () => (
    <div>
      <h2 className='page-title'>
        Campaign Manager Integrations
      </h2>

      {integrations ?
        renderTable() :
        <Spinner />}
    </div>
  );

  const renderIntegrationDetails = () => {
    const {
      id: integrationId,
      audienceReportAccountId: accountId,
      googleAccountId,
      googleAccountName,
    } = showDetails;

    return (
      <CampaignManagerIntegrationDetails
        accountId={accountId}
        integrationId={integrationId}
        googleAccountId={googleAccountId}
        googleAccountName={googleAccountName}
        onHideDetails={onHideDetails}
      />
    );
  };

  return (
    showDetails ?
      renderIntegrationDetails() :
      renderIntegrations()
  );
}

const mapStateToProps = ({ campaignManager }) => ({
  integrations: campaignManager.integrations,
});

const mapDispatchToProps = dispatch => ({
  fetchIntegrations: () => {
    dispatch(fetchIntegrations());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignManagerIntegrationTab);
