import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import App from './app'

// css modules
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tippy/dist/tippy.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './styles/sass/theme.scss'

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#react_container')

// toast configuration
toast.configure({
  'position': 'top-center',
  'hideProgressBar': true,
  'pauseOnVisibilityChange': false,
  'bodyClassName': 'react-toast-body'
})

ReactDOM.render((
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
), document.getElementById('react_container'))
