import React, { useState } from 'react'
import { Tooltip } from 'react-tippy'
import { connect } from 'react-redux'
import SearchBar from './SearchBar'
import AccountCampaignsList from './../components/AccountCampaignsList'
import { searchCampaigns, clearSearch } from '../reducers/accountReducer'
import {
  toggleCloneCampaignsModal,
  toggleMoveCampaignsModal,
  toggleDeleteCampaignsModal
} from '../reducers/modalReducer'

const AccountCampaignsTab = ({
                               hasSelectedCampaigns,
                               searchCampaigns,
                               clearSearch,
                               toggleDeleteCampaignsModal,
                               toggleCloneCampaignsModal,
                               toggleMoveCampaignsModal
                             }) => {
  const [timer, setTimer] = useState()

  const handleInput = ({target}) => {
    if (target.value.length >= 3) {
      clearTimeout(timer)

      setTimer(setTimeout(() => {
        searchCampaigns(target.value)
      }, 1000))
    }

    if (target.value === '') {
      clearSearch()
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      clearTimeout(timer)
      searchCampaigns(event.target.value)
    }
  }

  return <div>
    <SearchBar onKeyDown={onKeyDown} onChange={handleInput} placeholder="Search campaigns..."/>
    <h3>
      <span className='bulk-action disabled'>
        <span>
          <Tooltip title="Move selected campaigns" size='big'>
            <button
              disabled={!hasSelectedCampaigns}
              type="button"
              onClick={toggleMoveCampaignsModal}
              className="btn btn-icon btn-primary btn-sm">
              <i className="fui- flaticon stroke export-document-1"/>
            </button>
          </Tooltip>
        </span>
        <span>
          <Tooltip title="Clone selected campaigns" size='big'>
            <button
              disabled={!hasSelectedCampaigns}
              onClick={toggleCloneCampaignsModal}
              type="button"
              className="btn btn-icon btn-primary btn-sm">
              <i className="fui- flaticon stroke multiple-documents-1"/>
            </button>
          </Tooltip>
        </span>
        <span>
          <Tooltip title="Delete selected campaigns" size='big'>
            <button
              disabled={!hasSelectedCampaigns}
              type="button"
              onClick={toggleDeleteCampaignsModal}
              className="btn btn-icon btn-danger btn-sm">
              <i className="fui- flaticon stroke trash-1"/>
            </button>
          </Tooltip>
        </span>
      </span>
    </h3>
    <AccountCampaignsList/>
  </div>
}

const mapStateToProps = ({campaign}) => ({
  hasSelectedCampaigns: campaign.selectedCampaigns.length !== 0
})

export default connect(mapStateToProps, {
  searchCampaigns,
  clearSearch,
  toggleDeleteCampaignsModal,
  toggleMoveCampaignsModal,
  toggleCloneCampaignsModal
})(AccountCampaignsTab)
