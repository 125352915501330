import React  from 'react'
import { connect } from 'react-redux'
import { setProviderFilter, setStateFilter } from './../reducers/fusionReducer'

const FusionConnectionsFilters = ({providers, states, setProviderFilter, setStateFilter}) => {
  return (
    <div>
      <div className="row">
        <div className='col-xs-4'>
          <select
            onChange={(event) => setProviderFilter(event.target.value)}
            className="form-control input-sm">
            <option value='all'>All providers</option>
            {providers.map(provider => <option key={provider} value={provider}>{provider}</option>)}
          </select>
        </div>
        <div className='col-xs-4'>
          <select
            onChange={(event) => setStateFilter(event.target.value)}
            className="form-control input-sm">
            <option value='all'>All states</option>
            {states.map(state => <option key={state} value={state}>{state}</option>)}
          </select>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({fusion}) => {
  return {
    providers: fusion.providers,
    states: fusion.states
  }
}

export default connect(mapStateToProps, {
  setProviderFilter,
  setStateFilter
})(FusionConnectionsFilters)

