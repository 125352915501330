import React from 'react'
import Radium from 'radium'

const styles = {
  overflowY: 'inherit',
  background: 'url(\'/images/login_splash.jpg\') no-repeat center center fixed',
  height: '100vh',
}

const containerStyles = {
  width: '600px',
  height: '600px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  margin: '-150px 0 0 -300px',
  textAlign: 'center',
}

const titleStyles = {
  marginTop: '0px',
  fontWeight: '200',
  color: '#ffffff'
}

const buttonStyles = {
  padding: '12px 50px 14px 50px',
  background: 'rgba(255, 255, 255, 0)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  transition: 'all 0.3s ease',
  ':hover': {
    background: 'rgba(255, 255, 255, 0.05)',
    border: '1px solid rgba(255, 255, 255, 1)',
    borderRadius: '50px'
  }
}

const LoginView = () => {

  const handleLogin = () => {
    let redirectUrl = encodeURI(window.location.origin)
    window.location = `${process.env.REACT_APP_API_AUDIENCEPROJECT_URL}/sso/v1/cp/login?redirectUri=${redirectUrl}`
  }

  return <div style={styles}>
    <div style={containerStyles}>
      <h1 style={titleStyles}>AudienceReport Control Panel</h1>
      <button onClick={handleLogin} style={buttonStyles} type="submit" className="btn btn-primary btn-lg">Login</button>
    </div>
  </div>
}

export default Radium(LoginView)
