import { integrationsApi } from '../axios'

export const getIntegrations = () => (
  integrationsApi
    .get(`${process.env.REACT_APP_API_USERREPORT_URL}/dcm/v1/integrations`)
    .then(({ data }) => data)
)

export const getIntegration = (accountId, integrationId) => (
  integrationsApi
    .get(`${process.env.REACT_APP_API_USERREPORT_URL}/dcm/v1/accounts/${accountId}/integrations/${integrationId}`)
    .then(({ data }) => data)
)
