import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleCreateModal } from '../reducers/modalReducer'
import AccountList from '../components/AccountList'
import SearchBar from '../components/SearchBar'
import CreateAccountModal from '../modals/createAccountModal'

const AccountsView = ({history, toggleCreateModal}) => {
  const [term, setTerm] = useState('')

  const handleRedirectClick = (account) => {
    history.push(`/accounts/${account.id}`)
  }

  return (
    <div>
      <div className='row'>
        <h2 className='pull-left page-title'>
          Accounts
        </h2>
        <button onClick={toggleCreateModal} className="btn btn-success btn-icon pull-right pull-up margin-left">
          <i className="fui- flaticon stroke plus-1"/>
        </button>
        <SearchBar onChange={(event) => setTerm(event.target.value)}/>
      </div>
      <div className='row'>
        <AccountList handleRedirectClick={handleRedirectClick} term={term}/>
      </div>
      <CreateAccountModal />
    </div>
  )
}

export default withRouter(connect(null, {toggleCreateModal})(AccountsView))
