import PropTypes from 'prop-types';
import moment from 'moment';

const LabelDate = ({ date }) => {
  if (!date) {
    return 'Not available';
  }

  return moment(date).format('Y-MM-DD HH:mm');
};

LabelDate.propTypes = {
  date: PropTypes.string,
};

LabelDate.defaultProps = {
  date: undefined,
};

export default LabelDate;
