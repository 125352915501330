import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { lookupByDate } from '../reducers/trackpointReducer'
import useForm from 'react-hook-form'
import { errorStyles } from '../styles/styles'
import TrackpointLookupDateTable from './TrackpointLookupDateTable'

const TrackpointDateSearch = ({lookupByDate, isSearching}) => {
  const [date, setDate] = useState()
  const {register, handleSubmit, setValue, errors} = useForm()

  useEffect(() => {
    register({name: 'date'})
  }, [register])

  const handleDateChange = value => {
    setValue('date', value)
    setDate(value)
  }

  return <div>
    <form onSubmit={handleSubmit(lookupByDate)}>
      <div className="row">
        <div className="col-xs-3">
          <label>CID</label>
          <input ref={register({required: true})}
                 disabled={isSearching}
                 name='cid'
                 style={errors.id ? errorStyles : {}}
                 type="text"
                 placeholder="Input cid..."
                 className="form-control omnisearch"/>
        </div>

        <div className="col-xs-3">
          <label>Date</label>
          <DatePicker
            selected={date}
            dateFormat="MMMM d, yyyy"
            onChange={handleDateChange}
            placeholderText="Select a date..."
            className="form-control omnisearch full-width"/>
        </div>

        <div className="col-xs-3">
          <label>Tailcode</label>

          <div className="form-group">
            <input ref={register}
                   disabled={isSearching}
                   name='tailcode'
                   style={errors.id ? errorStyles : {}}
                   type="text"
                   placeholder="Input tailcode..."
                   className="form-control omnisearch"/>
          </div>
        </div>

        <div className="col-xs-3">
          <label>&nbsp;</label>
          <div>
            <button disabled={isSearching} type="submit" className="btn btn-icon btn-primary">
              Search
            </button>
          </div>
        </div>
      </div>
    </form>

    <TrackpointLookupDateTable/>
  </div>
}

const mapStateToProps = ({trackpoints: {isSearching}}) => ({
  isSearching,
})

export default connect(mapStateToProps, {lookupByDate})(TrackpointDateSearch)

