import React from 'react';
import PropTypes from 'prop-types';
import LabelDate from './common/LabelDate';
import LabelStatus from './common/LabelStatus';
import LabelExtraName from './common/LabelExtraName';

const AdformIntegrationOverview = ({
  integration,
}) => {
  const {
    status,
    country,
    connectedAccountEmail,
    activatedAt,
    lastErrorDate,
    lastErrorMessage,
  } = integration;

  const rows = [{
    label: 'Agency',
    content: (
      <LabelExtraName
        name={integration.agencyNames}
        extra={integration.agencyIds}
      />
    ),
  }, {
    label: 'Connected email',
    content: connectedAccountEmail,
  }, {
    label: 'Country',
    content: country,
  }, {
    label: 'Status',
    content: (
      <LabelStatus status={status} />
    ),
  }, {
    label: 'Activated',
    content: (
      <LabelDate date={activatedAt} />
    ),
  }];

  if (lastErrorMessage) {
    rows.push({
      label: 'Error message',
      content: (
        <React.Fragment>
          <span className="label label-danger">
            {lastErrorMessage}
          </span>
          <span className="label label-danger">
            {<LabelDate date={lastErrorDate} />}
          </span>
        </React.Fragment>
      ),
    });
  }

  return (
    <table
      className='table table-vcenter table-flat'
      style={{
        maxWidth: 600
      }}
    >
      <tbody>
        {rows.map((item, index) => (
          <tr key={index}>
            <td>
              {`${item.label}:`}
            </td>
            <td>
              {item.content}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

AdformIntegrationOverview.propTypes = {
  integration: PropTypes.object.isRequired,
};

export default AdformIntegrationOverview;
