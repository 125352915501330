import { campaignApi } from '../axios'

const initialState = {
  report: null,
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_REPORT':
      return {...state, report: action.report}
    default:
      return state
  }
}

const setReport = report => ({
  type: 'SET_SELECTED_REPORT',
  report,
})

export const fetchReport = id => {
  return (dispatch) => {
    return campaignApi
      .get(`${process.env.REACT_APP_CAMPAIGN_API_URL}/reports/${id}`)
      .then(response => dispatch(setReport(response.data.data)))
  }
}

export default reportReducer
