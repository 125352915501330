import React, { useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { loadKpis } from '../reducers/dashboardReducer'
import { connect } from 'react-redux'

const DashboardView = ({loadKpis, impressionsChart, campaignsCreatedChart}) => {
  useEffect(() => {
    loadKpis()
  }, [loadKpis])

  return <div>
    <div className="row">
      <div className="col-xs-6">
        <div className="graph-wrap hc-wrap">
          <h3>Impressions</h3>
          <HighchartsReact
            highcharts={Highcharts}
            options={impressionsChart}
          />
        </div>
      </div>
      <div className="col-xs-6 ">
        <div className="graph-wrap hc-wrap">
          <h3>New Campaigns</h3>
          <HighchartsReact
            highcharts={Highcharts}
            options={campaignsCreatedChart}
          />
        </div>
      </div>
    </div>
  </div>
}

const mapStateToProps = ({dashboard}) => ({
  impressionsChart: dashboard.impressionsChartOptions,
  campaignsCreatedChart: dashboard.campaignsChartOptions,
})

export default connect(mapStateToProps, {loadKpis})(DashboardView)