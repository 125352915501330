import React from 'react'
import Select from 'react-select'
import { reactSelectStyles } from '../styles/styles'

const AccountFormPermissions = ({initialValue, onChange, cids}) =>
  <div className="form-group">
    <label>Allow end users to also access these CID's:</label>
    <Select
      styles={reactSelectStyles}
      value={initialValue}
      options={cids}
      onChange={onChange}
      isMulti
    />
  </div>

export default AccountFormPermissions
