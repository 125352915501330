import React from 'react'
import { connect } from 'react-redux'

const AccountFormFeatures = ({features, register, accountFeatures}) =>
  <div>
    <div>
      <h4>Features</h4>
    </div>
    {features.map(feature =>
      <label key={feature.id}>
              <span style={{zIndex: 0}} className="pretty-checkbox">
                <input type="checkbox"
                       defaultChecked={accountFeatures.includes(feature.id)}
                       ref={register}
                       name={`features[${feature.id}]`}/>
                <i/>
              </span>
        {feature.description}
      </label>
    )}
  </div>

const mapStateToProps = ({features}, {accountFeatures}) => {
  return {
    accountFeatures,
    features: features.features,
  }
}

export default connect(mapStateToProps)(AccountFormFeatures)
