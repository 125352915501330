import React from 'react'
import { Tooltip } from 'react-tippy'

const RawExportDownloadLine = ({exportItem, label}) =>
  <tr
    onClick={() => window.location = `/api/posttest/download/
    ${exportItem.pid}/
    ${encodeURIComponent(exportItem.path)}`}
    className={exportItem.status !== 'FINISHED' ? 'disabled' : ''}>
    <td>{exportItem.name} : <small>({exportItem.pid})</small></td>
    <td>{exportItem.accountName} : <small>({exportItem.cid})</small></td>
    <td><small className="capitalize">{exportItem.type}</small></td>
    <td>{exportItem.date}</td>
    <td>
      <span className={`label ${label}`}>{exportItem.status}</span>
    </td>
    <td>
      <Tooltip title={"Download export file"} size='big'>
        <i className={'fui- flaticon stroke download-2'}/>
      </Tooltip>
    </td>
  </tr>

export default RawExportDownloadLine

