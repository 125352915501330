import React from "react";
import moment from "moment";

import LoadingBar from "./LoadingBar";
import {
  getCampaignSourceIcon,
  getCampaignSourceStyle,
} from "../styles/styles";

const CampaignDetails = ({ campaign, report, account }) => {
  if (!campaign.dates || !report) {
    return <LoadingBar loadingText="Loading campaign" />;
  }

  const renderReportItems = (report) => {
    return (
      <tbody>
        {report.reportItems.map((item) => (
          <tr key={item.evid}>
            <td>{item.name}</td>
            <td>{item.events | 0}</td>
            <td style={{ minWidth: "100px" }}>{item.sampleSize | 0}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderReportItemsTable = (report) => (
    <div className="scroll-box">
      <table className="table table-vcenter table-condensed">
        <thead>
          <tr>
            <th>Title</th>
            <th>Events</th>
            <th>Sample size</th>
          </tr>
        </thead>
        {renderReportItems(report)}
      </table>
    </div>
  );

  const renderCampaignSourcesTable = (campaign) => (
    <div>
      <table className="table table-vcenter table-condensed">
        <thead>
          <tr>
            <th>Provider</th>
            <th>Name</th>
            <th>Fusion ID</th>
            <th>Data Status</th>
            <th>Last data update</th>
          </tr>
        </thead>
        {renderCampaignSourcesItems(campaign.sources)}
      </table>
    </div>
  );

  const renderCampaignSourcesItems = (sources) => (
    <tbody>
      {sources.map((item) => (
        <tr key={item.id}>
          <td>{item.provider}</td>
          <td style={{ maxWidth: "250px" }}>{item.name}</td>
          <td>
            <div>
              {`${item.account}:`}
            </div>
            <div>
              {item.id}
            </div>
          </td>
          <td>
            <i
              style={getCampaignSourceStyle(item.status)}
              className={getCampaignSourceIcon(item.status)}
            />
          </td>
          <td>
            {item.last_siphoned !== null
              ? moment(item.last_siphoned).format("Y-MM-DD HH:mm")
              : "No date yet"}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className="modal-body">
      <div className="row">
        <div className="col-xs-4">
          <h4 className="modal-section-title">Details</h4>
          <ul className="details-list">
            <li className="box">
              <h5>Campaign ID</h5>
              {campaign.id}
            </li>
            <li className="box">
              <h5>Account</h5>
              {account.name}
            </li>
            <li className="box">
              <h5>Reach Build Event Type</h5>
              {campaign.calculatedReachBuildEventType}
            </li>
            <li className="box">
              <h5>Country</h5>
              {campaign.countryCode}
            </li>
            <li className="box">
              <h5>Created</h5>
              {campaign.dates.created}
            </li>
            <li className="box">
              <h5>Period</h5>
              {campaign.dates.reportStart} - {campaign.dates.reportEnd}
            </li>
          </ul>
        </div>

        <div className="col-xs-8">
          <div className="row">
            <div className="col-xs-12">
              <h4 className="modal-section-title">Events</h4>
              <div className="box clearfix">
                <div className="row">
                  <div className="col-xs-3 has-border">
                    <h5>Total Events</h5>
                    {report.totals.events || 0}
                  </div>
                  <div className="col-xs-3 has-border">
                    <h5>Events In target group</h5>
                    {report.totals.targetEvents || "N/A"}
                  </div>
                  <div className="col-xs-2 has-border">
                    <h5>Sample size</h5>
                    {report.totals.sampleSize || "N/A"}
                  </div>
                  <div className="col-xs-2 has-border">
                    <h5>Reach Persons</h5>
                    {report.totals.reachPersons || "N/A"}
                  </div>
                  <div className="col-xs-2">
                    <h5>Frequency</h5>
                    {report.totals.frequency}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="empty-divider small" />
          <h4 className="modal-section-title">
            {campaign.type === "integrated"
              ? "Campaign sources"
              : "Report items"}
          </h4>

          {campaign.type === "integrated"
            ? renderCampaignSourcesTable(campaign)
            : renderReportItemsTable(report)}
        </div>
      </div>
    </div>
  );
};

export default CampaignDetails;
