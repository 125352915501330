import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { find } from "lodash";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { campaignDetailsModalStyles } from "./../styles/styles";
import { createAccount } from "../reducers/accountsReducer";
import { toggleCampaignModal } from "../reducers/modalReducer";
import ModalHeader from "../components/ModalHeader";
import CampaignDetails from "../components/CampaignDetails";

const itemStyles = {
  display: "block",
  padding: "12px 20px",
  ":hover": {
    cursor: "pointer",
    background: "#f1f5fa",
  },
};

const itemLabelStyle = {
  color: "#4c5d6d",
  fontSize: "14px",
  ":hover": {
    color: "#4c5d6d",
  },
};

const CampaignModal = ({
  modalIsOpen,
  toggleCampaignModal,
  campaign,
  account,
  report,
  webappToken,
}) => {
  const redirectToEdit = () => {
    window.open(
      `https://app.audiencereport.com/logintoprojectedit/${webappToken}/${campaign.id}`
    );
  };

  const redirectToReport = () => {
    window.open(
      `https://app.audiencereport.com/logintoreport/${webappToken}/${campaign.id}`
    );
  };

  const actionItems = [
    {
      label: "Edit",
      action: redirectToEdit,
      icon: "pencil-1",
    },
    {
      label: "View report",
      action: redirectToReport,
      icon: "activity-1",
    },
  ];

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modalIsOpen}
      style={campaignDetailsModalStyles}
      onRequestClose={toggleCampaignModal}
    >
      <div>
        <ModalHeader onCancel={toggleCampaignModal} title={campaign.name}>
          <div className="btn-group btn-group-sm pull-right margin-right">
            <DropdownButton
              alignRight
              drop={"down"}
              variant="primary"
              title={`Campaign details`}
              id={`dropdown-button-drop`}
              key={"down"}
            >
              <div>
                {actionItems.map((item) => (
                  <div
                    key={item.label}
                    className={item.className}
                    style={itemStyles}
                  >
                    <Dropdown.Item onClick={item.action} style={itemLabelStyle}>
                      <i className={`fui- flaticon stroke ${item.icon}`} />
                      {item.label}
                    </Dropdown.Item>
                  </div>
                ))}
              </div>
            </DropdownButton>
          </div>
        </ModalHeader>
        <CampaignDetails
          campaign={campaign}
          account={account}
          report={report}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  modals,
  accounts,
  campaign,
  reports: { report },
}) => {
  return {
    report,
    modalIsOpen: modals.campaignModal,
    webappToken: campaign.webappToken,
    campaign: campaign.selectedCampaign,
    account: find(accounts.audienceReportAccounts, {
      id: campaign.selectedCampaign.customerId,
    }),
  };
};

export default connect(mapStateToProps, { toggleCampaignModal, createAccount })(
  CampaignModal
);
