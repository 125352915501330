import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { searchCampaigns } from '../reducers/searchReducer'
import { fetchReport } from '../reducers/reportReducer'
import { fetchAndSelectCampaign } from '../reducers/campaignReducer'
import { toggleCampaignModal } from '../reducers/modalReducer'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

const CampaignSearcher = ({toggleCampaignModal, searchResults, searchCampaigns}) => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSearch = (term) => {
    setIsLoading(true)
    searchCampaigns(term)
  }

  useEffect(() => {
    let oldQuery = options.length > 0 ? options[0].query : null
    let newQuery = searchResults.length > 0 ? searchResults[0].query : null

    if (newQuery !== oldQuery) {
      setOptions(searchResults)
      setIsLoading(false)
    }
  }, [setOptions, setIsLoading, searchResults, options])

  const renderChildren = (option) => {
    return <div onClick={() => toggleCampaignModal(option.id)}>{option.name}</div>
  }

  return (
    <AsyncTypeahead
      options={options}
      isLoading={isLoading}
      id='searcher'
      useCache={false}
      className='campaign-searcher navbar-form navbar-right'
      labelKey="query"
      minLength={3}
      align='right'
      onSearch={handleSearch}
      placeholder="Search globally..."
      renderMenuItemChildren={renderChildren}
    />
  )
}

const mapStateToProps = ({globalSearcher}) => ({
  searchResults: globalSearcher.searchResults,
  isSearching: globalSearcher.isSearching
})

const mapDispatchToProps = (dispatch) => ({
  searchCampaigns: (term) => dispatch(searchCampaigns(term)),
  toggleCampaignModal: (id) => {
    dispatch(fetchReport(id))
    dispatch(fetchAndSelectCampaign(id))
    dispatch(toggleCampaignModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSearcher)

