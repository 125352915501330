import { toast } from "react-toastify";
import {
  getAudienceReportUsers,
  getOrganizations,
  createNewAudienceReportAccount,
  deleteAudienceReportAccount,
  updateAccountBranding,
  editAudienceReportAccount,
} from "../util/api";

import { sortBy } from "lodash";

import { getTechedgeAccounts } from "../util/techedgeApi";

import { fetchAccount } from "./accountReducer";
import {
  toggleBrandingModal,
  toggleCreateModal,
  toggleEditModal,
  toggleDeleteModal,
} from "./modalReducer";

const initialState = {
  audienceReportAccounts: [],
  techedgeAccounts: [],
  organizations: [],
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AUDIENCEREPORT_ACCOUNTS":
      return { ...state, audienceReportAccounts: action.accounts };
    case "SET_TECHEDGE_ACCOUNTS":
      return { ...state, techedgeAccounts: action.accounts };
    case "SET_ORGANIZATIONS":
      return { ...state, organizations: action.organizations };
    default:
      return state;
  }
};

export const createAccount = (payload) => {
  return (dispatch) => {
    let permissions = payload.permissions.map((permission) => {
      // return { id: permission.value };
      return permission.value;
    });

    return createNewAudienceReportAccount({
      ...payload,
      organizationId: payload.organization.value,
      permissions,
    })
      .then(() => {
        toast.success(`Account ${payload.name} (${payload.cid} has been created`);

        dispatch(toggleCreateModal());
        dispatch(fetchAudienceReportAccounts());
      })
      .catch((error) => {
        let body = error.response.data;
        let rulesError = Object.values(body.error.rules);

        toast.error(`${body.error.message} rules: ${rulesError.join(",")}.`);
      });
  };
};

export const updateBrandingInfo = (payload) => {
  return (dispatch, getState) => {
    let accountId = getState().account.account.id;
    let formData = new FormData();
    formData.append("accountId", accountId);
    formData.append("primaryColor", payload.primaryColor);
    formData.append("secondaryColor", payload.secondaryColor);
    formData.append("companyLogo", payload.logo);
    formData.append("companyAvatar", payload.avatar);

    updateAccountBranding(formData, accountId).then(() => {
      toast.success(`Account branding information has been updated`);

      dispatch(toggleBrandingModal());
    });
  };
};

export const editAccount = (payload) => {
  return (dispatch) => {
    let permissions = payload.permissions.map((permission) => {
      return permission.value;
    });

    return editAudienceReportAccount({
      ...payload,
      organizationId: payload.organization.value,
      permissions,
    }).then(() => {
      toast.success(`Account ${payload.name} (${payload.cid} has been updated`);

      dispatch(toggleEditModal());
      dispatch(fetchAccount(payload.id))
        .then(account => dispatch({
          type: 'SET_ACCOUNT',
          account,
        }));
    });
  };
};

export const deleteAccount = (account) => {
  return (dispatch) => {
    deleteAudienceReportAccount(account).then(() => {
      toast.success(`Account ${account.name} (${account.cid}) has been deleted`);

      dispatch(toggleDeleteModal());
      dispatch(fetchAudienceReportAccounts());
    });
  };
};

const setTechedgeAccounts = (accounts) => {
  return {
    type: "SET_TECHEDGE_ACCOUNTS",
    accounts,
  };
};

const setAudienceReportAccounts = (accounts) => {
  return {
    type: "SET_AUDIENCEREPORT_ACCOUNTS",
    accounts,
  };
};

const setOrganizations = (organizations) => {
  return {
    type: "SET_ORGANIZATIONS",
    organizations,
  };
};

export const fetchAudienceReportAccounts = () => {
  return (dispatch) => {
    return getAudienceReportUsers().then((accounts) =>
      dispatch(setAudienceReportAccounts(accounts))
    );
  };
};

export const fetchTechedgeAccounts = () => {
  return (dispatch) => {
    return getTechedgeAccounts().then((accounts) =>
      dispatch(setTechedgeAccounts(accounts))
    );
  };
};

export const fetchOrganizations = () => {
  return (dispatch) => {
    return getOrganizations().then(({ data }) =>
      dispatch(
        setOrganizations(
          sortBy(data, ["name"]).map(({ id, name }) => {
            return { id, name };
          })
        )
      )
    );
  };
};

export default accountsReducer;
